const result = {
    "__schema": {
        "types": [
            {
                "kind": "UNION",
                "name": "SlugInstance",
                "possibleTypes": [
                    {
                        "name": "Space"
                    },
                    {
                        "name": "Venue"
                    }
                ]
            }
        ]
    }
};
export default result;
