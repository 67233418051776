import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import SearchHeader from '@/Search/components/SearchHeader.vue';
/** Components */
const HomePage = () => import(
/*  webpackPrefetch: true */ /* webpackChunkName: "home.route" */ '@/Home/Home.vue');
export const homeRoutes = [
    {
        path: '/',
        localePath: '',
        name: ROUTE_LIST_NAMES.MAIN,
        meta: {
            header: SearchHeader,
            withoutSearch: true
        },
        component: HomePage
    }
];
