import BaseModel from '@/shared/models/BaseModel';
class SpaceSearchModel extends BaseModel {
    activities;
    favorite;
    halfDayPrice;
    id;
    name;
    images;
    spaceUrl;
    spaceSize;
    venueId;
    venueUrl;
    constructor({ venueId, venueUrl }) {
        super();
        this.id = '';
        this.venueId = venueId;
        this.name = '';
        this.images = [];
        this.activities = [];
        this.favorite = false;
        this.halfDayPrice = null;
        this.spaceUrl = '';
        this.venueUrl = venueUrl;
        this.spaceSize = null;
    }
    apiAttributes(slugFlag) {
        return !slugFlag
            ? {
                id: 'space_id',
                name: 'space_name',
                images: 'images',
                activities: 'activities',
                favorite: 'favorite',
                halfDayPrice: 'half_day_price',
                spaceUrl: 'space_url',
                spaceSize: 'space_size'
            }
            : {
                id: 'space_id',
                name: 'space_name',
                images: 'space_images',
                activities: 'space_categories',
                favorite: 'favorite',
                halfDayPrice: 'half_day_price',
                spaceUrl: 'space_url',
                spaceSize: 'space_size'
            };
    }
    loadFromAPI({ model, slugFlag }) {
        Object.keys(this.apiAttributes(slugFlag)).forEach((key) => {
            this[key] = model[this.apiAttributes(slugFlag)[key]]
                ? model[this.apiAttributes(slugFlag)[key]]
                : this[key];
        });
    }
    mapActivities({ space, activitiesList, findByProp }) {
        this.activities = space.activities.map((act) => {
            const existedActivity = activitiesList.find((item) => item[findByProp] === act);
            return {
                id: existedActivity.category_id,
                title: existedActivity.category_name
            };
        });
    }
}
export default SpaceSearchModel;
