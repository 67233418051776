const ADD_CONFIGURATION = (state, value) => {
    if (state.configurations.indexOf(value) < 0) {
        state.configurations.push(value);
    }
};
const REMOVE_CONFIGURATION = (state, value) => {
    const index = state.configurations.findIndex((c) => c.configuration_id === value.configuration_id);
    if (index !== -1) {
        state.configurations.splice(index, 1);
    }
};
const UPDATE_FACILITY = (state, value) => {
    if (value.count < 1) {
        REMOVE_FACILITY(value.system_service_name);
    }
    const existIndex = state.facilities.findIndex((facility) => facility.system_service_name === value.system_service_name);
    if (existIndex < 0) {
        state.facilities.push(value);
    }
    else {
        state.facilities.splice(existIndex, 1, value);
    }
};
const REMOVE_FACILITY = (state, value) => {
    const existIndex = state.facilities.findIndex((facility) => facility.system_service_name === value);
    if (existIndex >= 0) {
        state.facilities.splice(existIndex, 1);
    }
};
const UPDATE_CATERING = (state, value) => {
    if (value.count < 1) {
        this.REMOVE_FACILITY(state, value.system_service_name);
    }
    const existIndex = state.caterings.findIndex((catering) => catering.system_service_name === value.system_service_name);
    if (existIndex < 0) {
        state.caterings.push(value);
    }
    else {
        state.caterings.splice(existIndex, 1, value);
    }
};
const REMOVE_CATERING = (state, value) => {
    const existIndex = state.caterings.findIndex((catering) => catering.system_service_name === value);
    if (existIndex >= 0) {
        state.caterings.splice(existIndex, 1);
    }
};
const UPDATE_START_TIME = (state, value) => {
    state.startTime = value;
};
const UPDATE_END_TIME = (state, value) => {
    state.endTime = value;
};
export default {
    ADD_CONFIGURATION,
    REMOVE_CONFIGURATION,
    UPDATE_FACILITY,
    REMOVE_FACILITY,
    UPDATE_CATERING,
    REMOVE_CATERING,
    UPDATE_START_TIME,
    UPDATE_END_TIME
};
