import { defineComponent, ref, watch, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EventBus from '@/shared/services/eventBus';
import { OrganisationRoutes } from '@/Organisation/organisation.const';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
const steps = [
    {
        // the route to which redirect when the breadcrumb is clicked
        to: '',
        // the breadcrumb title
        title: 'geo_pages.breadcrumbs.home',
        // is breadcrumb enabled/shown
        disabled: false,
        // is breadcrumb title static and thus the text must be found in locales or it is dynamic
        isStatic: true
    },
    {
        to: 'locations',
        title: 'geo_pages.breadcrumbs.locations',
        disabled: false,
        isStatic: true
    },
    {
        to: 'locations/:region',
        title: 'geo_pages.breadcrumbs.region',
        disabled: false,
        isStatic: true
    },
    {
        to: 'locations/:region/:city',
        title: 'geo_pages.breadcrumbs.city',
        disabled: false,
        isStatic: true
    },
    {
        to: 'customer/venue/:slug',
        title: 'geo_pages.breadcrumbs.venue',
        disabled: false,
        isStatic: true
    },
    {
        to: '#',
        title: 'geo_pages.breadcrumbs.space',
        disabled: true,
        isStatic: true
    }
];
export default defineComponent({
    components: {
        Breadcrumbs
    },
    setup() {
        const space = ref(null);
        let links = ref(steps);
        watch(() => space.value, () => {
            if (space.value) {
                // Region
                links.value[2].title =
                    space.value.venue.venue_geo.region.seo_entity_name;
                links.value[2].to = `${GeoPagesRoutes.CITIES}${space.value.venue.venue_geo.region.seo_entity_slug}`;
                links.value[2].isStatic = false;
                // City
                links.value[3].title =
                    space.value.venue.venue_geo.city.seo_entity_name;
                links.value[3].to = `${GeoPagesRoutes.CITIES}${space.value.venue.venue_geo.city.seo_entity_slug}`;
                links.value[3].isStatic = false;
                // Organisation
                if (space.value.venue.collection?.has_public_page &&
                    links.value[5].to === '#') {
                    const orgListItem = {
                        to: `${OrganisationRoutes.ORGANISATION}${space.value.venue.collection.slug}`,
                        title: space.value.venue.collection.collection_name,
                        disabled: false,
                        isStatic: false
                    };
                    links.value.splice(4, 0, orgListItem);
                }
                // Venue
                links.value[links.value.length - 2].title =
                    space.value.venue.venue_name;
                links.value[links.value.length - 2].to = `customer/venue${space.value.venue.venue_url}`;
                links.value[links.value.length - 2].isStatic = false;
                // Space
                links.value[links.value.length - 1].title =
                    space.value.internal_name || space.value.space_reference;
                links.value[links.value.length - 1].isStatic = false;
                links.value[links.value.length - 1].to = `customer/venue${space.value.space_url}`;
            }
        }, { immediate: true });
        onMounted(() => {
            // Get space object from the Space.vue component
            EventBus.$on('spaceLoaded', _space => {
                if (_space) {
                    space.value = _space;
                }
            });
        });
        return {
            links
        };
    }
});
