import Vue from 'vue';
import Router from 'vue-router';
import qs from 'query-string';
import { routes } from '@/config/router/routes';
import { afterEachCallback } from '@/config/router/routerAfterEachCallback';
import { beforeEachCallback } from '@/config/router/routerBeforeEachCallback';
import { setupRedirectsWithEventBus } from '@/config/router/setupRedirectsWithEventBus';
import { setupSentry } from '@/config/loggers/setupSentry';
Vue.use(Router);
const isSentryEnabled = Number(process.env.VUE_APP_ENABLE_SENTRY_LOGS || 0);
/**
 * Setup Vue router
 */
export const setupRouter = () => {
    const _router = new Router({
        mode: 'history',
        fallback: false,
        parseQuery: (data) => {
            try {
                return qs.parse(data, {
                    arrayFormat: 'bracket',
                    parseBooleans: true,
                    parseNumbers: !data.includes('token')
                });
            }
            catch (e) {
                console.log(e);
            }
        },
        stringifyQuery: (data) => {
            try {
                const params = qs.stringify(data, {
                    arrayFormat: 'bracket',
                    skipNull: true,
                    parseBooleans: true,
                    parseNumbers: true
                });
                return params && `?${params}`;
            }
            catch (e) {
                console.log(e);
            }
        },
        scrollBehavior: (to, from) => {
            if (to.meta.savePosition)
                return false;
            return { y: 0 };
        },
        routes
    });
    if (isSentryEnabled) {
        setupSentry(_router);
    }
    setupRedirectsWithEventBus(_router);
    // refresh the page if chunk loading failed
    _router.onError((error) => {
        if (/loading chunk \d* failed./i.test(error.message)) {
            window.location.reload();
        }
    });
    _router.afterEach((to, from) => {
        afterEachCallback(to, from);
    });
    _router.beforeEach((to, from, next) => {
        beforeEachCallback(to, from, next);
    });
    return _router;
};
