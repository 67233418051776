import { defineComponent, getCurrentInstance } from '@vue/composition-api';
export default defineComponent({
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Array, Object, Number, Boolean],
            default: () => null
        },
        active: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const instance = getCurrentInstance();
        const parent = instance.$parent;
        const onClick = () => {
            parent.$emit('input', props.value);
        };
        return {
            onClick
        };
    }
});
