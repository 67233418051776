import { defineComponent } from '@vue/composition-api';
import { useGetStyles } from '@/util/globalHelpers';
export default defineComponent({
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup() {
        return { useGetStyles };
    }
});
