import i18n from '@/i18n/i18n-setup';
import EventBus from '@/shared/services/eventBus';
export const lnManager = {
    // get defaultLocale () {
    //   return process.env.VUE_APP_I18N_LOCALE
    // },
    // get supportedLocales() {
    //   return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    // },
    get currentLocale() {
        return i18n.locale;
    },
    get storageLanguage() {
        return localStorage.getItem('ln_locale');
    },
    set currentLocale(locale) {
        // localStorage.setItem('ln_locale', locale);
        // i18n.locale = locale;
        if (locale !== undefined) {
            EventBus.$emit('languageSelected', locale);
            localStorage.setItem('locale', locale);
        }
    }
};
