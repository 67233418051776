import BaseModel from '@/shared/models/BaseModel';
import ValidationHelper from '@/util/ValidationHelper';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
class BaseForm extends BaseModel {
    general_error;
    _errors;
    _generalError;
    constructor() {
        super();
        this.general_error = '';
        this._errors = Vue.observable({});
        this._generalError = '';
    }
    sendAttributes() {
        return [];
    }
    hideAttributes() {
        return [];
    }
    validationRules() {
        return {};
    }
    errors(...args) {
        let errorMessage = '';
        args.reverse().forEach((fieldName) => {
            if (fieldName in this._errors) {
                errorMessage = this._errors[fieldName];
            }
        });
        return errorMessage;
    }
    generateFormData() {
        delete this.general_error;
        const sendData = {};
        Object.keys(this).forEach((key) => {
            if (key === 'phone' && this[key] === '')
                return;
            if (key[0] === '_')
                return;
            sendData[key] = this[key];
        });
        return sendData;
    }
    buildSendData() {
        const sendData = {};
        const attributesSource = this.sendAttributes().length
            ? this.sendAttributes()
            : this.attributes();
        attributesSource.forEach((attribute) => {
            if (this.apiAttributes()[attribute]) {
                if (this.hideAttributes().includes(attribute))
                    return;
                sendData[this.apiAttributes()[attribute]] = this[attribute];
            }
        });
        return sendData;
    }
    buildErrors(response) {
        if (response instanceof Object) {
            if (response.detail) {
                if (response.detail instanceof Object) {
                    Object.keys(response.detail).forEach((key) => {
                        if (response.detail[key] instanceof Array) {
                            let keyFound = false;
                            Object.keys(this.apiAttributes()).forEach((attributeKey) => {
                                if (this.apiAttributes()[attributeKey] === key) {
                                    keyFound = true;
                                    Vue.set(this._errors, attributeKey, response.detail[key][0]);
                                }
                            });
                            if (!keyFound) {
                                this._generalError = `${key}: ${response.detail[key][0]}`;
                            }
                        }
                    });
                }
                else {
                    this._generalError = response.detail;
                }
            }
        }
    }
    loadFromModel(model) {
        if (!model)
            return;
        this.attributes().forEach((attribute) => {
            if (model[attribute] != null) {
                this[attribute] = cloneDeep(model[attribute]);
            }
        });
    }
    matchErrors(response) {
        if (response instanceof Object) {
            if (response.detail) {
                if (response.detail instanceof Object) {
                    Object.keys(response.detail).forEach((key) => {
                        if (response.detail[key] instanceof Array) {
                            if (key in this) {
                                this[key] = response.detail[key];
                            }
                            else {
                                this.general_error = `${key}: ${response.detail[key][0]}`;
                            }
                        }
                    });
                }
                else {
                    this.general_error = response.detail;
                }
            }
        }
    }
    validate(vueInstance) {
        this._errors = Vue.observable({});
        this._generalError = '';
        Object.keys(this.validationRules()).forEach((validationKey) => {
            this.validationRules()[validationKey].forEach((validationRule) => {
                if (typeof validationRule.validation === 'function') {
                    validationRule.validation(vueInstance, this, validationRule.i18code);
                    return;
                }
                if (!ValidationHelper[validationRule.validation](this, this[validationKey], validationRule)) {
                    this._errors[validationKey] = globalThis.$i18n.t(validationRule.i18code);
                }
            });
        });
        return !Object.keys(this._errors).length && !this._generalError;
    }
    _imagesRequired(vue, form, errorCode) {
        if (form.images.length < 3) {
            Vue.set(form._errors, 'images', globalThis.$i18n.t(errorCode));
        }
    }
}
export default BaseForm;
