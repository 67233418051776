class BaseModel {
    apiAttributes() {
        return {};
    }
    loadFromAPI(payload) {
        Object.keys(this.apiAttributes()).forEach((key) => {
            if (payload[this.apiAttributes()[key]] != null) {
                this[key] = payload[this.apiAttributes()[key]];
            }
        });
    }
    attributes() {
        return Object.keys(this).filter((attribute) => attribute[0] !== '_' && attribute !== 'general_error');
    }
}
export default BaseModel;
