import { defineComponent, ref } from '@vue/composition-api';
import { rtResolver } from '@/util/storyblok';
import VRuntimeTemplate from 'v-runtime-template';
import StoryblokClient from 'storyblok-js-client';
import { STORYBLOK_PUBLIC_KEY } from '@/App.vue';
let storyapi = new StoryblokClient({
    accessToken: STORYBLOK_PUBLIC_KEY
});
export default defineComponent({
    components: {
        VRuntimeTemplate
    },
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        storyapi.setComponentResolver((component, blok) => {
            return `<component :blok='${JSON.stringify(blok)}' :is='"${component}"'></component>`;
        });
        const doc = ref(`<div>${rtResolver.render(props.blok.body)}</div>`);
        return {
            doc
        };
    }
});
