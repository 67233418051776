export default {
    inserted(el, binding) {
        const callback = binding.value;
        const options = binding.options || {
            passive: true
        };
        window.addEventListener('resize', callback, options);
        /* eslint-disable-next-line */
        el._onResize = {
            callback,
            options
        };
        if (!binding.modifiers || !binding.modifiers.quiet) {
            callback();
        }
    },
    unbind(el) {
        if (!el._onResize)
            return;
        const { callback, options } = el._onResize;
        window.removeEventListener('resize', callback, options);
        /* eslint-disable-next-line */
        delete el._onResize;
    }
};
