import { computed, defineComponent } from '@vue/composition-api';
import { useGetLocalizedPath, useGetStyles } from '@/util/globalHelpers';
export default defineComponent({
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const classes = computed(() => props.blok.styles.join(' '));
        const link = computed(() => {
            const isExternal = props.blok.link.url.includes('http');
            const isEmail = !!props.blok.link.email;
            return {
                isExternal,
                isEmail,
                url: !isExternal && !isEmail
                    ? useGetLocalizedPath(props.blok.link.url)
                    : props.blok.link.email || props.blok.link.url
            };
        });
        return {
            classes,
            link,
            useGetStyles
        };
    }
});
