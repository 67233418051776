import { getAllCategories } from '@/shared/api-modules/application.api';
const getCategories = async ({ commit }) => {
    const categories = await getAllCategories();
    commit('SET_ACTIVITIES', categories);
};
const clearVenues = ({ commit }) => commit('CLEAR_VENUES');
export default {
    getCategories,
    clearVenues
};
