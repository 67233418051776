import { momentObj } from '@/util/dates';
import { DATE_FORMATS } from '@/Calendar/const';
const currentDay = (state) => state.currentDay;
const currentWeekNumber = (state) => state.currentWeekNumber;
const days = (state) => {
    const weekDays = [];
    const today = momentObj(state.currentDay);
    const weekDayPointer = today.startOf('isoWeek');
    weekDays.push({
        name: weekDayPointer.format(DATE_FORMATS.weekModeTitle),
        active: weekDayPointer.isSame(new Date(), 'd'),
        formatedName: weekDayPointer.format(DATE_FORMATS.calendarCore),
        weekDay: 1
    });
    for (let i = 1; i < 7; i += 1) {
        weekDayPointer.add(1, 'd');
        weekDays.push({
            name: weekDayPointer.format(DATE_FORMATS.weekModeTitle),
            formatedName: weekDayPointer.format(DATE_FORMATS.calendarCore),
            active: weekDayPointer.isSame(new Date(), 'd'),
            weekDay: weekDayPointer.isoWeekday()
        });
    }
    return weekDays;
};
const statuses = (state) => state.RESERVATION_STATUS;
export default {
    currentDay,
    currentWeekNumber,
    days,
    statuses
};
