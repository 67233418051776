import { defineComponent, onMounted, ref } from '@vue/composition-api';
const toLeft = ({ left, width } = {}, targetWidth = 0) => ({
    transform: left - width / 2 <= 0
        ? `translate(-${left - targetWidth / 2}px, 0)`
        : `translate(-${width / 2 - targetWidth / 2}px, 0)`
});
const toCenter = ({ right, width } = {}, innerWidth = 0) => ({ transform: 'translate(-50%, 0)', left: '50%' });
export default defineComponent({
    props: {
        position: {
            type: String,
            default: 'left'
        },
        title: {
            type: String,
            default: null
        },
        filterName: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { root } = context;
        const parent = ref(null);
        let styles = ref({
            transform: null
        });
        onMounted(() => {
            const rect = parent.value?.getBoundingClientRect();
            const target = parent.value?.parentElement?.parentElement?.getBoundingClientRect();
            switch (props.position) {
                case 'left':
                    styles.value = toLeft(rect, target?.width);
                    break;
                case 'center':
                    styles.value = toCenter(rect, window.innerWidth);
                    break;
                default:
                    styles.value = toLeft(rect, target?.width);
            }
            styles.value.maxHeight =
                !root.$mq?.phone && `calc(100vh - ${rect?.top}px)`;
        });
        return {
            parent,
            styles
        };
    }
});
