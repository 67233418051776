const RichTextResolver = require('storyblok-js-client/dist/richTextResolver');
const MySchema = require('storyblok-js-client/dist/schema');
MySchema.nodes.heading = function (node) {
    let attrs = {};
    if (node.content &&
        node.content.length === 1 &&
        node.content[0].marks &&
        node.content[0].marks.length === 1 &&
        node.content[0].marks[0].type === 'styled') {
        attrs = node.content[0].marks[0].attrs;
        delete node.content[0].marks;
    }
    return {
        tag: [
            {
                tag: `h${node.attrs.level}`,
                attrs: attrs
            }
        ]
    };
};
export const rtResolver = new RichTextResolver(MySchema);
