import { defineComponent } from '@vue/composition-api';
import HomeHowTo from '@/Home/HomeHowTo.vue';
export default defineComponent({
    components: {
        HomeHowTo
    },
    props: {
        blok: {
            type: Object,
            default: () => { }
        }
    }
});
