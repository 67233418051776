import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    props: {
        space: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, context) {
        const { root } = context;
        const badgesArray = computed(() => {
            let badges = [];
            if (props.space?.isAfterpayAllowed) {
                badges.push({
                    class: 'fa-solid fa-clock fa-sm',
                    title: root.$t('space.card.afterpay_badge_title')
                });
            }
            if (props.space?.isHighlighted) {
                badges.push({
                    class: 'fa-solid fa-fire-flame-curved fa-sm',
                    title: root.$t('space.card.highlighted_location_badge_title')
                });
            }
            if (props.space?.features?.includes(13)) {
                // 13 is the static ID of the disabled toilet feature
                badges.push({
                    class: 'fa-solid fa-wheelchair fa-sm',
                    title: root.$t('space.card.toilet_for_disabled_title')
                });
            }
            return badges;
        });
        /**
         * This map is used to position the badges on the card.
         * The index of the badge in the array is used as the key.
         * The value is the number of pixels from the right side of the card.
         * Step is 35px.
         */
        const badgeIndexToStyleMap = {
            0: 15,
            1: 50,
            2: 85
        };
        return {
            badgeIndexToStyleMap,
            badgesArray
        };
    }
});
