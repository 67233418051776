var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.links.length)?_c('nav',{attrs:{"itemscope":"itemscope","itemtype":"https://schema.org/breadcrumb"}},[_c('ul',{staticClass:"breadcrumbs",attrs:{"itemscope":"itemscope","itemtype":"https://schema.org/BreadcrumbList"}},_vm._l((_vm.links),function(link,key){return _c('li',{key:("" + _vm.SITE_URL + (_vm.useGetLocalizedPath(link.to))),staticClass:"breadcrumbs-list",attrs:{"itemprop":"itemListElement","itemscope":"itemscope","itemtype":"https://schema.org/ListItem"}},[_c('meta',{attrs:{"itemprop":"position","content":key + 1}}),(link.disabled || link.unavailable)?_c('span',{class:[
          'breadcrumbs__link',
          {
            'breadcrumbs__link--disabled': link.disabled,
            'breadcrumbs__link--unavailable': link.unavailable,
            'breadcrumbs__link--active': link.active
          }
        ],attrs:{"itemprop":"name"}},[_vm._v(" "+_vm._s(link.isStatic ? _vm.$t(link.title) : link.title)+" ")]):_c('router-link',{staticClass:"breadcrumbs__link",attrs:{"to":_vm.useGetLocalizedPath(link.to),"itemprop":"item"}},[_c('span',{attrs:{"itemprop":"name"}},[_vm._v(_vm._s(link.isStatic ? _vm.$t(link.title) : link.title))])]),(key !== _vm.links.length - 1)?_c('i',{staticClass:"fa-solid fa-angle-right fa-sm breadcrumbs__icon"}):_vm._e()],1)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }