var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"}],staticClass:"storyblok-question"},[_c('Accordion',{scopedSlots:_vm._u([{key:"header",fn:function(ref){
var collapsed = ref.collapsed;
return [_c('div',{staticClass:"storyblok-question__heading"},[_c('p',[_vm._v(" "+_vm._s(_vm.blok.question)+" ")]),_c('i',{class:[
            {
              'fa-solid fa-chevron-up fa-sm': !collapsed,
              'fa-solid fa-chevron-down fa-sm': collapsed
            }
          ]})])]}},{key:"body",fn:function(){return [_c('p',{staticClass:"storyblok-question__body"},[_vm._v(" "+_vm._s(_vm.blok.answer)+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }