import { defineComponent, ref, watch } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { match, compile } from 'path-to-regexp';
import { BookingRoutes } from '@/Booking/booking.const';
const steps = [
    {
        to: `${BookingRoutes.BOOKING}${BookingRoutes.PAYMENT}/:hash([0-9a-zA-z]{6,8})`,
        title: 'booking.header.details',
        disabled: true,
        isStatic: true
    },
    {
        to: `${BookingRoutes.BOOKING}${BookingRoutes.PAYMENT}/:hash([0-9a-zA-z]{6,8})/${BookingRoutes.PAY}`,
        title: 'booking.header.direct',
        disabled: true,
        isStatic: true
    }
];
export default defineComponent({
    components: {
        Breadcrumbs
    },
    setup(_, context) {
        const { root } = context;
        let links = ref(steps);
        watch(() => root.$route, () => {
            let params = {};
            links.value = steps.reduceRight((acc, { to, ...item }) => {
                const prefix = root.$route.params.locale
                    ? `/${root.$route.params.locale}/`
                    : '/';
                const matcher = match(prefix + to);
                const matched = matcher(root.$route.fullPath);
                if (matched) {
                    Object.assign(params, matched.params);
                }
                const prevState = acc[0]?.disabled ?? true;
                const disabled = prevState ? !matched : prevState;
                const link = {
                    ...item,
                    to: disabled ? to : compile(to)(params),
                    disabled
                };
                return new Array().concat(link, acc);
            }, new Array());
        }, { immediate: true });
        return {
            links
        };
    }
});
