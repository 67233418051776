import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';
import * as directives from '@/util/directives';
/**
 * Register Vue global directives
 */
export const registerDirectives = () => {
    Object.keys(directives).forEach((key) => {
        Vue.directive(key, directives[key]);
    });
    Vue.directive('observe-visibility', ObserveVisibility);
};
