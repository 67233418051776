import { get, isEmpty, keys, pickBy } from 'lodash';
import LocalDB from '@/shared/services/db';
import { addSpaceConfigurations, addSpaceFacilities, addSpaceFeatures, addSpaceImages, attachVenueFeatures, attachVenueImages as attachVenueImagesViaApi, attachVenueServices, createSpace as createSpaceViaApi, createVenue as createVenueViaApi, loadOrganisation as loadOrganisationViaApi, loadSpaceCategories, loadSpaceConfigurations, loadSpaceFeatures, loadSpaceServices as loadSpaceServicesViaApi, loadSpaceStaticServices, 
// loadSpacesList,
loadVenue as loadVenueViaApi, loadVenueFeatures as loadVenueFeaturesViaApi, loadVenueImage, loadVenueServices as loadVenueServicesViaApi, loadVenuesList as loadVenuesListViaApi, saveOrganisation as saveOrganisationViaApi, removeSpace as removeSpaceViaApi, removeVenue as removeVenueViaApi, updateVenue as updateVenueViaApi } from '@/shared/api-modules/vendor.api';
import OrganisationDetailsForm from '@/pages/vendor/onboarding/organisation-details/forms/OrganisationDetailsForm';
import VenueDetailsForm from '@/pages/vendor/venue/details/forms/VenueDetailsForm';
import ServiceModel from '@/shared/models/common/ServiceModel';
import { FEATURES_ICONS } from '@/shared/const';
import { transformWorkingTime } from '@/util/utils';
import { computed } from '@vue/composition-api';
const initVendor = async ({ dispatch }, throwUp = false) => {
    const user = dispatch('$_app/loadUser', {}, { root: true });
    const organisation = await dispatch('loadOrganisation', throwUp);
    if (organisation) {
        return Promise.all([
            user,
            organisation,
            dispatch('loadVenuesList'),
            dispatch('loadVenueFeatures'),
            dispatch('loadVenueServices'),
            dispatch('loadFromStorage')
        ]);
    }
};
const initSpecificVenueDetails = async ({ dispatch }, venueId, throwUp = false) => {
    return Promise.all([
        dispatch('loadVenue', venueId),
        dispatch('loadVenueFeatures'),
        dispatch('loadVenueServices'),
        dispatch('loadFromStorage')
    ]);
};
const initStaticSpaceData = ({ dispatch }) => Promise.all([
    dispatch('loadSpaceFromStorage'),
    dispatch('loadSpaceStaticFeatures'),
    dispatch('loadSpaceStaticCategories'),
    dispatch('loadSpaceStaticConfigurations')
]);
// 1. ORGANISATION BLOCK
const saveOrganisation = async (_, form) => saveOrganisationViaApi(form);
// END OF ORGANISATION BLOCK
// 2. VENUE BLOCK
const createVenue = async ({ state }) => {
    const { venueDetails, venueServices } = state;
    const { facilities, catering } = venueServices;
    const allFacilities = [
        ...facilities.onDemandFacilities,
        ...facilities.presentFacilities
    ];
    venueDetails.workingTime = transformWorkingTime(venueDetails.workingTime);
    venueDetails.allowCatering = venueServices.catering.ownCatering;
    const features = [];
    Object.keys(get(venueDetails, 'features', [])).forEach(key => {
        if (venueDetails.features[key])
            features.push(key);
    });
    const facilitiesData = allFacilities
        .filter((s) => s.selected)
        .map((s) => {
        const service = new ServiceModel(s).buildSendData();
        return { ...service, is_enabled: true };
    });
    const cateringData = catering.cateringItems
        .filter((s) => s.selected)
        .map((s) => {
        const service = new ServiceModel(s).buildSendData();
        return { ...service, is_enabled: true };
    });
    const { venue_id, venue_name } = await createVenueViaApi(new VenueDetailsForm(venueDetails).buildSendData());
    if (venue_id) {
        if (venueDetails.images.length) {
            await attachVenueImagesViaApi({ images_ids: venueDetails.images, venue_name }, venue_id);
        }
        if (features.length) {
            await attachVenueFeatures({
                feature_ids: features,
                venue_id
            });
        }
        if (facilitiesData.length || cateringData.length) {
            await attachVenueServices({
                venue_services: [...facilitiesData, ...cateringData],
                venue_id
            });
        }
    }
    return { venue_id, venue_name };
};
const updateVenue = (_, payload) => updateVenueViaApi(payload.form, payload.id);
const removeVenue = async ({ commit }, id) => {
    await removeVenueViaApi(id);
    commit('REMOVE_VENUE', id);
    return id;
};
const loadOrganisation = async ({ commit, state }, throwUp) => {
    // TODO: This causes the cache issue when switching among the vendors in the same browser
    // If re-logged in as another vendor you'd get the organisation form pre-filled with previous user data
    // if (state.organisation) return state.organisation;
    const organisation = await loadOrganisationViaApi(throwUp);
    const organisationDetailsModel = new OrganisationDetailsForm();
    organisationDetailsModel.loadFromAPI(organisation);
    commit('SET_ORGANISATION', organisationDetailsModel);
    return organisationDetailsModel;
};
const loadVenuesList = async ({ commit }) => {
    const venues = await loadVenuesListViaApi();
    let venueModels = venues.map((venue) => {
        const model = new VenueDetailsForm();
        model.loadFromAPI(venue);
        return model;
    });
    venueModels = await Promise.all(venueModels.map(async (el) => ({
        ...el,
        images: await loadVenueImage(el.id)
        // TODO: uncomment when venue list will be fixed!
        // spaces: await loadSpacesList(el.id),
    })));
    commit('SET_VENUES_LIST', venueModels);
    return venueModels;
};
const loadVenue = async ({ commit }, id) => {
    const venue = await loadVenueViaApi(id);
    const model = new VenueDetailsForm();
    model.loadFromAPI(venue);
    // Resolve images
    model.images = await loadVenueImage(venue.venue_id);
    commit('SET_VENUE', model);
    return model;
};
const loadVenueFeatures = async ({ commit }) => {
    const features = await loadVenueFeaturesViaApi();
    const data = features.map(f => ({
        ...f,
        ...{ icon_name: FEATURES_ICONS[f.feature_name] }
    }));
    commit('SET_VENUE_FEATURES', data);
    return data;
};
const loadVenueServices = async ({ commit }) => {
    const catering = [];
    const facilities = {
        presentFacilities: [],
        onDemandFacilities: []
    };
    const services = await loadVenueServicesViaApi();
    services.forEach(service => {
        const model = new ServiceModel();
        model.loadFromAPI(service);
        if (service.system_service_type === 'CATERING') {
            catering.push(model);
        }
        else if (service.system_service_type === 'FACILITY') {
            if (service.is_always_on_demand) {
                facilities.onDemandFacilities.push(model);
            }
            else {
                facilities.presentFacilities.push(model);
            }
        }
    });
    commit('SET_VENUE_FACILITIES', facilities);
    commit('SET_VENUE_CATERING', { cateringItems: catering });
    return services;
};
/**
 * Loading venue data from the localStorage
 * and save wizard step for the "Create venue" redirect button
 */
const loadFromStorage = ({ commit }) => {
    const details = LocalDB.instance().getItem(LocalDB.FIELDS.VENUE_DETAILS);
    const facilities = LocalDB.instance().getItem(LocalDB.FIELDS.VENUE_SERVICES.facilities);
    const catering = LocalDB.instance().getItem(LocalDB.FIELDS.VENUE_SERVICES.catering);
    const tags = LocalDB.instance().getItem(LocalDB.FIELDS.VENUE_TAGS);
    const venueId = computed(() => globalThis.$store.getters['$_vendor/venueId']);
    if (!venueId.value) {
        if (details) {
            commit('SET_VENUE_DETAILS', JSON.parse(details));
            commit('SET_VENUE_WIZARD', 'FACILITIES');
        }
        if (facilities) {
            commit('SET_VENUE_SERVICES', {
                model: 'facilities',
                data: JSON.parse(facilities)
            });
            commit('SET_VENUE_WIZARD', 'CATERING');
        }
        if (catering) {
            commit('SET_VENUE_SERVICES', {
                model: 'catering',
                data: JSON.parse(catering)
            });
            commit('SET_VENUE_WIZARD', 'TAGS');
        }
        commit('SET_VENUE_TAGS', JSON.parse(tags) || []);
        commit('SET_VENUE_WIZARD', 'TAGS');
    }
};
const attachVenueImages = (_, payload) => attachVenueImagesViaApi(payload.data, payload.id);
// END OF VENUE BLOCK
// 3. SPACE BLOCK
const loadSpaceFromStorage = ({ commit }) => {
    const db = LocalDB.instance();
    const spaceSteps = [
        'DETAILS',
        'CONFIGURATIONS',
        'BOOKING_RULES',
        'FACILITIES',
        'PRICING',
        'FEATURES'
    ];
    spaceSteps.forEach((step, index) => {
        const res = db.getItem(LocalDB.FIELDS[`SPACE_${step}`]);
        if (res) {
            commit(`SET_SPACE_${step}`, JSON.parse(res));
            commit('SET_SPACE_WIZARD', spaceSteps[index + 1]);
        }
    });
    const res = db.getItem(LocalDB.FIELDS.VENUE_ID);
    if (res)
        commit('SET_VENUE_ID', JSON.parse(res));
};
const loadSpaceStaticFeatures = async ({ commit }) => {
    const data = await loadSpaceFeatures();
    commit('SET_SPACE_STATIC_FEATURES', data);
    return data;
};
const loadSpaceStaticCategories = async ({ commit }) => {
    const data = await loadSpaceCategories();
    commit('SET_SPACE_STATIC_CATEGORIES', data);
    return data;
};
const loadSpaceStaticConfigurations = async ({ commit }) => {
    const data = await loadSpaceConfigurations();
    commit('SET_SPACE_STATIC_CONFIGURATIONS', data);
    return data;
};
const loadSpaceServices = async ({ commit }, params) => {
    const { _, spaceId } = params;
    // TODO: Double check this part!
    const data = {
        facilities: []
    };
    const db = LocalDB.instance();
    let spaceServices = [];
    if (spaceId)
        spaceServices = await loadSpaceServicesViaApi(spaceId);
    const services = spaceServices.map((el) => ({
        ...el,
        is_enabled: true
    }));
    const staticServices = await loadSpaceStaticServices();
    const serviceList = [];
    staticServices.forEach((s) => {
        const inSpace = services.find((e) => e.system_service_id === s.system_service_id);
        serviceList.push(inSpace || s);
    });
    serviceList.forEach((service) => {
        const model = new ServiceModel();
        model.loadFromAPI(service);
        model.selected = service.is_venue
            ? service.is_venue
            : service.is_enabled || false;
        model.selectedLimitOption = service.is_inventory_enabled ? 1 : 0;
        data.facilities.push(model);
    });
    // Main getter for facilities
    const facilities = JSON.parse(db.getItem(LocalDB.FIELDS.SPACE_FACILITIES));
    commit('SET_SPACE_SERVICES', spaceId ? data : facilities || data);
    return serviceList;
};
const createSpace = async ({ state }) => {
    const { spaceDetails, spaceConfigurations, spaceBookingRules, spaceFacilities, spacePricing } = state;
    const preparedSpace = {
        venue_id: Number(spaceDetails.venueId),
        internal_name: spaceDetails.name,
        space_description: spaceDetails.description,
        access_instructions: spaceDetails.accessInstructions,
        categories: spaceDetails.activities.map((el) => ({
            category_id: el.id,
            has_mandatory: spaceFacilities.mandatoryConfirm
        })),
        booking_limit: spaceBookingRules.bookingsNumber || 0,
        space_size: Number(spaceConfigurations.size),
        has_discount: !!spacePricing.has_discount,
        discount_percentage: spacePricing.discount_percentage || null,
        discount_description: String(spacePricing.discount_description),
        wifi_name: spaceFacilities.wifi,
        wifi_code: spaceFacilities.wifiPassword,
        space_booking_type: spaceBookingRules.selectedBookingTypeOption
            ? 'MANUAL'
            : 'DIRECT',
        cancellation_th: spaceBookingRules.cancellationTime,
        book_before_min_th: spaceBookingRules.availableTime,
        host_approval_th: spaceBookingRules.decisionTime,
        short_day_price: spacePricing.short_day_price
            ? spacePricing.short_day_price
            : spacePricing.half_day_price,
        half_day_price: spacePricing.half_day_price,
        day_price: spacePricing.day_price,
        full_day_price: spacePricing.full_day_price,
        service_break: spacePricing.service_break,
        is_afterpay_allowed: spaceBookingRules.selectedPaymentOption !== 1,
        is_payment_options_selectable: spaceBookingRules.selectedPaymentOption === 2
    };
    const { features } = spaceDetails;
    const { facilities } = spaceFacilities;
    const configurations = spaceConfigurations.configurations
        .filter((c) => c.checked)
        .map((c) => {
        return {
            configuration_id: c.configuration_id,
            is_default: !c.custom,
            configuration_price: c.price || 0,
            min_guests: 0,
            max_guests: c.attendees,
            configuration_type: c.configuration_type
        };
    });
    const chosenFacilities = facilities
        .filter((s) => s.selected)
        .map((s) => {
        const service = new ServiceModel(s).buildSendData();
        return { ...service, is_enabled: true };
    });
    const { space_id } = await createSpaceViaApi(preparedSpace);
    if (space_id) {
        if (spaceDetails.images.length) {
            await addSpaceImages({ images_ids: spaceDetails.images, space_name: preparedSpace.internal_name }, space_id);
        }
        if (configurations.length) {
            await addSpaceConfigurations({
                configurations
            }, space_id);
        }
        if (!isEmpty(features)) {
            await addSpaceFeatures({
                feature_ids: keys(pickBy(features)).map(Number),
                space_id
            });
        }
        if (chosenFacilities.length) {
            await addSpaceFacilities({
                venue_services: [...chosenFacilities],
                space_id
            });
        }
    }
    return space_id;
};
const attachSpaceImages = (_, payload) => addSpaceImages(payload.data, payload.id);
const removeSpace = async (_, id) => {
    await removeSpaceViaApi(id);
};
// END OF SPACE BLOCK
export default {
    initVendor,
    initStaticSpaceData,
    saveOrganisation,
    loadOrganisation,
    createVenue,
    updateVenue,
    removeVenue,
    loadVenuesList,
    loadVenue,
    loadVenueFeatures,
    loadVenueServices,
    loadFromStorage,
    attachVenueImages,
    loadSpaceFromStorage,
    loadSpaceStaticFeatures,
    loadSpaceStaticConfigurations,
    loadSpaceStaticCategories,
    loadSpaceServices,
    createSpace,
    attachSpaceImages,
    removeSpace,
    initSpecificVenueDetails
};
