import { defineComponent } from '@vue/composition-api';
import VenueCard from '@/GeoPages/components/VenueCard.vue';
export default defineComponent({
    components: {
        VenueCard
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        city: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        }
    },
    setup() {
        return {};
    }
});
