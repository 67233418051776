import { InMemoryCache, defaultDataIdFromObject, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloLink, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { onError } from 'apollo-link-error';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import fragment from '@/generated-types/fragment';
import DebounceLink from 'apollo-link-debounce';
import { GraphqlAuthGuard } from '@/Authentication/utils/guard';
import { getToken } from '@/Authentication/utils/token';
import { GQLErrorHandler } from '@/util/errors';
const DEBOUNCE_TIME = 100;
const isProd = process.env.NODE_ENV === 'production';
// ToDO: open this connection when using GraphQL Subscription.
//    if we open websockets for each page that is opened, we will greatly limit the sockets we can open per node,
//    we also might need to think about loadbalancing the websockets in the near future.
//    Research aiohttp websockets limitations
const graphqlWSLink = new WebSocketLink({
    uri: `${process.env.VUE_APP_PUBLIC_GRAPHQL_WS_URL}`,
    options: {
        reconnect: true
    },
    webSocketImpl: !process.browser ? require('ws') : null
});
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: fragment
});
const authMiddleware = new ApolloLink((operation, forward) => {
    if (process.browser) {
        const currentLocale = globalThis.$i18n.locale;
        // add the authorization to the headers
        operation.setContext({
            headers: {
                authorization: `Bearer ${getToken()}` || null,
                'X-Locale': currentLocale ? currentLocale.toUpperCase() : undefined
            }
        });
    }
    return forward(operation);
});
let cache = null;
let legacyClient = null;
let apolloClient = null;
// Create the apollo client
export function createApolloClients() {
    cache = cache
        ? cache
        : new InMemoryCache({
            fragmentMatcher,
            dataIdFromObject: object => {
                switch (object.__typename) {
                    case 'Booking':
                        return object?.booking_id?.toString();
                    case 'GetAccountProfile':
                        return object?.accountId?.toString();
                    case 'EventNotification':
                        return object?.event_notification_id?.toString();
                    case 'InventoryService':
                        return object?.system_service_id?.toString();
                    case 'OrganisationBank':
                        return object?.organisation_bank_id?.toString();
                    case 'SpaceAvailability':
                        return object?.space_availability_id?.toString();
                    case 'TeamMember':
                        return object?.email;
                    default:
                        return defaultDataIdFromObject(object);
                }
            }
        });
    legacyClient = legacyClient
        ? legacyClient
        : new ApolloClient({
            /** TODO: find better way to handle Circular dependencies */
            link: ApolloLink.from([
                new DebounceLink(DEBOUNCE_TIME),
                onError(GQLErrorHandler),
                onError(GraphqlAuthGuard),
                createHttpLink({
                    uri: process.env.VUE_APP_GRAPHQL_URL_PROXY
                })
            ]),
            cache
        });
    apolloClient = apolloClient
        ? apolloClient
        : new ApolloClient({
            /** TODO: find better way to handle Circular dependencies */
            link: ApolloLink.from([
                authMiddleware,
                onError(GQLErrorHandler),
                onError(GraphqlAuthGuard),
                split(({ query }) => {
                    const definition = getMainDefinition(query);
                    return (definition.kind === 'OperationDefinition' &&
                        definition.operation === 'subscription');
                }, graphqlWSLink, createHttpLink({
                    uri: process.env.VUE_APP_GRAPHQL_URL
                }))
            ]),
            cache
        });
    return { apolloClient, legacyClient };
}
