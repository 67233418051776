/** Modules */
import Booking from '@/Booking';
import Space from '@/Space';
import Venue from '@/Venue';
import Search from '@/Search';
import Services from '@/Services';
import Home from '@/Home';
import Notifications from '@/Notifications';
import Chat from '@/Chat';
import Favorites from '@/Favorites';
import Finance from '@/Finance';
import Admin from '@/Admin';
import Blog from '@/Blog';
import HelpdeskRoutes from '@/Helpdesk';
import HowTo from '@/HowTo';
import GeoPages from '@/GeoPages';
import Organisation from '@/Organisation';
import { vendorRoutes } from '@/config/router/appRoutes/vendor';
import { profileRoutes } from '@/config/router/appRoutes/profile';
import { rootRoutes } from '@/config/router/appRoutes';
import { cloneDeep } from 'lodash';
import i18n from '@/i18n/i18n-setup';
// Note: webpackChunkName Have been added to identify issues. @kelvin for more information.
const MainLayout = () => import(
/* webpackChunkName: "main_layout.route" */ '@/layouts/MainLayout.vue');
const childrenRoutes = [
    ...rootRoutes,
    ...Home.routes,
    ...Search.routes,
    ...GeoPages.routes,
    ...Space.routes,
    ...Venue.routes,
    ...Services.routes,
    ...Notifications.routes,
    ...Chat.routes,
    ...Favorites.routes,
    ...Finance.routes,
    ...Admin.routes,
    ...Blog.routes,
    ...HelpdeskRoutes.routes,
    ...HowTo.routes,
    ...Booking.routes,
    ...Organisation.routes,
    ...vendorRoutes,
    ...profileRoutes
];
const renameComponent = (arr) => arr.map(item => {
    item.name = item.name ? `en-${item.name}` : undefined;
    if (item.children)
        renameComponent(item.children);
    return item;
});
export const routes = [
    {
        path: '/',
        component: MainLayout,
        children: cloneDeep(childrenRoutes).map(el => ({
            ...el,
            path: el.path
        }))
    },
    {
        // We join the locale so our catchAll is aware of all possible language routes
        path: `/:locale(${i18n.availableLocales.join('|')})`,
        component: MainLayout,
        children: cloneDeep(childrenRoutes).map(el => {
            if (el.children)
                renameComponent(el.children);
            return {
                ...el,
                path: el.localePath,
                name: el.name ? `en-${el.name}` : undefined
            };
        })
    },
    {
        path: '/:catchAll(.*)',
        redirect: { name: 'not-found' }
    }
];
