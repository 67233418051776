import axios from '@/http';
import useSmartlookHooks from '@/shared/composables/useSmartlookHooks';
import { LogoutDocument, GetAccessByEmailTokenDocument } from '@/generated-types/graphql.types';
import { legacyClient, apolloClient } from '@/main';
import i18n from '@/i18n/i18n-setup';
var AUTHENTICATION_ACTIONS;
(function (AUTHENTICATION_ACTIONS) {
    AUTHENTICATION_ACTIONS["SET_ACCESS_TOKEN"] = "SET_ACCESS_TOKEN";
    AUTHENTICATION_ACTIONS["SET_REFRESH_TOKEN"] = "SET_REFRESH_TOKEN";
    AUTHENTICATION_ACTIONS["REMOVE_ACCESS_TOKEN"] = "REMOVE_ACCESS_TOKEN";
    AUTHENTICATION_ACTIONS["REMOVE_REFRESH_TOKEN"] = "REMOVE_REFRESH_TOKEN";
})(AUTHENTICATION_ACTIONS || (AUTHENTICATION_ACTIONS = {}));
const AuthenticationStore = {
    namespaced: true,
    state: () => ({
        accessToken: '',
        refreshToken: ''
    }),
    mutations: {
        [AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN]: (state, accessToken) => {
            state.accessToken = accessToken;
        },
        [AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN]: (state, refreshToken) => {
            state.refreshToken = refreshToken;
        },
        [AUTHENTICATION_ACTIONS.REMOVE_ACCESS_TOKEN]: state => (state.accessToken = ''),
        [AUTHENTICATION_ACTIONS.REMOVE_REFRESH_TOKEN]: state => (state.refreshToken = '')
    },
    actions: {
        authorize: async ({ commit }, payload) => {
            commit(AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN, payload.accessToken);
            commit(AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN, payload.refreshToken);
        },
        socialAuth: async ({ commit }, { socialProvider, accessToken, authFlow, accountRole = null }) => {
            const localStorageLocale = localStorage.getItem('signupLocale');
            const locale = i18n.locale
                ? i18n.locale.toUpperCase()
                : i18n.fallbackLocale.toString().toUpperCase();
            try {
                const signUpData = {
                    token: accessToken,
                    locale: localStorageLocale || locale,
                    auth_flow: authFlow
                };
                if (accountRole) {
                    signUpData.account_state = accountRole;
                }
                const { data } = await axios.post(`/account/oauth/${socialProvider}/`, signUpData);
                const { access_token, refresh_token } = data;
                localStorage.removeItem('signupLocale');
                commit(AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN, access_token);
                commit(AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN, refresh_token);
                const { identifyUser } = useSmartlookHooks();
                identifyUser(data);
                // Send event
                globalThis.$gtm.trackEvent({
                    event: data.is_created ? 'account_signup' : 'account_login',
                    category: 'accounts',
                    action: 'by_social',
                    label: `User ${data.is_created ? 'signed up' : 'logged in'} with social network`,
                    value: data.socialProvider ? data.socialProvider.toLowerCase() : '',
                    noninteraction: false
                });
                return data;
            }
            catch (error) {
                console.log(error, 'social auth error');
            }
        },
        login: async (_, phoneOrEmail) => {
            try {
                const { data } = await axios.post('/account/auth-token/', {
                    login: phoneOrEmail
                });
                return data;
            }
            catch (error) {
                throw error.response.data;
            }
        },
        logout: async ({ commit, state }) => {
            try {
                await legacyClient.query({
                    query: LogoutDocument,
                    variables: { token: state.accessToken }
                });
                commit(AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN, '');
                commit(AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN, '');
                const { anonymizeUser } = useSmartlookHooks();
                anonymizeUser();
                globalThis.$store.dispatch('$_app/removeUser');
                globalThis.$router.replace({
                    path: globalThis.$i18n.locale !== 'nl' ? '/en/' : '/'
                });
            }
            catch (error) {
                commit(AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN, '');
                commit(AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN, '');
                globalThis.$store.dispatch('$_app/removeUser');
                console.log('Log out error', error);
            }
        },
        clearTokens: async ({ commit }) => {
            commit(AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN, '');
            commit(AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN, '');
            globalThis.$store.dispatch('$_app/removeUser');
            globalThis.$router.replace({ path: '/' });
        },
        loginByToken: async ({ commit }, token) => {
            try {
                const { data } = await axios.get(`/account/access-token/${token}/`);
                const { access_token, refresh_token } = data;
                commit(AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN, access_token);
                commit(AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN, refresh_token);
                const { identifyUser } = useSmartlookHooks();
                identifyUser(data);
                // Send event
                globalThis.$gtm.trackEvent({
                    event: 'account_login',
                    category: 'accounts',
                    action: 'authenticated',
                    label: 'by_token',
                    value: 'by_token',
                    noninteraction: false
                });
                return data;
            }
            catch (error) {
                throw error.response.data;
            }
        },
        signUpEmail: async (_, payload) => {
            try {
                const { data } = await axios.post('/account/registration/', payload);
                // Send event
                globalThis.$gtm.trackEvent({
                    event: 'account_signup',
                    category: 'accounts',
                    action: 'by_email',
                    label: 'User signed up by email',
                    value: 'by_email',
                    noninteraction: false
                });
                return data;
            }
            catch (error) {
                throw error.response.data;
            }
        },
        verifyEmail: async ({ commit }, token) => {
            const { data: { account } } = await axios.get(`/account/verify-email/${token}/`);
            const { access_token, refresh_token } = account;
            commit(AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN, access_token);
            commit(AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN, refresh_token);
            const { identifyUser } = useSmartlookHooks();
            identifyUser(account);
            // Send event
            globalThis.$gtm.trackEvent({
                event: 'account_verified_email',
                category: 'accounts',
                action: 'account_verified_email',
                label: 'Account email verified',
                value: account.email,
                noninteraction: false
            });
            return account.email;
        },
        verifyPhoneLink: async ({ commit }, token) => {
            const { data: { account } } = await axios.get(`/account/verify-phone-with-link/${token}/`);
            const { access_token, refresh_token } = account;
            commit(AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN, access_token);
            commit(AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN, refresh_token);
            // Send event
            globalThis.$gtm.trackEvent({
                event: 'account_verified_phone',
                category: 'accounts',
                action: 'account_verified_phone',
                label: 'User phone number verified',
                value: account.phone,
                noninteraction: false
            });
            return account.phone;
        },
        verifyPhoneCode: async (_, code) => {
            try {
                const { data: { account } } = await axios.get(`/account/verify-phone/${code}/`);
                return account.phone;
            }
            catch (e) {
                throw e.response.data;
            }
        },
        resendVerification: async (_, { method, accountId }) => {
            try {
                await axios.post('/account/resend-verification/', {
                    method,
                    account_id: accountId
                });
            }
            catch (error) {
                console.log('Error via resend verification: ', error);
            }
        },
        loginWithRedirect: async ({ commit }, email_token) => {
            try {
                const { data: { get_access_tokens: account } } = await apolloClient.mutate({
                    mutation: GetAccessByEmailTokenDocument,
                    variables: { email_token }
                });
                const { access_token, refresh_token } = account;
                commit(AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN, access_token);
                commit(AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN, refresh_token);
                return account;
            }
            catch (error) {
                commit(AUTHENTICATION_ACTIONS.SET_ACCESS_TOKEN, '');
                commit(AUTHENTICATION_ACTIONS.SET_REFRESH_TOKEN, '');
                globalThis.$router.replace({ path: '/' });
                console.log('Log in error', error);
            }
        }
    },
    getters: {
        accessToken: state => state.accessToken,
        refreshToken: state => state.refreshToken
    }
};
export default AuthenticationStore;
