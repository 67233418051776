import Vuex from 'vuex';
import AppModule from '@/shared/store-modules/app/index';
import VendorModule from '@/shared/store-modules/vendor/index';
import VenueModule from '@/shared/store-modules/venue/index';
import CalendarModule from '@/shared/modules/calendar/store/index';
import HistoryModule from '@/shared/store-modules/history/index';
import { createModule } from 'vuex-toast';
import loaderStore from '@/config/store/loader.store';
import authenticationModule from '@/Authentication';
import BookingModule from '@/shared/store-modules/booking/index';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import createPersistedState from 'vuex-persistedstate';
import LogRocket from 'logrocket';
import createPlugin from 'logrocket-vuex';
// If you want to use the default style (with webpack css-loader)
import 'vuex-toast/dist/vuex-toast.css';
const vuexStorePlugins = [];
if (process.browser) {
    vuexStorePlugins.push(createPersistedState({
        paths: ['authentication', 'booking']
    }));
}
// LogRocket
const logRocketPlugin = createPlugin(LogRocket);
vuexStorePlugins.push(logRocketPlugin);
/**
 * Setup global Vuex store
 * for Vue3: https://v3.vuejs.org/guide/migration/global-api.html#vue-prototype-replaced-by-config-globalproperties
 */
export const setupVuexStore = () => {
    return new Vuex.Store({
        modules: {
            $_app: AppModule,
            $_vendor: VendorModule,
            $_venue: VenueModule,
            $_calendar: CalendarModule,
            $_history: HistoryModule,
            toast: createModule({
                dismissInterval: 8000
            }),
            loader: loaderStore,
            authentication: authenticationModule.store,
            booking: BookingModule
        },
        actions: {
            async initStore({ dispatch }) {
                await dispatch('$_venue/getCategories');
            },
            addToast({ dispatch, getters }, ...args) {
                const messages = getters.toastMessages;
                const similar = messages?.find(({ text, type }) => args[0]?.text === text && args[0]?.type === type);
                if (similar)
                    return;
                dispatch(ADD_TOAST_MESSAGE, ...args);
            }
        },
        plugins: vuexStorePlugins
    });
};
