import { defineComponent } from '@vue/composition-api';
export default function () {
    try {
        import('@/quality/Helper.vue').then(helper => {
            let doesHelperExist, HelperComponentClass, HelperInstance;
            const qualityHelper = helper.default;
            function loadQualityModel(e) {
                let pressed = '';
                if (e.shiftKey) {
                    pressed += 'Shift';
                }
                else if (e.ctrlKey) {
                    pressed += 'Ctrl';
                }
                pressed += e.keyCode;
                if (pressed == 'Ctrl81') {
                    doesHelperExist = document.querySelector('#quality-helper');
                    if (!doesHelperExist) {
                        HelperComponentClass = defineComponent(qualityHelper);
                        HelperInstance = new HelperComponentClass({
                            propsData: { enabled: true }
                        });
                        HelperInstance.$mount(); // pass nothing
                        // Add to dom
                        const $vm = document.querySelector('#app');
                        if ($vm) {
                            $vm.appendChild(HelperInstance.$el);
                        }
                    }
                    else {
                        HelperInstance._data.enabled = !HelperInstance._data.enabled;
                    }
                }
                if (pressed == 'Shift81') {
                    // Remove helper in case of any type of failure
                    if (HelperInstance) {
                        HelperInstance._data.enabled = false;
                        HelperInstance.$destroy();
                        document.querySelector('#app').removeChild(HelperInstance.$el);
                    }
                }
            }
            document.addEventListener('keydown', loadQualityModel);
        });
    }
    catch (error) {
        console.error('File import failed');
    }
}
