import EventBus from '@/shared/services/eventBus';
import { EVENTBUS_EVENTS } from '@/shared/const';
import { ROUTE_LIST_NAMES, PUBLIC_ROUTES } from '@/config/router/appRoutes';
import { AuthPopups } from '@/Authentication/authentication.const';
export const setupRedirectsWithEventBus = (router) => {
    EventBus.$on(EVENTBUS_EVENTS.API_ERROR, payload => {
        switch (payload.status) {
            case 404:
                router
                    .push({ name: ROUTE_LIST_NAMES.ERRORS.NOT_FOUND })
                    .then(() => { });
                break;
            case 500:
                router
                    .push({ name: ROUTE_LIST_NAMES.ERRORS.SERVER_ERROR })
                    .then(() => { });
                break;
            case 403:
                // NOTE: Set user commit performed for backward compatibility.
                // Should be removed after dependent part of app will be updated.
                globalThis.$store.commit('$_app/SET_USER', {});
                globalThis.$store
                    .dispatch('authentication/logout')
                    .then(() => { });
                router
                    .push({
                    name: ROUTE_LIST_NAMES.MAIN,
                    query: { authPopup: AuthPopups.LOGIN }
                })
                    .then(() => { });
                break;
            case 401:
                if (PUBLIC_ROUTES.includes(router.history.current.name))
                    return;
                // NOTE: Set user commit performed for backward compatibility.
                // Should be removed after dependent part of app will be updated.
                globalThis.$store.commit('$_app/SET_USER', {});
                globalThis.$store
                    .dispatch('authentication/logout')
                    .then(() => { });
                router
                    .push({
                    name: ROUTE_LIST_NAMES.MAIN,
                    query: { authPopup: AuthPopups.LOGIN }
                })
                    .then(() => { });
                break;
            default:
                return false;
        }
        return true;
    });
    EventBus.$on(EVENTBUS_EVENTS.REDIRECT, payload => {
        router.push({ name: payload }).then(() => { });
    });
};
