/**
 * Retrieves an authentication token from the local storage.
 * This function is specifically designed to access a 'vuex' local storage item,
 * parse its content as JSON, and then extract an 'accessToken' from the 'authentication' section.
 * It's commonly used in web applications to retrieve user tokens for session management and API authentication.
 * The function includes a try-catch block to handle any exceptions that might occur during the retrieval
 * and parsing process, such as if 'vuex' is not set or contains malformed JSON, ensuring that the function
 * gracefully handles errors and returns null in such cases.
 *
 * @returns {Nullable<string>} The retrieved access token if found and valid, or null otherwise.
 */
export const getStorageToken = () => {
    try {
        const store = JSON.parse(localStorage.getItem('vuex') || '');
        return store?.authentication?.accessToken;
    }
    catch (e) {
        return null;
    }
};
