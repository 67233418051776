var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-input select",class:{
    inline: _vm.inline,
    disabled: _vm.disabled
  }},[(_vm.label)?_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{class:{
      'option-selected': true,
      empty: _vm.isNotSelected,
      disabled: _vm.disabled,
      error: _vm.error
    },on:{"click":function($event){$event.stopPropagation();return _vm.toggleOptions.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.selectedLabel)+" "),_c('span',{class:{ arrow: true, rotated: _vm.optionsVisible }},[_c('i',{staticClass:"fa-solid fa-chevron-down fa-2xs"})])]),_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeOptions),expression:"closeOptions"}],class:[{ 'options-list': true, visible: _vm.optionsVisible }, _vm.position]},_vm._l((_vm.options),function(option,index){return _c('li',{key:index,class:['option', { disabled: option.disabled }],on:{"click":function($event){return _vm.selectOption(option)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0),(_vm.error)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }