export var OrganisationRoutes;
(function (OrganisationRoutes) {
    OrganisationRoutes["ORGANISATION"] = "organisation";
})(OrganisationRoutes || (OrganisationRoutes = {}));
export const mockedPlaceholders = {
    facebook: 'https://facebook.com/WeMeet',
    instagram: 'https://instagram.com/WeMeet',
    website2: 'https://website.com',
    twitter: 'https://twitter.com/WeMeet',
    linkedin: 'https://linkedin.com/WeMeet'
};
