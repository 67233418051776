import { ORGANISATION_TYPES } from '@/shared/const';
import BaseForm from '@/shared/models/BaseForm';
class OrganisationDetailsModel extends BaseForm {
    coc;
    financeAddress;
    financeEmail;
    financePlaceId;
    id;
    isUpdateAllowed;
    name;
    organisationAddress;
    organisationRating;
    organisationType;
    personalAddress;
    placeId;
    vatNumber;
    vatRate;
    constructor() {
        super();
        this.id = null;
        this.organisationType = ORGANISATION_TYPES.PUBLIC_ORGANISATION;
        this.name = '';
        this.coc = null;
        this.vatNumber = '';
        this.vatRate = null;
        this.financeAddress = '';
        this.financeEmail = '';
        this.organisationAddress = '';
        this.personalAddress = '';
        this.placeId = '';
        this.financePlaceId = '';
        this.isUpdateAllowed = true;
        this.organisationRating = null;
    }
    hideAttributes() {
        switch (this.organisationType) {
            case ORGANISATION_TYPES.PUBLIC_ORGANISATION:
                return [
                    'id',
                    'personalAddress',
                    'financeAddress',
                    'organisationAddress',
                    'isUpdateAllowed'
                ];
            case ORGANISATION_TYPES.PRIVATE_INDIVIDUAL:
                return [
                    'organisationAddress',
                    'personalAddress',
                    'financeAddress',
                    'coc',
                    'name'
                ];
            default:
                return [];
        }
    }
    apiAttributes() {
        return {
            id: 'account_id',
            organisationType: 'organisation_type',
            name: 'organisation_name',
            vatNumber: 'vat_number',
            vatRate: 'vat_rate',
            financePlaceId: 'finance_place_id',
            financeEmail: 'finance_email',
            placeId: 'place_id',
            coc: 'coc',
            organisationAddress: 'address',
            financeAddress: 'finance_address',
            isUpdateAllowed: 'is_update_allowed',
            organisationRating: 'organisation_rating'
        };
    }
}
export default OrganisationDetailsModel;
