import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        rows: {
            type: Number,
            default: 4
        },
        disabled: {
            type: Boolean,
            default: false
        },
        error: {
            type: [String, Boolean],
            default: false
        },
        name: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: false
        },
        maxLength: {
            type: Number,
            default: 255
        },
        minLength: {
            type: Number,
            default: 0
        },
        label: {
            type: String,
            default: null
        },
        isLabelHtml: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        isSpaceDetailsPage: {
            type: Boolean,
            default: false
        },
        isLightStyle: {
            type: Boolean,
            default: false
        },
        labelAsteriskRight: {
            type: Boolean,
            default: false
        }
    },
    setup(_, { emit }) {
        const onShiftEnter = (event) => {
            if (event.shiftKey && event.key === 'Enter') {
                event.preventDefault();
                emit('input', event.target.value);
                emit('submit');
            }
        };
        return {
            onShiftEnter
        };
    }
});
