import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes/const';
import OnboardingHeader from '@/pages/vendor/onboarding/OnboardingHeader.vue';
const EmptyLayout = () => import(
/* webpackChunkName: "empty_layout.route" */ '@/layouts/EmptyLayout.vue');
const OnboardingLayout = () => import(
/* webpackChunkName: "onboarding_layout.route" */ '@/layouts/OnboardingLayout.vue');
const VendorPage = () => import(
/* webpackChunkName: "public.vendor_landingpage" */ '@/Home/HomeVendor.vue');
const SpacesPage = () => import(
/* webpackChunkName: "space.route" */ '@/pages/vendor/venues/VenuesPage.vue');
const VenueSettingsPage = () => import(
/* webpackChunkName: "venue_settings.route" */ '@/pages/vendor/venue/VenueSettingsPage.vue');
const VenueDetailsPage = () => import(
/* webpackChunkName: "venue_detail.route" */ '@/pages/vendor/venue/details/VenueDetailsPage.vue');
const VenueFacilitiesPage = () => import(
/* webpackChunkName: "venue_facility.route" */ '@/pages/vendor/venue/facilities/VenueFacilitiesPage.vue');
const VenueCateringPage = () => import(
/* webpackChunkName: "venue_catering.route" */ '@/pages/vendor/venue/catering/VenueCateringPage.vue');
const VenueTagsPage = () => import(
/* webpackChunkName: "venue_tags.route" */ '@/pages/vendor/venue/tags/VenueTagsPage.vue');
const SpaceSettingsPage = () => import(
/* webpackChunkName: "space_settings.route" */ '@/pages/vendor/space/SpaceSettingsPage.vue');
const SpaceDetailsPage = () => import(
/* webpackChunkName: "space_detail.route" */ '@/pages/vendor/space/details/SpaceDetailsPage.vue');
const SpaceConfigurationsPage = () => import(
/* webpackChunkName: "space_config.route" */ '@/pages/vendor/space/configurations/SpaceConfigurationsPage.vue');
const SpaceBookingRulesPage = () => import(
/* webpackChunkName: "space_booking_rule.route" */ '@/pages/vendor/space/booking-rules/SpaceBookingRulesPage.vue');
const SpaceFacilitiesPage = () => import(
/* webpackChunkName: "space_facility.route" */ '@/pages/vendor/space/facilities/SpaceFacilitiesPage.vue');
const SpacePricingPage = () => import(
/* webpackChunkName: "space_price.route" */ '@/pages/vendor/space/pricing/SpacePricingPage.vue');
const SpaceFeaturesPage = () => import(
/* webpackChunkName: "space_feature.route" */ '@/pages/vendor/space/features/SpaceFeaturesPage.vue');
const OrganisationDetailsPage = () => import(
/* webpackChunkName: "organisation.route" */ '@/pages/vendor/onboarding/organisation-details/OrganisationDetailsPage.vue');
const VenueOnboardingPage = () => import(
/* webpackChunkName: "venue_onboarding.route" */ '@/pages/vendor/venue/VenueOnboardingPage.vue');
const SpaceOnboardingPage = () => import(
/* webpackChunkName: "space_onboarding.route" */ '@/pages/vendor/space/SpaceOnboardingPage.vue');
export const vendorRoutes = [
    {
        path: '/vendor',
        localePath: 'vendor',
        component: EmptyLayout,
        children: [
            /** TODO: fix route structure **/
            {
                path: '/',
                component: VendorPage
            },
            {
                path: 'spaces',
                name: ROUTE_LIST_NAMES.SPACES,
                component: SpacesPage,
                props: true
            },
            {
                path: 'venue/:venueId(\\d+)',
                name: ROUTE_LIST_NAMES.VENUE_SETTINGS.MAIN,
                redirect: { name: ROUTE_LIST_NAMES.VENUE_SETTINGS.DETAILS },
                meta: {
                    hideHeader: true
                },
                component: VenueSettingsPage,
                children: [
                    {
                        meta: {
                            center: true
                        },
                        path: 'details',
                        name: ROUTE_LIST_NAMES.VENUE_SETTINGS.DETAILS,
                        component: VenueDetailsPage
                    },
                    {
                        meta: {
                            center: true
                        },
                        path: 'facilities',
                        name: ROUTE_LIST_NAMES.VENUE_SETTINGS.FACILITIES,
                        component: VenueFacilitiesPage
                    },
                    {
                        meta: {
                            center: true
                        },
                        path: 'catering',
                        name: ROUTE_LIST_NAMES.VENUE_SETTINGS.CATERING,
                        component: VenueCateringPage
                    },
                    {
                        meta: {
                            center: true
                        },
                        path: 'tags',
                        name: ROUTE_LIST_NAMES.VENUE_SETTINGS.TAGS,
                        component: VenueTagsPage
                    }
                ]
            },
            {
                path: 'venue/:venueId(\\d+)/space/:spaceId(\\d+)',
                name: ROUTE_LIST_NAMES.SPACE_SETTINGS.MAIN,
                redirect: { name: ROUTE_LIST_NAMES.SPACE_SETTINGS.DETAILS },
                meta: {
                    hideHeader: true
                },
                component: SpaceSettingsPage,
                children: [
                    {
                        meta: {
                            center: true
                        },
                        path: 'details',
                        name: ROUTE_LIST_NAMES.SPACE_SETTINGS.DETAILS,
                        component: SpaceDetailsPage
                    },
                    {
                        meta: {
                            center: true
                        },
                        path: 'configurations',
                        name: ROUTE_LIST_NAMES.SPACE_SETTINGS.CONFIGURATIONS,
                        component: SpaceConfigurationsPage
                    },
                    {
                        meta: {
                            center: true
                        },
                        path: 'booking-rules',
                        name: ROUTE_LIST_NAMES.SPACE_SETTINGS.BOOKING_RULES,
                        component: SpaceBookingRulesPage
                    },
                    {
                        meta: {
                            center: true
                        },
                        path: 'facilities',
                        name: ROUTE_LIST_NAMES.SPACE_SETTINGS.FACILITIES,
                        component: SpaceFacilitiesPage
                    },
                    {
                        meta: {
                            center: true
                        },
                        path: 'pricing',
                        name: ROUTE_LIST_NAMES.SPACE_SETTINGS.PRICING,
                        component: SpacePricingPage
                    },
                    {
                        meta: {
                            center: true
                        },
                        path: 'features',
                        name: ROUTE_LIST_NAMES.SPACE_SETTINGS.FEATURES,
                        component: SpaceFeaturesPage
                    }
                ]
            },
            {
                path: 'venue/:venueId(\\d+)/space/create',
                name: ROUTE_LIST_NAMES.CREATE_SPACE.MAIN,
                redirect: { name: ROUTE_LIST_NAMES.CREATE_SPACE.DETAILS },
                component: SpaceSettingsPage,
                children: [
                    {
                        path: 'details',
                        name: ROUTE_LIST_NAMES.CREATE_SPACE.DETAILS,
                        component: SpaceDetailsPage
                    },
                    {
                        path: 'configurations',
                        name: ROUTE_LIST_NAMES.CREATE_SPACE.CONFIGURATIONS,
                        component: SpaceConfigurationsPage
                    },
                    {
                        path: 'booking-rules',
                        name: ROUTE_LIST_NAMES.CREATE_SPACE.BOOKING_RULES,
                        component: SpaceBookingRulesPage
                    },
                    {
                        path: 'facilities',
                        name: ROUTE_LIST_NAMES.CREATE_SPACE.FACILITIES,
                        component: SpaceFacilitiesPage
                    },
                    {
                        path: 'pricing',
                        name: ROUTE_LIST_NAMES.CREATE_SPACE.PRICING,
                        component: SpacePricingPage
                    },
                    {
                        path: 'features',
                        name: ROUTE_LIST_NAMES.CREATE_SPACE.FEATURES,
                        component: SpaceFeaturesPage
                    }
                ]
            },
            {
                path: 'onboarding',
                name: ROUTE_LIST_NAMES.ONBOARDING.MAIN,
                redirect: {
                    name: ROUTE_LIST_NAMES.ONBOARDING.ORGANISATION_DETAILS
                },
                component: OnboardingLayout,
                meta: {
                    breadcrumbs: OnboardingHeader
                },
                children: [
                    {
                        path: 'organisation-details',
                        name: ROUTE_LIST_NAMES.ONBOARDING.ORGANISATION_DETAILS,
                        meta: {
                            hideHeader: true,
                            embed: false
                        },
                        component: OrganisationDetailsPage
                    },
                    {
                        path: 'venue',
                        name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.MAIN,
                        redirect: {
                            name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.DETAILS
                        },
                        meta: {
                            hideHeader: true
                        },
                        component: VenueOnboardingPage,
                        children: [
                            {
                                meta: {
                                    hideHeader: true
                                },
                                path: 'details',
                                name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.DETAILS,
                                component: VenueDetailsPage
                            },
                            {
                                meta: {
                                    hideHeader: true
                                },
                                path: 'facilities',
                                name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.FACILITIES,
                                component: VenueFacilitiesPage
                            },
                            {
                                meta: {
                                    hideHeader: true
                                },
                                path: 'catering',
                                name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.CATERING,
                                component: VenueCateringPage
                            },
                            {
                                meta: {
                                    hideHeader: true
                                },
                                path: 'tags',
                                name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.TAGS,
                                component: VenueTagsPage
                            }
                        ]
                    },
                    {
                        path: 'space',
                        name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.MAIN,
                        redirect: {
                            name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.DETAILS
                        },
                        meta: {
                            hideHeader: true
                        },
                        component: SpaceOnboardingPage,
                        children: [
                            {
                                meta: {
                                    hideHeader: true
                                },
                                path: 'details',
                                name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.DETAILS,
                                component: SpaceDetailsPage
                            },
                            {
                                meta: {
                                    hideHeader: true
                                },
                                path: 'configurations',
                                name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.CONFIGURATIONS,
                                component: SpaceConfigurationsPage
                            },
                            {
                                meta: {
                                    hideHeader: true
                                },
                                path: 'booking-rules',
                                name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.BOOKING_RULES,
                                component: SpaceBookingRulesPage
                            },
                            {
                                meta: {
                                    hideHeader: true
                                },
                                path: 'facilities',
                                name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.FACILITIES,
                                component: SpaceFacilitiesPage
                            },
                            {
                                meta: {
                                    hideHeader: true
                                },
                                path: 'pricing',
                                name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.PRICING,
                                component: SpacePricingPage
                            },
                            {
                                meta: {
                                    hideHeader: true
                                },
                                path: 'features',
                                name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.FEATURES,
                                component: SpaceFeaturesPage
                            }
                        ]
                    }
                ]
            }
        ]
    }
];
