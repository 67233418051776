import i18n from '@/i18n/i18n-setup';
export var AdminRoutes;
(function (AdminRoutes) {
    AdminRoutes["ADMIN"] = "admin";
})(AdminRoutes || (AdminRoutes = {}));
export const tableLabels = [
    {
        name: 'cityName',
        label: i18n.t('admin.city.city_name').toString(),
        sortable: true,
        customElement: 'cityNameSlot'
    },
    {
        name: 'regionName',
        label: i18n.t('admin.city.region_name').toString(),
        sortable: true,
        customElement: 'regionNameSlot'
    },
    {
        name: 'cityCounter',
        label: i18n.t('admin.city.active_spaces').toString(),
        sortable: true,
        customElement: 'cityCounterSlot'
    },
    {
        name: 'action',
        label: i18n.t('admin.action').toString(),
        sortable: false,
        customElement: 'actionSlot'
    }
];
