import { SITE_URL } from '@/App.vue';
const FACEBOOK_APP_ID = process.env.VUE_APP_FACEBOOK_APP_ID;
const createLinkTag = (rel, id, hreflang, title, type) => {
    const link = document.createElement('link');
    link.setAttribute('rel', rel);
    link.setAttribute('linkId', id);
    if (hreflang) {
        link.setAttribute('hreflang', hreflang);
    }
    if (title) {
        link.setAttribute('title', title);
    }
    if (type) {
        link.setAttribute('type', type);
    }
    document.getElementsByTagName('head')[0].appendChild(link);
    return link;
};
const getHreflangLinks = () => {
    return document.querySelectorAll('link[rel="alternate"]');
};
export const useMeta = (options) => {
    const updateMeta = () => {
        const updateTag = (content, name, property) => {
            if (!name && !property)
                return;
            let element;
            if (name && !property) {
                element = document.querySelector(`meta[name="${name}"]`);
                if (!element) {
                    element = document.createElement('meta');
                    if (name) {
                        element.setAttribute('name', name);
                    }
                    document.getElementsByTagName('head')[0].appendChild(element);
                }
            }
            else {
                element = document.querySelector(`meta[property="${property}"]`);
                if (!element) {
                    element = document.createElement('meta');
                    if (property) {
                        element.setAttribute('property', property);
                    }
                    document.getElementsByTagName('head')[0].appendChild(element);
                }
            }
            element.setAttribute('content', content);
        };
        if (options.title) {
            document.title = options.title;
            updateTag(options.title, 'title');
            updateTag(options.title, 'twitter:title');
            updateTag(options.title, undefined, 'og:title');
        }
        if (options.description) {
            updateTag(options.description, 'description');
            updateTag(options.description, 'twitter:description');
            updateTag(options.description, undefined, 'og:description');
        }
        updateTag('website', undefined, 'og:type');
        updateTag('summary', undefined, 'twitter:card');
        updateTag(globalThis.$i18n.t('meta.general.title_tag'), undefined, 'og:site_name');
        if (FACEBOOK_APP_ID) {
            updateTag(FACEBOOK_APP_ID, undefined, 'fb:app_id');
        }
        const currentLocale = globalThis.$i18n.locale;
        updateTag(currentLocale, undefined, 'og:locale');
        updateTag(currentLocale === 'nl' ? 'en' : 'nl', undefined, 'og:locale:alternate');
        if (options.keywords) {
            updateTag(options.keywords, 'keywords');
        }
        if (options.url) {
            updateTag(options.url, undefined, 'og:url');
        }
        if (options.imageUrl) {
            updateTag(options.imageUrl, undefined, 'og:image');
            updateTag(options.imageUrl, undefined, 'twitter:image');
            updateTag('Main image', undefined, 'twitter:image:alt');
        }
        if (options.noIndex) {
            updateTag('noindex, nofollow', 'robots');
        }
        else if (options.noIndex === false) {
            updateTag('index, follow', 'robots');
        }
        document.documentElement.lang = globalThis.$i18n.locale.toLowerCase();
        // https://yoast.com/rel-canonical/
        let canonicalLink = document.querySelector('link[rel="canonical"]');
        if (!canonicalLink) {
            canonicalLink = createLinkTag('canonical', 'canonicalLink');
        }
        canonicalLink.setAttribute('href', `${SITE_URL}${globalThis.$router.currentRoute.path}`);
        let sitemapLink = document.querySelector('link[rel="sitemap"]');
        if (!sitemapLink) {
            sitemapLink = createLinkTag('sitemap', 'sitemapLink', undefined, 'Sitemap', 'application/xml');
        }
        sitemapLink.setAttribute('href', `${SITE_URL}/sitemap.xml`);
        // https://developers.google.com/search/docs/advanced/crawling/localized-versions
        let hrefLangLinks = getHreflangLinks();
        if (!hrefLangLinks.length) {
            createLinkTag('alternate', 'defaultAlternate', 'x-default');
            createLinkTag('alternate', 'nlAlternate', 'nl');
            createLinkTag('alternate', 'enAlternate', 'en');
        }
        hrefLangLinks = getHreflangLinks();
        hrefLangLinks.forEach((link) => {
            if (link.getAttribute('linkId') === 'defaultAlternate') {
                link.setAttribute('href', `${SITE_URL}${globalThis.$router.currentRoute.path.replace('/en', '')}`);
            }
            else if (link.getAttribute('linkId') === 'nlAlternate') {
                link.setAttribute('href', `${SITE_URL}${globalThis.$router.currentRoute.path.replace('/en', '')}`);
            }
            else if (link.getAttribute('linkId') === 'enAlternate') {
                link.setAttribute('href', `${SITE_URL}/en${globalThis.$router.currentRoute.path.replace('/en', '')}`);
            }
        });
    };
    updateMeta();
};
