import { BlogRoutes } from '@/Blog/blog.const';
import SearchHeader from '@/Search/components/SearchHeader.vue';
/** Components */
const EmptyLayout = () => import(
/* webpackChunkName: "blog.route" */ '@/layouts/EmptyLayout.vue');
const Blog = () => import(/* webpackChunkName: "blog.route" */ '@/Blog/Blog.vue');
const Article = () => import(/* webpackChunkName: "article.route" */ '@/Blog/Article.vue');
export const blogRoutes = [
    {
        path: `/${BlogRoutes.BLOG}`,
        localePath: BlogRoutes.BLOG,
        component: EmptyLayout,
        meta: {
            header: SearchHeader
        },
        children: [
            {
                path: '/',
                component: Blog
            },
            {
                path: ':slug',
                beforeEnter: (to, _from, next) => {
                    const locale = to.params.locale;
                    return next({
                        query: { lang: locale }
                    });
                },
                component: Article,
                meta: {
                    lang: 'en'
                }
            }
        ]
    }
];
