const SET_CURRENT_DAY = (state, { day, week }) => {
    state.currentDay = day;
    state.currentWeekNumber = week;
};
const CLEAR_DATE_INFO = (state) => {
    state.currentDay = '';
    state.currentWeekNumber = null;
};
export default {
    CLEAR_DATE_INFO,
    SET_CURRENT_DAY
};
