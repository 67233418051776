const organisation = (state) => state.organisation;
const venuesList = (state) => state.venues;
const venueId = (state) => state.venueId;
const spaceDetails = (state) => state.spaceDetails;
const spaceConfigurations = (state) => state.spaceConfigurations;
const spaceBookingRules = (state) => state.spaceBookingRules;
const spaceFacilities = (state) => state.spaceFacilities;
const spacePricing = (state) => state.spacePricing;
const spaceStaticFeatures = (state) => state.spaceStaticFeatures;
const spaceStaticConfigurations = (state) => state.spaceStaticConfigurations;
const spaceStaticCategories = (state) => state.spaceStaticCategories;
const spaceServices = (state) => state.spaceServices;
const venueDetails = (state) => state.venueDetails;
const venueFeatures = (state) => state.venueFeatures;
const venueFacilities = (state) => state.venueFacilities;
const venueCatering = (state) => state.venueCatering;
const venueServices = (state) => state.venueServices;
const venueTags = (state) => state.venueTags;
const venueWizard = (state) => state.venueWizard;
const spaceWizard = (state) => state.spaceWizard;
export default {
    organisation,
    venuesList,
    venueId,
    venueTags,
    spaceDetails,
    spaceConfigurations,
    spaceBookingRules,
    spaceFacilities,
    spacePricing,
    spaceStaticFeatures,
    spaceStaticConfigurations,
    spaceStaticCategories,
    spaceServices,
    venueDetails,
    venueFeatures,
    venueFacilities,
    venueCatering,
    venueServices,
    venueWizard,
    spaceWizard
};
