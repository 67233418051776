export const DEFAULT_COUNTRY_COORDINATES = {
    lat: 52.2129919,
    lng: 5.2793703
};
export const DEFAULT_PAGE_SIZE = 20;
/**
 * Some of the values are not used directly in the code, but are used in the callbacks and loops.
 * Do not remove unused methods
 */
export var SortMethods;
(function (SortMethods) {
    SortMethods["RESET"] = "resetSort";
    SortMethods["SORT_BY_PRICE_ASC"] = "sortByPriceASC";
    SortMethods["SORT_BY_PRICE_DESC"] = "sortByPriceDESC";
    SortMethods["SORT_BY_RATING_ASC"] = "sortByRatingASC";
    SortMethods["SORT_BY_RATING_DESC"] = "sortByRatingDESC";
    SortMethods["SORT_BY_SIZE_ASC"] = "sortBySizeASC";
    SortMethods["SORT_BY_SIZE_DESC"] = "sortBySizeDESC";
})(SortMethods || (SortMethods = {}));
export var SearchRoutes;
(function (SearchRoutes) {
    SearchRoutes["SEARCH"] = "search";
})(SearchRoutes || (SearchRoutes = {}));
