import { NotificationsRoutes } from '@/Notifications/notifications.const';
import RouteGuard from '@/Authentication/authentication.guard';
/** Components */
const EmptyLayout = () => import(
/* webpackChunkName: "notifications.route" */ '@/layouts/EmptyLayout.vue');
const Notifications = () => import(
/* webpackChunkName: "notifications.route" */ '@/Notifications/Notifications.vue');
export const notificationsRoutes = [
    {
        path: `/${NotificationsRoutes.NOTIFICATIONS}`,
        localePath: NotificationsRoutes.NOTIFICATIONS,
        component: EmptyLayout,
        beforeEnter: RouteGuard.authorized,
        children: [
            {
                path: ':venueId?',
                component: Notifications,
                meta: {
                    maxWidthContainer: true,
                    center: true
                }
            }
        ]
    }
];
