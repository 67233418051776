import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'HomeInfo',
    props: {
        alt: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        img: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    }
});
