import Vue from 'vue';
// Global internal component imports
import Button from '@/uikit/Button.vue';
import Input from '@/uikit/Input.vue';
import Select from '@/uikit/Select.vue';
import Option from '@/uikit/Option.vue';
import Textarea from '@/uikit/Textarea.vue';
import Modal from '@/uikit/Modal.vue';
import Radio from '@/uikit/Radio.vue';
import Checkbox from '@/uikit/Checkbox/Checkbox.vue';
// Storyblok component imports
import ActionButton from '@/StoryBlok/ActionButton.vue';
import Article from '@/StoryBlok/Article.vue';
import CodeInjector from '@/StoryBlok/CodeInjector.vue';
import Column from '@/StoryBlok/Column.vue';
import CustomBlock from '@/StoryBlok/CustomBlock.vue';
import Feature from '@/StoryBlok/Feature.vue';
import Grid from '@/StoryBlok/Grid.vue';
import Headline from '@/StoryBlok/Headline.vue';
import HeroImage from '@/StoryBlok/HeroImage.vue';
import HomeHowTo from '@/StoryBlok/HomeHowTo.vue';
import Logo from '@/StoryBlok/Logo.vue';
import Mark from '@/StoryBlok/Mark.vue';
import Navigation from '@/StoryBlok/Navigation.vue';
import NavItem from '@/StoryBlok/NavItem.vue';
import OneColumn from '@/StoryBlok/OneColumn.vue';
import Page from '@/StoryBlok/Page.vue';
import PopularQuestions from '@/StoryBlok/PopularQuestions.vue';
import Question from '@/StoryBlok/Question.vue';
import Richtext from '@/StoryBlok/Richtext.vue';
import Row from '@/StoryBlok/Row.vue';
import SearchBlock from '@/StoryBlok/SearchBlock.vue';
import Slider from '@/StoryBlok/Slider.vue';
import SocialNetworks from '@/StoryBlok/SocialNetworks.vue';
import SpaceCard from '@/StoryBlok/SpaceCard.vue';
import StoryblokNested from '@/StoryBlok/StoryblokNested.vue';
import Teaser from '@/StoryBlok/Teaser.vue';
import TextBlock from '@/StoryBlok/TextBlock.vue';
import TitledIcon from '@/StoryBlok/TitledIcon.vue';
import YouTube from '@/StoryBlok/YouTube.vue';
// Third-party component imports
import { components } from 'gmap-vue';
const { VueperSlides, VueperSlide } = require('vueperslides');
const VueSlider = require('vue-slider-component');
const Datepicker = require('vuejs-datepicker').default;
const { Cluster } = components;
const globalComponents = [
    // Global internal components
    { name: 'WMButton', component: Button },
    { name: 'WMInput', component: Input },
    { name: 'WMSelect', component: Select },
    { name: 'WMOption', component: Option },
    { name: 'WMTextArea', component: Textarea },
    { name: 'WMModal', component: Modal },
    { name: 'WMRadio', component: Radio },
    { name: 'WMCheckbox', component: Checkbox },
    // Storyblok
    { name: 'action_button', component: ActionButton },
    { name: 'code_injector', component: CodeInjector },
    { name: 'column', component: Column },
    { name: 'custom_block', component: CustomBlock },
    { name: 'feature', component: Feature },
    { name: 'grid', component: Grid },
    { name: 'home_how_to', component: HomeHowTo },
    { name: 'logo', component: Logo },
    { name: 'markBlock', component: Mark },
    { name: 'navigation', component: Navigation },
    { name: 'nav_item', component: NavItem },
    { name: 'one_column', component: OneColumn },
    { name: 'page', component: Page },
    { name: 'popular_questions', component: PopularQuestions },
    { name: 'question', component: Question },
    { name: 'richtext', component: Richtext },
    { name: 'row', component: Row },
    { name: 'search_block', component: SearchBlock },
    { name: 'social_networks', component: SocialNetworks },
    { name: 'storyblok', component: StoryblokNested },
    { name: 'teaser', component: Teaser },
    { name: 'youtube', component: YouTube },
    { name: 'titled_icon', component: TitledIcon },
    { name: 'Article', component: Article },
    { name: 'Headline', component: Headline },
    { name: 'HeroImage', component: HeroImage },
    { name: 'Slider', component: Slider },
    { name: 'SpaceCard', component: SpaceCard },
    { name: 'TextBlock', component: TextBlock },
    // Third-party
    { name: 'VueperSlides', component: VueperSlides },
    { name: 'VueperSlide', component: VueperSlide },
    { name: 'VueSlider', component: VueSlider },
    { name: 'VuejsDatepicker', component: Datepicker },
    { name: 'GmapCluster', component: Cluster }
];
/**
 * Register Vue global components from the globalComponents array
 */
export const registerGlobalComponents = () => {
    globalComponents.forEach((component) => {
        Vue.component(component.name, component.component);
    });
};
