import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import ExtraOptions from 'vue-match-media';
import GmapVue from 'gmap-vue';
import gMapOptions from '@/config/plugins/gMapVueOptions';
import vueGtmOptions from '@/config/plugins/vueGtmOptions';
import InfiniteLoading from 'vue-infinite-loading';
import StoryblokVue from 'storyblok-vue';
import VCalendar from 'v-calendar';
import VScrollLock from 'v-scroll-lock';
import Vuelidate from 'vuelidate';
import VueAxios from 'vue-axios';
import VueClipboard from 'vue-clipboard2';
import VueCompositionAPI from '@vue/composition-api';
import VueCurrencyInput from 'vue-currency-input';
import VueGtm from 'vue-gtm';
import VueMask from 'v-mask';
import VueSocialSharing from 'vue-social-sharing';
import Vue2TouchEvents from 'vue2-touch-events';
const VueScrollTo = require('vue-scrollto');
const VueShortKey = require('vue-shortkey');
VueClipboard.config.autoSetContainer = true;
const plugins = [
    { plugin: GmapVue, options: gMapOptions },
    { plugin: Vuelidate },
    { plugin: VueCompositionAPI },
    {
        plugin: VueCurrencyInput,
        options: { globalOptions: { allowNegative: false } }
    },
    { plugin: VueSocialSharing },
    { plugin: StoryblokVue },
    { plugin: InfiniteLoading },
    { plugin: Vue2TouchEvents },
    { plugin: VueShortKey, options: { prevent: ['input', 'textarea'] } },
    { plugin: VueScrollTo },
    { plugin: VueClipboard },
    { plugin: VueAxios, options: axios },
    { plugin: ExtraOptions },
    { plugin: VCalendar },
    { plugin: VScrollLock },
    { plugin: VueMask, options: { placeholders: { N: null } } },
    { plugin: Vuex },
    { plugin: VueGtm, options: vueGtmOptions }
];
/**
 * Register Vue global plugins
 */
export const registerGlobalPlugins = () => {
    plugins.forEach((plugin) => {
        Vue.use(plugin.plugin, plugin.options || {});
    });
};
