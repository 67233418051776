import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
export var InvoiceType;
(function (InvoiceType) {
    InvoiceType["Invoice"] = "INVOICE";
    InvoiceType["Payout"] = "PAYOUT";
    InvoiceType["ProofOfPayment"] = "PROOF_OF_PAYMENT";
    InvoiceType["Refund"] = "REFUND";
})(InvoiceType || (InvoiceType = {}));
export var PayoutStatus;
(function (PayoutStatus) {
    PayoutStatus["Completed"] = "COMPLETED";
    PayoutStatus["DuplicatedRequest"] = "DUPLICATED_REQUEST";
    PayoutStatus["Failed"] = "FAILED";
    PayoutStatus["Pending"] = "PENDING";
})(PayoutStatus || (PayoutStatus = {}));
export var UserRoleState;
(function (UserRoleState) {
    UserRoleState["Booker"] = "BOOKER";
    UserRoleState["Vendor"] = "VENDOR";
})(UserRoleState || (UserRoleState = {}));
export var LocaleType;
(function (LocaleType) {
    LocaleType["En"] = "EN";
    LocaleType["Nl"] = "NL";
})(LocaleType || (LocaleType = {}));
export var ImageEntityType;
(function (ImageEntityType) {
    ImageEntityType["Account"] = "ACCOUNT";
    ImageEntityType["Organisation"] = "ORGANISATION";
    ImageEntityType["Space"] = "SPACE";
    ImageEntityType["Venue"] = "VENUE";
})(ImageEntityType || (ImageEntityType = {}));
export var FeatureType;
(function (FeatureType) {
    FeatureType["Global"] = "GLOBAL";
    FeatureType["Space"] = "SPACE";
    FeatureType["Venue"] = "VENUE";
})(FeatureType || (FeatureType = {}));
export var LocationType;
(function (LocationType) {
    LocationType["City"] = "CITY";
    LocationType["Region"] = "REGION";
})(LocationType || (LocationType = {}));
export var SpaceOrderItemType;
(function (SpaceOrderItemType) {
    /** The service price is for arrangement */
    SpaceOrderItemType["Arrangement"] = "ARRANGEMENT";
    /** The service price is for one hour of usage */
    SpaceOrderItemType["PerHour"] = "PER_HOUR";
    /** The service price is for each item */
    SpaceOrderItemType["PerItem"] = "PER_ITEM";
    /** The service price is for each item or for one hour of usage */
    SpaceOrderItemType["PerItemOrPerHour"] = "PER_ITEM_OR_PER_HOUR";
    /** The service price is for each person in the meeting */
    SpaceOrderItemType["PerPerson"] = "PER_PERSON";
    /** The service price is fixed */
    SpaceOrderItemType["Static"] = "STATIC";
})(SpaceOrderItemType || (SpaceOrderItemType = {}));
export var SystemServiceType;
(function (SystemServiceType) {
    SystemServiceType["Catering"] = "CATERING";
    SystemServiceType["Facility"] = "FACILITY";
    SystemServiceType["HumanResource"] = "HUMAN_RESOURCE";
})(SystemServiceType || (SystemServiceType = {}));
export var VenueType;
(function (VenueType) {
    VenueType["Business"] = "BUSINESS";
    VenueType["Social"] = "SOCIAL";
})(VenueType || (VenueType = {}));
export var SpaceBookingType;
(function (SpaceBookingType) {
    SpaceBookingType["Direct"] = "DIRECT";
    SpaceBookingType["Manual"] = "MANUAL";
})(SpaceBookingType || (SpaceBookingType = {}));
export var ConfigurationType;
(function (ConfigurationType) {
    ConfigurationType["Banquet"] = "BANQUET";
    ConfigurationType["Block"] = "BLOCK";
    ConfigurationType["Circle"] = "CIRCLE";
    ConfigurationType["Classroom"] = "CLASSROOM";
    ConfigurationType["Cocktail"] = "COCKTAIL";
    ConfigurationType["Informal"] = "INFORMAL";
    ConfigurationType["OpenSpace"] = "OPEN_SPACE";
    ConfigurationType["Theatre"] = "THEATRE";
    ConfigurationType["UShape"] = "U_SHAPE";
})(ConfigurationType || (ConfigurationType = {}));
export var OrderItemStatusType;
(function (OrderItemStatusType) {
    OrderItemStatusType["Cancelled"] = "CANCELLED";
    OrderItemStatusType["Declined"] = "DECLINED";
    OrderItemStatusType["Draft"] = "DRAFT";
    OrderItemStatusType["Expired"] = "EXPIRED";
    OrderItemStatusType["Paid"] = "PAID";
    OrderItemStatusType["PaymentProcessing"] = "PAYMENT_PROCESSING";
    OrderItemStatusType["Pending"] = "PENDING";
    OrderItemStatusType["Refunded"] = "REFUNDED";
})(OrderItemStatusType || (OrderItemStatusType = {}));
export var OrderItemType;
(function (OrderItemType) {
    OrderItemType["Configuration"] = "CONFIGURATION";
    OrderItemType["Custom"] = "CUSTOM";
    OrderItemType["Service"] = "SERVICE";
    OrderItemType["Space"] = "SPACE";
    OrderItemType["Venue"] = "VENUE";
})(OrderItemType || (OrderItemType = {}));
export var OrderStateType;
(function (OrderStateType) {
    OrderStateType["ClosedByCustomer"] = "CLOSED_BY_CUSTOMER";
    OrderStateType["ClosedByHost"] = "CLOSED_BY_HOST";
    OrderStateType["CustomerApproval"] = "CUSTOMER_APPROVAL";
    OrderStateType["Expired"] = "EXPIRED";
    OrderStateType["HostApproval"] = "HOST_APPROVAL";
    OrderStateType["Paid"] = "PAID";
    OrderStateType["PaymentFailed"] = "PAYMENT_FAILED";
    OrderStateType["PaymentPending"] = "PAYMENT_PENDING";
    OrderStateType["PaymentProcessing"] = "PAYMENT_PROCESSING";
    OrderStateType["PayoutCompleted"] = "PAYOUT_COMPLETED";
    OrderStateType["PayoutFailed"] = "PAYOUT_FAILED";
    OrderStateType["PayoutPending"] = "PAYOUT_PENDING";
})(OrderStateType || (OrderStateType = {}));
export var OrderStatusType;
(function (OrderStatusType) {
    OrderStatusType["Cancelled"] = "CANCELLED";
    OrderStatusType["Declined"] = "DECLINED";
    OrderStatusType["Draft"] = "DRAFT";
    OrderStatusType["Expired"] = "EXPIRED";
    OrderStatusType["Paid"] = "PAID";
    /** @deprecated Passed order stays in PAID status */
    OrderStatusType["Passed"] = "PASSED";
    OrderStatusType["PaymentProcessing"] = "PAYMENT_PROCESSING";
    OrderStatusType["Pending"] = "PENDING";
    OrderStatusType["Refunded"] = "REFUNDED";
})(OrderStatusType || (OrderStatusType = {}));
export var BookingStatus;
(function (BookingStatus) {
    /** Bookings cancelled by vendor or customer */
    BookingStatus["Cancelled"] = "CANCELLED";
    /** Booking request/offer was declined by the vendor/customer respectively */
    BookingStatus["Declined"] = "DECLINED";
    /** Booking is created but not all mandatory fields are provided. Can't be paid */
    BookingStatus["Draft"] = "DRAFT";
    /** Expired bookings (not being paid within the payment deadline) */
    BookingStatus["Expired"] = "EXPIRED";
    /** Bookings being paid and the event hasn't started yet */
    BookingStatus["Paid"] = "PAID";
    /** Bookings being paid and the event has already finished */
    BookingStatus["Passed"] = "PASSED";
    /** Bookings with the payment currently being in process by the external payment system */
    BookingStatus["PaymentProcessing"] = "PAYMENT_PROCESSING";
    /** Bookings pending for payment */
    BookingStatus["Pending"] = "PENDING";
})(BookingStatus || (BookingStatus = {}));
export var BookingType;
(function (BookingType) {
    /** Booking for space the customer can book without vendor confirmation */
    BookingType["Direct"] = "DIRECT";
    /** Booking created by vendor and been offered to the customer */
    BookingType["Offer"] = "OFFER";
    /** Booking which needs to be validated and approved by the vendor */
    BookingType["Request"] = "REQUEST";
})(BookingType || (BookingType = {}));
export var ChatMessageType;
(function (ChatMessageType) {
    ChatMessageType["SpaceVisitRequested"] = "SPACE_VISIT_REQUESTED";
})(ChatMessageType || (ChatMessageType = {}));
export var ChatType;
(function (ChatType) {
    ChatType["Booking"] = "BOOKING";
    ChatType["Space"] = "SPACE";
    ChatType["Support"] = "SUPPORT";
    ChatType["Venue"] = "VENUE";
})(ChatType || (ChatType = {}));
export var CalendarProviderType;
(function (CalendarProviderType) {
    CalendarProviderType["Apple"] = "apple";
    CalendarProviderType["Exchange"] = "exchange";
    CalendarProviderType["Google"] = "google";
    CalendarProviderType["LiveConnect"] = "live_connect";
    CalendarProviderType["Office365"] = "office365";
})(CalendarProviderType || (CalendarProviderType = {}));
export var EventNotificationName;
(function (EventNotificationName) {
    EventNotificationName["AdminAdded"] = "admin_added";
    EventNotificationName["AdminRemoved"] = "admin_removed";
    EventNotificationName["BookingCancelledByCustomer"] = "booking_cancelled_by_customer";
    EventNotificationName["BookingCancelledByCustomerWithRefund"] = "booking_cancelled_by_customer_with_refund";
    EventNotificationName["BookingCancelledByCustomerWithoutRefund"] = "booking_cancelled_by_customer_without_refund";
    EventNotificationName["BookingCancelledByHost"] = "booking_cancelled_by_host";
    EventNotificationName["BookingExpired"] = "booking_expired";
    EventNotificationName["BookingPassed"] = "booking_passed";
    EventNotificationName["BookingRefunded"] = "booking_refunded";
    EventNotificationName["BookingRequestApproved"] = "booking_request_approved";
    EventNotificationName["BookingRequestCancelled"] = "booking_request_cancelled";
    EventNotificationName["BookingRequestCreatedForCustomer"] = "booking_request_created_for_customer";
    EventNotificationName["BookingRequestCreatedForHost"] = "booking_request_created_for_host";
    EventNotificationName["BookingRequestDeclined"] = "booking_request_declined";
    EventNotificationName["BookingRequestExpiredForCustomer"] = "booking_request_expired_for_customer";
    EventNotificationName["BookingRequestExpiredForHost"] = "booking_request_expired_for_host";
    EventNotificationName["BookingUnpaid"] = "booking_unpaid";
    EventNotificationName["BookingUpdatedByHost"] = "booking_updated_by_host";
    EventNotificationName["ChangeRequestCancelledByCustomer"] = "change_request_cancelled_by_customer";
    EventNotificationName["ChangeRequestCancelledByHost"] = "change_request_cancelled_by_host";
    EventNotificationName["ChangeRequestCreatedForCustomer"] = "change_request_created_for_customer";
    EventNotificationName["ChangeRequestCreatedForHost"] = "change_request_created_for_host";
    EventNotificationName["ChangeRequestDeclined"] = "change_request_declined";
    EventNotificationName["ChangeRequestExpiredForCustomer"] = "change_request_expired_for_customer";
    EventNotificationName["ChangeRequestExpiredForHost"] = "change_request_expired_for_host";
    EventNotificationName["DayOverview"] = "day_overview";
    EventNotificationName["DirectBookingCreated"] = "direct_booking_created";
    EventNotificationName["FirstOrganisationCreated"] = "first_organisation_created";
    EventNotificationName["FirstSpaceCreated"] = "first_space_created";
    EventNotificationName["FirstVenueCreated"] = "first_venue_created";
    EventNotificationName["LoginEmailVerification"] = "login_email_verification";
    EventNotificationName["LoginSmsVerification"] = "login_sms_verification";
    EventNotificationName["OfferAccepted"] = "offer_accepted";
    EventNotificationName["OrderPaid"] = "order_paid";
    EventNotificationName["OrganisationBanned"] = "organisation_banned";
    EventNotificationName["OrganisationRatingCritical"] = "organisation_rating_critical";
    EventNotificationName["PaymentInvoice"] = "payment_invoice";
    EventNotificationName["PayoutCreditNote"] = "payout_credit_note";
    EventNotificationName["PayoutDetailsSubmitted"] = "payout_details_submitted";
    EventNotificationName["PayoutFailure"] = "payout_failure";
    EventNotificationName["PhoneVerification"] = "phone_verification";
    EventNotificationName["ProspectRequestApproved"] = "prospect_request_approved";
    EventNotificationName["ProspectRequestCreatedForCustomer"] = "prospect_request_created_for_customer";
    EventNotificationName["ProspectRequestCreatedForHost"] = "prospect_request_created_for_host";
    EventNotificationName["ProspectRequestUnsubscribedForHost"] = "prospect_request_unsubscribed_for_host";
    EventNotificationName["RefundInvoice"] = "refund_invoice";
    EventNotificationName["SignupEmailVerification"] = "signup_email_verification";
    EventNotificationName["SpaceActivated"] = "space_activated";
    EventNotificationName["SpaceAgendaIsNotSynchronized"] = "space_agenda_is_not_synchronized";
    EventNotificationName["TeamMembershipInvitation"] = "team_membership_invitation";
    EventNotificationName["UpdateLocationDetails"] = "update_location_details";
    EventNotificationName["UpdateOrganisationDetails"] = "update_organisation_details";
    EventNotificationName["UpdateProfile"] = "update_profile";
    EventNotificationName["UpdateSpaceDetails"] = "update_space_details";
    EventNotificationName["WelcomeEmail"] = "welcome_email";
})(EventNotificationName || (EventNotificationName = {}));
export var EventNotificationStatus;
(function (EventNotificationStatus) {
    EventNotificationStatus["Active"] = "ACTIVE";
    EventNotificationStatus["Expired"] = "EXPIRED";
    EventNotificationStatus["Performed"] = "PERFORMED";
})(EventNotificationStatus || (EventNotificationStatus = {}));
export var EventNotificationType;
(function (EventNotificationType) {
    EventNotificationType["Action"] = "ACTION";
    EventNotificationType["Notification"] = "NOTIFICATION";
    EventNotificationType["System"] = "SYSTEM";
})(EventNotificationType || (EventNotificationType = {}));
export var PlaceApiStatus;
(function (PlaceApiStatus) {
    PlaceApiStatus["InvalidRequest"] = "INVALID_REQUEST";
    PlaceApiStatus["NotFound"] = "NOT_FOUND";
    PlaceApiStatus["Ok"] = "OK";
    PlaceApiStatus["OverQueryLimit"] = "OVER_QUERY_LIMIT";
    PlaceApiStatus["RequestDenied"] = "REQUEST_DENIED";
    PlaceApiStatus["UnknownError"] = "UNKNOWN_ERROR";
    PlaceApiStatus["ZeroResults"] = "ZERO_RESULTS";
})(PlaceApiStatus || (PlaceApiStatus = {}));
export var AuthFlow;
(function (AuthFlow) {
    AuthFlow["Login"] = "LOGIN";
    AuthFlow["Signup"] = "SIGNUP";
})(AuthFlow || (AuthFlow = {}));
export var EventScope;
(function (EventScope) {
    EventScope["Admin"] = "ADMIN";
    EventScope["Authentication"] = "AUTHENTICATION";
    EventScope["Booking"] = "BOOKING";
    EventScope["Location"] = "LOCATION";
})(EventScope || (EventScope = {}));
export var OrderType;
(function (OrderType) {
    OrderType["PerItem"] = "PER_ITEM";
    OrderType["PerItemOrPerHour"] = "PER_ITEM_OR_PER_HOUR";
    OrderType["Static"] = "STATIC";
})(OrderType || (OrderType = {}));
export var SystemServiceName2;
(function (SystemServiceName2) {
    SystemServiceName2["AudioConferencing"] = "AUDIO_CONFERENCING";
    SystemServiceName2["Beamer"] = "BEAMER";
    SystemServiceName2["FlatscreenTv"] = "FLATSCREEN_TV";
    SystemServiceName2["Flipover"] = "FLIPOVER";
    SystemServiceName2["HybridMeeting"] = "HYBRID_MEETING";
    SystemServiceName2["LightInstallation"] = "LIGHT_INSTALLATION";
    SystemServiceName2["MusicInstruments"] = "MUSIC_INSTRUMENTS";
    SystemServiceName2["SelfServiceBeverages"] = "SELF_SERVICE_BEVERAGES";
    SystemServiceName2["SmartBoard"] = "SMART_BOARD";
    SystemServiceName2["SoundSystemExclMic"] = "SOUND_SYSTEM_EXCL_MIC";
    SystemServiceName2["SoundSystemInclMic"] = "SOUND_SYSTEM_INCL_MIC";
    SystemServiceName2["StageParts"] = "STAGE_PARTS";
    SystemServiceName2["WhiteBoard"] = "WHITE_BOARD";
})(SystemServiceName2 || (SystemServiceName2 = {}));
export var SystemServiceType2;
(function (SystemServiceType2) {
    SystemServiceType2["Facility"] = "FACILITY";
})(SystemServiceType2 || (SystemServiceType2 = {}));
export var SystemServiceName;
(function (SystemServiceName) {
    SystemServiceName["Breakfast"] = "BREAKFAST";
    SystemServiceName["BreakfastGlutenFree"] = "BREAKFAST_GLUTEN_FREE";
    SystemServiceName["BreakfastHalal"] = "BREAKFAST_HALAL";
    SystemServiceName["BreakfastVegan"] = "BREAKFAST_VEGAN";
    SystemServiceName["BreakfastVegetarian"] = "BREAKFAST_VEGETARIAN";
    SystemServiceName["Childcare"] = "CHILDCARE";
    SystemServiceName["CoffeeTeaIsServed"] = "COFFEE_TEA_IS_SERVED";
    SystemServiceName["Dinner"] = "DINNER";
    SystemServiceName["DinnerGlutenFree"] = "DINNER_GLUTEN_FREE";
    SystemServiceName["DinnerHalal"] = "DINNER_HALAL";
    SystemServiceName["DinnerVegan"] = "DINNER_VEGAN";
    SystemServiceName["DinnerVegetarian"] = "DINNER_VEGETARIAN";
    SystemServiceName["DrinksAlcoholic"] = "DRINKS_ALCOHOLIC";
    SystemServiceName["DrinksNonAlcoholic"] = "DRINKS_NON_ALCOHOLIC";
    SystemServiceName["GeneralAssistant"] = "GENERAL_ASSISTANT";
    SystemServiceName["Kitchen"] = "KITCHEN";
    SystemServiceName["Locker"] = "LOCKER";
    SystemServiceName["Lunch"] = "LUNCH";
    SystemServiceName["LunchGlutenFree"] = "LUNCH_GLUTEN_FREE";
    SystemServiceName["LunchHalal"] = "LUNCH_HALAL";
    SystemServiceName["LunchVegan"] = "LUNCH_VEGAN";
    SystemServiceName["LunchVegetarian"] = "LUNCH_VEGETARIAN";
    SystemServiceName["MusicInstruments"] = "MUSIC_INSTRUMENTS";
    SystemServiceName["Parking"] = "PARKING";
    SystemServiceName["Printer"] = "PRINTER";
    SystemServiceName["Scanner"] = "SCANNER";
    SystemServiceName["SnacksCakes"] = "SNACKS_CAKES";
    SystemServiceName["SnacksCold"] = "SNACKS_COLD";
    SystemServiceName["SnacksCookies"] = "SNACKS_COOKIES";
    SystemServiceName["SnacksPretzels"] = "SNACKS_PRETZELS";
    SystemServiceName["SnacksWarm"] = "SNACKS_WARM";
})(SystemServiceName || (SystemServiceName = {}));
export var ConfigurationType2;
(function (ConfigurationType2) {
    ConfigurationType2["Banquet"] = "BANQUET";
    ConfigurationType2["Block"] = "BLOCK";
    ConfigurationType2["Circle"] = "CIRCLE";
    ConfigurationType2["Classroom"] = "CLASSROOM";
    ConfigurationType2["Cocktail"] = "COCKTAIL";
    ConfigurationType2["Informal"] = "INFORMAL";
    ConfigurationType2["OpenSpace"] = "OPEN_SPACE";
    ConfigurationType2["Theatre"] = "THEATRE";
    ConfigurationType2["UShape"] = "U_SHAPE";
})(ConfigurationType2 || (ConfigurationType2 = {}));
export var FeatureName;
(function (FeatureName) {
    FeatureName["Airconditioning"] = "AIRCONDITIONING";
    FeatureName["BarCafe"] = "BAR_CAFE";
    FeatureName["CabledInternet"] = "CABLED_INTERNET";
    FeatureName["Catering"] = "CATERING";
    FeatureName["GardenTerraceCourtyard"] = "GARDEN_TERRACE_COURTYARD";
    FeatureName["HostOnSite"] = "HOST_ON_SITE";
    FeatureName["InspiringLocation"] = "INSPIRING_LOCATION";
    FeatureName["Privacy"] = "PRIVACY";
    FeatureName["PublicTransportNearby"] = "PUBLIC_TRANSPORT_NEARBY";
    FeatureName["Restaurant"] = "RESTAURANT";
    FeatureName["RoomsForOvernightStay"] = "ROOMS_FOR_OVERNIGHT_STAY";
    FeatureName["ToiletForDisabled"] = "TOILET_FOR_DISABLED";
    FeatureName["Waterside"] = "WATERSIDE";
    FeatureName["WheelchairAccessible"] = "WHEELCHAIR_ACCESSIBLE";
})(FeatureName || (FeatureName = {}));
export var FeatureType3;
(function (FeatureType3) {
    FeatureType3["Global"] = "GLOBAL";
})(FeatureType3 || (FeatureType3 = {}));
export var FeatureType2;
(function (FeatureType2) {
    FeatureType2["Space"] = "SPACE";
})(FeatureType2 || (FeatureType2 = {}));
export var PhoneUsageType;
(function (PhoneUsageType) {
    PhoneUsageType["General"] = "GENERAL";
    PhoneUsageType["Login"] = "LOGIN";
})(PhoneUsageType || (PhoneUsageType = {}));
export var OrganisationType;
(function (OrganisationType) {
    OrganisationType["Private"] = "PRIVATE";
    OrganisationType["Public"] = "PUBLIC";
})(OrganisationType || (OrganisationType = {}));
export var EntityType2;
(function (EntityType2) {
    EntityType2["Account"] = "ACCOUNT";
    EntityType2["Organisation"] = "ORGANISATION";
    EntityType2["Space"] = "SPACE";
    EntityType2["Venue"] = "VENUE";
})(EntityType2 || (EntityType2 = {}));
export var OrderType2;
(function (OrderType2) {
    OrderType2["PerHour"] = "PER_HOUR";
    OrderType2["PerItem"] = "PER_ITEM";
    OrderType2["Static"] = "STATIC";
})(OrderType2 || (OrderType2 = {}));
export var SystemServiceType3;
(function (SystemServiceType3) {
    SystemServiceType3["Catering"] = "CATERING";
    SystemServiceType3["Facility"] = "FACILITY";
})(SystemServiceType3 || (SystemServiceType3 = {}));
export var FeatureType4;
(function (FeatureType4) {
    FeatureType4["Global"] = "GLOBAL";
    FeatureType4["Space"] = "SPACE";
    FeatureType4["Venue"] = "VENUE";
})(FeatureType4 || (FeatureType4 = {}));
export var VenueType2;
(function (VenueType2) {
    VenueType2["Business"] = "BUSINESS";
    VenueType2["Social"] = "SOCIAL";
})(VenueType2 || (VenueType2 = {}));
export var AccountState;
(function (AccountState) {
    AccountState["Booker"] = "BOOKER";
    AccountState["Vendor"] = "VENDOR";
})(AccountState || (AccountState = {}));
export var Locale2;
(function (Locale2) {
    Locale2["En"] = "EN";
    Locale2["Nl"] = "NL";
})(Locale2 || (Locale2 = {}));
export var RoleType;
(function (RoleType) {
    RoleType["Cleaner"] = "CLEANER";
    RoleType["Finance"] = "FINANCE";
    RoleType["KeyManager"] = "KEY_MANAGER";
    RoleType["Owner"] = "OWNER";
    RoleType["Support"] = "SUPPORT";
    RoleType["VenueManager"] = "VENUE_MANAGER";
})(RoleType || (RoleType = {}));
export var OrganisationType2;
(function (OrganisationType2) {
    OrganisationType2["Private"] = "PRIVATE";
    OrganisationType2["Public"] = "PUBLIC";
})(OrganisationType2 || (OrganisationType2 = {}));
export var OrderType3;
(function (OrderType3) {
    OrderType3["PerHour"] = "PER_HOUR";
    OrderType3["PerItem"] = "PER_ITEM";
    OrderType3["Static"] = "STATIC";
})(OrderType3 || (OrderType3 = {}));
export var VenueServiceOrderType;
(function (VenueServiceOrderType) {
    VenueServiceOrderType["Arrangement"] = "ARRANGEMENT";
    VenueServiceOrderType["PerHour"] = "PER_HOUR";
    VenueServiceOrderType["PerItem"] = "PER_ITEM";
    VenueServiceOrderType["PerPerson"] = "PER_PERSON";
})(VenueServiceOrderType || (VenueServiceOrderType = {}));
export var OrderItemType2;
(function (OrderItemType2) {
    OrderItemType2["Configuration"] = "CONFIGURATION";
    OrderItemType2["Custom"] = "CUSTOM";
    OrderItemType2["Service"] = "SERVICE";
    OrderItemType2["Venue"] = "VENUE";
})(OrderItemType2 || (OrderItemType2 = {}));
export var SystemServiceOrderType;
(function (SystemServiceOrderType) {
    SystemServiceOrderType["PerHour"] = "PER_HOUR";
    SystemServiceOrderType["PerItem"] = "PER_ITEM";
    SystemServiceOrderType["PerItemPerHour"] = "PER_ITEM_PER_HOUR";
    SystemServiceOrderType["Static"] = "STATIC";
})(SystemServiceOrderType || (SystemServiceOrderType = {}));
export var BookingType2;
(function (BookingType2) {
    BookingType2["Direct"] = "DIRECT";
    BookingType2["Manual"] = "MANUAL";
})(BookingType2 || (BookingType2 = {}));
export var Locale;
(function (Locale) {
    Locale["En"] = "EN";
    Locale["Nl"] = "NL";
})(Locale || (Locale = {}));
export var Method;
(function (Method) {
    Method["Email"] = "EMAIL";
    Method["Phone"] = "PHONE";
})(Method || (Method = {}));
export var EntityType;
(function (EntityType) {
    EntityType["Account"] = "ACCOUNT";
    EntityType["Organisation"] = "ORGANISATION";
    EntityType["Space"] = "SPACE";
    EntityType["Venue"] = "VENUE";
})(EntityType || (EntityType = {}));
export var UserEventPermission;
(function (UserEventPermission) {
    UserEventPermission["AllowedToActivateSpacesWithoutSync"] = "ALLOWED_TO_ACTIVATE_SPACES_WITHOUT_SYNC";
    UserEventPermission["AllowedToCancelPaidBookingsAsVendor"] = "ALLOWED_TO_CANCEL_PAID_BOOKINGS_AS_VENDOR";
    UserEventPermission["AllowedToSetSpaceBookingTypes"] = "ALLOWED_TO_SET_SPACE_BOOKING_TYPES";
})(UserEventPermission || (UserEventPermission = {}));
export const SpecificBookingFragmentDoc = gql `
    fragment SpecificBooking on Booking {
  booking_id
  slot_start
  slot_end
  start_time
  end_time
  attendees
  reservation_time
  meeting_name
  meeting_description
  is_reservation_extended
  is_discount_applied
  is_afterpay_requested
  is_deleted
  replacement {
    booking_id
  }
  booking_type
  is_on_dispute
  is_visible
  booking_status
  booking_hash
  ts_reservation_end
  cancellation_reason
  ts_created
  origin {
    booking_id
    slot_start
    slot_end
    start_time
    end_time
    attendees
    reservation_time
    meeting_name
    meeting_description
    is_reservation_extended
    is_discount_applied
    is_afterpay_requested
    is_deleted
    booking_type
    is_on_dispute
    is_visible
    booking_status
    booking_hash
    ts_reservation_end
    cancellation_reason
    ts_created
    booking_meta {
      coupon {
        coupon_id
        coupon_code
      }
      is_prospect_booking
    }
    order {
      order_dispute_th
      order_id
      order_status
      order_state
      service_fee_vat
      order_total
      payout_total
      order_total_vat_excl
      ts_order_cancellation_dl
      order_subtotal
      refunded_total
      discount_total
      service_fee
      service_fee_total
      total_paid
      vat_total
      payment_link
      booker {
        first_name
        last_name
        email
        phone
      }
      order_items {
        is_mandatory
        order_item_id
        reference_id
        order_item_type
        order_item_total
        order_item_price
        service_name
        service_type
        quantity
        vat_rate
        vat
        service_fee
      }
      order_meta {
        organisation_name
        billing_address
        kvk_number
        afterpay_description
      }
      order_state
    }
    space {
      venue {
        venue_geo {
          address
        }
        venue_name
        venue_url
        venue_rules
      }
      space_id
      space_name
      internal_name
      space_reference
      discount_percentage
      is_afterpay_allowed
      is_payment_options_selectable
      is_prospect
      service_fee_rate
      space_images {
        image_url
      }
      space_configurations {
        configuration_id
        configuration_type
        configuration_price
      }
      space_services {
        system_service_name
        system_service_type
        system_service_id
        price
      }
      space_url
    }
    booking_history {
      booking_status
      order_status
      order_state
      ts_created
    }
  }
  booking_meta {
    coupon {
      coupon_id
      coupon_code
    }
    is_prospect_booking
  }
  order {
    order_dispute_th
    order_id
    order_status
    order_state
    service_fee_vat
    order_total
    payout_total
    order_total_vat_excl
    ts_order_cancellation_dl
    order_subtotal
    refunded_total
    discount_total
    service_fee
    service_fee_total
    total_paid
    vat_total
    payment_link
    booker {
      first_name
      last_name
      email
      phone
    }
    order_items {
      is_mandatory
      order_item_id
      reference_id
      order_item_type
      order_item_total
      order_item_price
      service_name
      service_type
      quantity
      vat_rate
      vat
      service_fee
    }
    order_meta {
      organisation_name
      billing_address
      kvk_number
      afterpay_description
    }
    order_state
  }
  space {
    venue {
      venue_geo {
        address
      }
      venue_name
      venue_url
      venue_rules
    }
    space_id
    space_name
    internal_name
    space_reference
    discount_percentage
    is_afterpay_allowed
    is_payment_options_selectable
    is_prospect
    service_fee_rate
    space_images {
      image_url
    }
    space_configurations {
      configuration_id
      configuration_type
      configuration_price
    }
    space_services {
      system_service_name
      system_service_type
      system_service_id
      price
    }
    space_url
  }
  can_be_edited
  booking_history {
    booking_status
    order_status
    order_state
    ts_created
  }
}
    `;
export const NotificationFragmentDoc = gql `
    fragment Notification on EventNotification {
  event_name
  event_notification_id
  event_status
  event_type
  is_read
  is_deleted
  venue_id
  event_type
  space {
    space_id
    internal_name
    venue {
      venue_id
    }
  }
  booking {
    ts_reservation_end
    booking_id
    booking_status
    slot_start
    is_discount_applied
    is_afterpay_requested
    order {
      ts_host_approval_dl
      ts_customer_approval_dl
      booker {
        first_name
        last_name
      }
      vendor {
        first_name
        last_name
      }
      order_state
    }
    space {
      space_id
    }
  }
}
    `;
export const InviteMembersDocument = gql `
    mutation inviteMembers($emails: [String]) {
  send_teammate_invitations(emails: $emails) {
    account_id
    email
    first_name
    last_name
  }
}
    `;
/**
 * __useInviteMembersMutation__
 *
 * To run a mutation, you first call `useInviteMembersMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInviteMembersMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInviteMembersMutation({
 *   variables: {
 *     emails: // value for 'emails'
 *   },
 * });
 */
export function useInviteMembersMutation(options = {}) {
    return VueApolloComposable.useMutation(InviteMembersDocument, options);
}
export const RemoveTeamMemberDocument = gql `
    mutation removeTeamMember($email: String!) {
  delete_team_member(email: $email) {
    account_id
    email
    first_name
    last_name
  }
}
    `;
/**
 * __useRemoveTeamMemberMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMemberMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMemberMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveTeamMemberMutation({
 *   variables: {
 *     email: // value for 'email'
 *   },
 * });
 */
export function useRemoveTeamMemberMutation(options = {}) {
    return VueApolloComposable.useMutation(RemoveTeamMemberDocument, options);
}
export const UpdateOrganisationDocument = gql `
    mutation updateOrganisation($input: OrganisationPublicInput!, $token: String!) {
  mutationViewerApiKey(apiKey: $token) {
    patchApiOrganisationPublic(organisationPublicInput: $input) {
      organisationId
    }
  }
}
    `;
/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOrganisationMutation({
 *   variables: {
 *     input: // value for 'input'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateOrganisationDocument, options);
}
export const UpdateUserNotificationsPreferencesDocument = gql `
    mutation updateUserNotificationsPreferences($input: [UpdatedUserNotificationInput!]!) {
  update_user_notification_preferences(input: $input) {
    event_id
  }
}
    `;
/**
 * __useUpdateUserNotificationsPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationsPreferencesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationsPreferencesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserNotificationsPreferencesMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserNotificationsPreferencesMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateUserNotificationsPreferencesDocument, options);
}
export const GetCompaniesDocument = gql `
    query getCompanies($company_name: String!) {
  get_companies(company_name: $company_name) {
    legal_name
    coc_number
    address
  }
}
    `;
/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a Vue component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCompaniesQuery({
 *   company_name: // value for 'company_name'
 * });
 */
export function useGetCompaniesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetCompaniesDocument, variables, options);
}
export function useGetCompaniesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetCompaniesDocument, variables, options);
}
export const OrganisationServiceFeeDocument = gql `
    query organisationServiceFee($token: String!) {
  viewerApiKey(apiKey: $token) {
    getApiOrganisation {
      serviceFee
      vatRate
    }
  }
}
    `;
/**
 * __useOrganisationServiceFeeQuery__
 *
 * To run a query within a Vue component, call `useOrganisationServiceFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationServiceFeeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganisationServiceFeeQuery({
 *   token: // value for 'token'
 * });
 */
export function useOrganisationServiceFeeQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(OrganisationServiceFeeDocument, variables, options);
}
export function useOrganisationServiceFeeLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(OrganisationServiceFeeDocument, variables, options);
}
export const GetUserNotificationsDocument = gql `
    query getUserNotifications {
  get_all_user_system_notifications {
    event_id
    is_enabled
  }
}
    `;
/**
 * __useGetUserNotificationsQuery__
 *
 * To run a query within a Vue component, call `useGetUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserNotificationsQuery();
 */
export function useGetUserNotificationsQuery(options = {}) {
    return VueApolloComposable.useQuery(GetUserNotificationsDocument, {}, options);
}
export function useGetUserNotificationsLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetUserNotificationsDocument, {}, options);
}
export const TeamMembersDocument = gql `
    query teamMembers {
  all_team_members {
    account_id
    email
    first_name
    last_name
  }
}
    `;
/**
 * __useTeamMembersQuery__
 *
 * To run a query within a Vue component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTeamMembersQuery();
 */
export function useTeamMembersQuery(options = {}) {
    return VueApolloComposable.useQuery(TeamMembersDocument, {}, options);
}
export function useTeamMembersLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(TeamMembersDocument, {}, options);
}
export const UpdateCityDetailsDocument = gql `
    mutation updateCityDetails($input: SeoCityDetails!) {
  update_city_seo(input: $input) {
    location_id
  }
}
    `;
/**
 * __useUpdateCityDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCityDetailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCityDetailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCityDetailsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCityDetailsMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateCityDetailsDocument, options);
}
export const UpdateSpaceSeoDetailsDocument = gql `
    mutation updateSpaceSeoDetails($input: SpaceSeoDetails!) {
  update_space_seo(input: $input) {
    description
  }
}
    `;
/**
 * __useUpdateSpaceSeoDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateSpaceSeoDetailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpaceSeoDetailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSpaceSeoDetailsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpaceSeoDetailsMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateSpaceSeoDetailsDocument, options);
}
export const DeleteFeedbackDocument = gql `
    mutation deleteFeedback($feedbackId: Int!) {
  delete_feedback(feedback_id: $feedbackId) {
    feedback_id
  }
}
    `;
/**
 * __useDeleteFeedbackMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteFeedbackMutation({
 *   variables: {
 *     feedbackId: // value for 'feedbackId'
 *   },
 * });
 */
export function useDeleteFeedbackMutation(options = {}) {
    return VueApolloComposable.useMutation(DeleteFeedbackDocument, options);
}
export const AddFeedbackDocument = gql `
    mutation addFeedback($avatarImage: String, $userName: String!, $userCompanyName: String, $userCompanyPosition: String, $userRole: UserRoleState!, $feedback: String!) {
  add_feedback(avatar_image: $avatarImage, user_name: $userName, user_company_name: $userCompanyName, user_company_position: $userCompanyPosition, user_role: $userRole, feedback: $feedback) {
    feedback_id
  }
}
    `;
/**
 * __useAddFeedbackMutation__
 *
 * To run a mutation, you first call `useAddFeedbackMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddFeedbackMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddFeedbackMutation({
 *   variables: {
 *     avatarImage: // value for 'avatarImage'
 *     userName: // value for 'userName'
 *     userCompanyName: // value for 'userCompanyName'
 *     userCompanyPosition: // value for 'userCompanyPosition'
 *     userRole: // value for 'userRole'
 *     feedback: // value for 'feedback'
 *   },
 * });
 */
export function useAddFeedbackMutation(options = {}) {
    return VueApolloComposable.useMutation(AddFeedbackDocument, options);
}
export const EditFeedbackDocument = gql `
    mutation editFeedback($feedbackId: Int!, $avatarImage: String, $userName: String, $userCompanyName: String, $userCompanyPosition: String, $userRole: UserRoleState, $feedback: String, $isAvatarChanged: Boolean!) {
  edit_feedback(feedback_id: $feedbackId, avatar_image: $avatarImage, user_name: $userName, user_company_name: $userCompanyName, user_company_position: $userCompanyPosition, user_role: $userRole, feedback: $feedback, is_avatar_changed: $isAvatarChanged) {
    feedback_id
  }
}
    `;
/**
 * __useEditFeedbackMutation__
 *
 * To run a mutation, you first call `useEditFeedbackMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useEditFeedbackMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useEditFeedbackMutation({
 *   variables: {
 *     feedbackId: // value for 'feedbackId'
 *     avatarImage: // value for 'avatarImage'
 *     userName: // value for 'userName'
 *     userCompanyName: // value for 'userCompanyName'
 *     userCompanyPosition: // value for 'userCompanyPosition'
 *     userRole: // value for 'userRole'
 *     feedback: // value for 'feedback'
 *     isAvatarChanged: // value for 'isAvatarChanged'
 *   },
 * });
 */
export function useEditFeedbackMutation(options = {}) {
    return VueApolloComposable.useMutation(EditFeedbackDocument, options);
}
export const EditTopSpacesDocument = gql `
    mutation editTopSpaces($input: [UpdatedSpaceTopInput!]!) {
  edit_top_spaces(input: $input) {
    space_id
  }
}
    `;
/**
 * __useEditTopSpacesMutation__
 *
 * To run a mutation, you first call `useEditTopSpacesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useEditTopSpacesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useEditTopSpacesMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useEditTopSpacesMutation(options = {}) {
    return VueApolloComposable.useMutation(EditTopSpacesDocument, options);
}
export const GetVendorsDocument = gql `
    query getVendors {
  get_vendors {
    account_id
    first_name
    last_name
    phone
    email
    organisation_name
  }
}
    `;
/**
 * __useGetVendorsQuery__
 *
 * To run a query within a Vue component, call `useGetVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetVendorsQuery();
 */
export function useGetVendorsQuery(options = {}) {
    return VueApolloComposable.useQuery(GetVendorsDocument, {}, options);
}
export function useGetVendorsLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetVendorsDocument, {}, options);
}
export const GetVendorTokensDocument = gql `
    query getVendorTokens($accountId: Int!) {
  get_vendor_tokens(account_id: $accountId) {
    access_token
    refresh_token
  }
}
    `;
/**
 * __useGetVendorTokensQuery__
 *
 * To run a query within a Vue component, call `useGetVendorTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorTokensQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetVendorTokensQuery({
 *   accountId: // value for 'accountId'
 * });
 */
export function useGetVendorTokensQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetVendorTokensDocument, variables, options);
}
export function useGetVendorTokensLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetVendorTokensDocument, variables, options);
}
export const GetCustomersDocument = gql `
    query getCustomers {
  get_customers {
    account_id
    first_name
    last_name
    phone
    email
  }
}
    `;
/**
 * __useGetCustomersQuery__
 *
 * To run a query within a Vue component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCustomersQuery();
 */
export function useGetCustomersQuery(options = {}) {
    return VueApolloComposable.useQuery(GetCustomersDocument, {}, options);
}
export function useGetCustomersLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetCustomersDocument, {}, options);
}
export const GetCustomerTokensDocument = gql `
    query getCustomerTokens($accountId: Int!) {
  get_customer_tokens(account_id: $accountId) {
    access_token
    refresh_token
  }
}
    `;
/**
 * __useGetCustomerTokensQuery__
 *
 * To run a query within a Vue component, call `useGetCustomerTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerTokensQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCustomerTokensQuery({
 *   accountId: // value for 'accountId'
 * });
 */
export function useGetCustomerTokensQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetCustomerTokensDocument, variables, options);
}
export function useGetCustomerTokensLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetCustomerTokensDocument, variables, options);
}
export const GetAllCitiesDocument = gql `
    query getAllCities($entityType: LocationType!, $showAll: Boolean!, $pagination: Pagination!) {
  get_all_locations(entity_type: $entityType, show_all: $showAll, pagination: $pagination) {
    result {
      seo_entity_id
      seo_entity_name
      counter
      seo_entity_slug
      seo_region_name
    }
  }
}
    `;
/**
 * __useGetAllCitiesQuery__
 *
 * To run a query within a Vue component, call `useGetAllCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCitiesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllCitiesQuery({
 *   entityType: // value for 'entityType'
 *   showAll: // value for 'showAll'
 *   pagination: // value for 'pagination'
 * });
 */
export function useGetAllCitiesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetAllCitiesDocument, variables, options);
}
export function useGetAllCitiesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetAllCitiesDocument, variables, options);
}
export const GetAllActiveSpacesDocument = gql `
    query getAllActiveSpaces {
  get_all_active_spaces {
    space_id
    space_name
    internal_name
    is_afterpay_allowed
    space_images {
      image_url
    }
    space_booking_type
    space_url
    space_features {
      feature_id
    }
    space_categories {
      category_id
    }
    venue {
      venue_name
      venue_geo {
        city {
          seo_entity_name
          seo_entity_slug
        }
      }
    }
    half_day_price
    space_size
    min_guests
    max_guests
  }
}
    `;
/**
 * __useGetAllActiveSpacesQuery__
 *
 * To run a query within a Vue component, call `useGetAllActiveSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllActiveSpacesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllActiveSpacesQuery();
 */
export function useGetAllActiveSpacesQuery(options = {}) {
    return VueApolloComposable.useQuery(GetAllActiveSpacesDocument, {}, options);
}
export function useGetAllActiveSpacesLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetAllActiveSpacesDocument, {}, options);
}
export const GetAllPayoutsDocument = gql `
    query getAllPayouts {
  all_payouts {
    booking_id
    venue_name
    space_name
    iban_name
    amount_paid
    payout_amount
    iban_number
    invoice_number
    invoice_date
    payout_status
    payout_id
    payout_date
  }
}
    `;
/**
 * __useGetAllPayoutsQuery__
 *
 * To run a query within a Vue component, call `useGetAllPayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPayoutsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllPayoutsQuery();
 */
export function useGetAllPayoutsQuery(options = {}) {
    return VueApolloComposable.useQuery(GetAllPayoutsDocument, {}, options);
}
export function useGetAllPayoutsLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetAllPayoutsDocument, {}, options);
}
export const LogoutDocument = gql `
    query logout($token: String!) {
  viewerApiKey(apiKey: $token) {
    logout {
      accountId
    }
  }
}
    `;
/**
 * __useLogoutQuery__
 *
 * To run a query within a Vue component, call `useLogoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoutQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLogoutQuery({
 *   token: // value for 'token'
 * });
 */
export function useLogoutQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(LogoutDocument, variables, options);
}
export function useLogoutLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(LogoutDocument, variables, options);
}
export const ProfileDocument = gql `
    query profile($token: String!) {
  viewerApiKey(apiKey: $token) {
    getProfile {
      accountId
      email
      emailVerified
      firstName
      lastName
      locale
      phone
      accountState
      chatToken
      chatUserId
      organisationId
      socialNetworks
      phoneVerified
      isAdmin
      avatarImage
      roleType
      eventPermissions
    }
  }
}
    `;
/**
 * __useProfileQuery__
 *
 * To run a query within a Vue component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProfileQuery({
 *   token: // value for 'token'
 * });
 */
export function useProfileQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(ProfileDocument, variables, options);
}
export function useProfileLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(ProfileDocument, variables, options);
}
export const GetSocialNetworkAuthUrlDocument = gql `
    query getSocialNetworkAuthUrl($socialNetwork: String!, $authFlow: AuthFlow!) {
  get_social_network_authorization_url(social_network: $socialNetwork, auth_flow: $authFlow) {
    authorization_url
  }
}
    `;
/**
 * __useGetSocialNetworkAuthUrlQuery__
 *
 * To run a query within a Vue component, call `useGetSocialNetworkAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialNetworkAuthUrlQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSocialNetworkAuthUrlQuery({
 *   socialNetwork: // value for 'socialNetwork'
 *   authFlow: // value for 'authFlow'
 * });
 */
export function useGetSocialNetworkAuthUrlQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetSocialNetworkAuthUrlDocument, variables, options);
}
export function useGetSocialNetworkAuthUrlLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetSocialNetworkAuthUrlDocument, variables, options);
}
export const UpdateUserStateDocument = gql `
    mutation updateUserState($token: String!, $profile: UpdateAccountProfileInput!) {
  mutationViewerApiKey(apiKey: $token) {
    updateProfile(updateAccountProfileInput: $profile) {
      accountId
      email
      emailVerified
      firstName
      lastName
      locale
      phone
      accountState
      chatToken
      chatUserId
      organisationId
      socialNetworks
      phoneVerified
      isAdmin
      avatarImage
      roleType
    }
  }
}
    `;
/**
 * __useUpdateUserStateMutation__
 *
 * To run a mutation, you first call `useUpdateUserStateMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStateMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserStateMutation({
 *   variables: {
 *     token: // value for 'token'
 *     profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateUserStateMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateUserStateDocument, options);
}
export const CompleteAccountDocument = gql `
    mutation completeAccount($token: String!, $profile: UpdateAccountProfileInput!) {
  mutationViewerApiKey(apiKey: $token) {
    updateProfile(updateAccountProfileInput: $profile) {
      firstName
      lastName
      phone
      phoneVerified
      accountState
      accountId
      roleType
      isAdmin
      avatarImage
    }
  }
}
    `;
/**
 * __useCompleteAccountMutation__
 *
 * To run a mutation, you first call `useCompleteAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCompleteAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCompleteAccountMutation({
 *   variables: {
 *     token: // value for 'token'
 *     profile: // value for 'profile'
 *   },
 * });
 */
export function useCompleteAccountMutation(options = {}) {
    return VueApolloComposable.useMutation(CompleteAccountDocument, options);
}
export const GetAccessByEmailTokenDocument = gql `
    mutation getAccessByEmailToken($email_token: String!) {
  get_access_tokens(email_token: $email_token) {
    account_id
    email
    first_name
    last_name
    locale
    phone
    account_state
    chat_token
    chat_user_id
    phone_verified
    social_networks {
      network
    }
    organisation_id
    access_token
    refresh_token
  }
}
    `;
/**
 * __useGetAccessByEmailTokenMutation__
 *
 * To run a mutation, you first call `useGetAccessByEmailTokenMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useGetAccessByEmailTokenMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useGetAccessByEmailTokenMutation({
 *   variables: {
 *     email_token: // value for 'email_token'
 *   },
 * });
 */
export function useGetAccessByEmailTokenMutation(options = {}) {
    return VueApolloComposable.useMutation(GetAccessByEmailTokenDocument, options);
}
export const UpdateRefreshAndAccessTokensDocument = gql `
    mutation updateRefreshAndAccessTokens($input: RefreshTokenInput!) {
  updateRefreshAndAccessTokens(refreshTokenInput: $input) {
    accessToken
    refreshToken
  }
}
    `;
/**
 * __useUpdateRefreshAndAccessTokensMutation__
 *
 * To run a mutation, you first call `useUpdateRefreshAndAccessTokensMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRefreshAndAccessTokensMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateRefreshAndAccessTokensMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRefreshAndAccessTokensMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateRefreshAndAccessTokensDocument, options);
}
export const DeleteUserAccountDocument = gql `
    mutation deleteUserAccount {
  delete_account {
    account_id
  }
}
    `;
/**
 * __useDeleteUserAccountMutation__
 *
 * To run a mutation, you first call `useDeleteUserAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteUserAccountMutation();
 */
export function useDeleteUserAccountMutation(options = {}) {
    return VueApolloComposable.useMutation(DeleteUserAccountDocument, options);
}
export const GetAllBlogArticlesDocument = gql `
    query getAllBlogArticles($pagination: Pagination!) {
  get_all_blog_articles(pagination: $pagination) {
    articles {
      name
      description
      slug
      raw_content
    }
    total
    per_page
  }
}
    `;
/**
 * __useGetAllBlogArticlesQuery__
 *
 * To run a query within a Vue component, call `useGetAllBlogArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlogArticlesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllBlogArticlesQuery({
 *   pagination: // value for 'pagination'
 * });
 */
export function useGetAllBlogArticlesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetAllBlogArticlesDocument, variables, options);
}
export function useGetAllBlogArticlesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetAllBlogArticlesDocument, variables, options);
}
export const GetBlogArticleDocument = gql `
    query getBlogArticle($articleSlug: String!, $preview: Boolean!) {
  get_blog_article(article_slug: $articleSlug, preview: $preview) {
    name
    description
    slug
    raw_content
  }
}
    `;
/**
 * __useGetBlogArticleQuery__
 *
 * To run a query within a Vue component, call `useGetBlogArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogArticleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBlogArticleQuery({
 *   articleSlug: // value for 'articleSlug'
 *   preview: // value for 'preview'
 * });
 */
export function useGetBlogArticleQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetBlogArticleDocument, variables, options);
}
export function useGetBlogArticleLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetBlogArticleDocument, variables, options);
}
export const UpdateSpacesAvailabilityDocument = gql `
    mutation updateSpacesAvailability($input: SpacesAvailabilityInput!) {
  manage_spaces_availability(input: $input) {
    space_availability_id
    is_opened
    slot_from
    slot_to
    space_id
    slot_description
  }
}
    `;
/**
 * __useUpdateSpacesAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateSpacesAvailabilityMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpacesAvailabilityMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSpacesAvailabilityMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSpacesAvailabilityMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateSpacesAvailabilityDocument, options);
}
export const VenueWorkingTimeDocument = gql `
    query venueWorkingTime($venueId: Int!) {
  specific_venue(venue_id: $venueId) {
    venue_id
    working_time {
      week_day
      open_time
      close_time
    }
    venue_spaces(show_all_spaces: true) {
      space_id
      space_name
      space_reference
      internal_name
      space_images {
        image_url
      }
    }
  }
}
    `;
/**
 * __useVenueWorkingTimeQuery__
 *
 * To run a query within a Vue component, call `useVenueWorkingTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueWorkingTimeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVenueWorkingTimeQuery({
 *   venueId: // value for 'venueId'
 * });
 */
export function useVenueWorkingTimeQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(VenueWorkingTimeDocument, variables, options);
}
export function useVenueWorkingTimeLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(VenueWorkingTimeDocument, variables, options);
}
export const SubmitFreeBookingDocument = gql `
    mutation submitFreeBooking($bookingId: Int!) {
  submit_free_booking(booking_id: $bookingId) {
    booking_id
    booking_status
  }
}
    `;
/**
 * __useSubmitFreeBookingMutation__
 *
 * To run a mutation, you first call `useSubmitFreeBookingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFreeBookingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSubmitFreeBookingMutation({
 *   variables: {
 *     bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useSubmitFreeBookingMutation(options = {}) {
    return VueApolloComposable.useMutation(SubmitFreeBookingDocument, options);
}
export const ApproveBookingDocument = gql `
    mutation approveBooking($id: Int!) {
  booking_approve(booking_id: $id) {
    booking_id
    ts_reservation_end
    booking_status
    ts_reservation_end
    order {
      ts_host_approval_dl
      ts_customer_approval_dl
      ts_order_cancellation_dl
      order_state
    }
    origin {
      booking_id
      ts_reservation_end
      booking_status
      ts_reservation_end
      order {
        ts_host_approval_dl
        ts_customer_approval_dl
        ts_order_cancellation_dl
        order_state
      }
    }
  }
}
    `;
/**
 * __useApproveBookingMutation__
 *
 * To run a mutation, you first call `useApproveBookingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useApproveBookingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useApproveBookingMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useApproveBookingMutation(options = {}) {
    return VueApolloComposable.useMutation(ApproveBookingDocument, options);
}
export const UpdateBookingMeetingDocument = gql `
    mutation updateBookingMeeting($id: Int!, $data: BookingMeetingUpdateInput!) {
  update_booking_meeting(booking_id: $id, input: $data) {
    booking_id
    booking_status
    meeting_name
    meeting_description
    origin {
      booking_id
      booking_status
      meeting_name
      meeting_description
    }
  }
}
    `;
/**
 * __useUpdateBookingMeetingMutation__
 *
 * To run a mutation, you first call `useUpdateBookingMeetingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingMeetingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateBookingMeetingMutation({
 *   variables: {
 *     id: // value for 'id'
 *     data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBookingMeetingMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateBookingMeetingDocument, options);
}
export const RequestPaymentLinkDocument = gql `
    mutation requestPaymentLink($data: PaymentInput!) {
  create_payment_link(input: $data) {
    payment_link
  }
}
    `;
/**
 * __useRequestPaymentLinkMutation__
 *
 * To run a mutation, you first call `useRequestPaymentLinkMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRequestPaymentLinkMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRequestPaymentLinkMutation({
 *   variables: {
 *     data: // value for 'data'
 *   },
 * });
 */
export function useRequestPaymentLinkMutation(options = {}) {
    return VueApolloComposable.useMutation(RequestPaymentLinkDocument, options);
}
export const RequestPayTimeDocument = gql `
    mutation requestPayTime($id: Int!) {
  update_booking(booking_id: $id, input: {extend_reservation: true}) {
    booking_id
    is_reservation_extended
    ts_reservation_end
    order {
      ts_host_approval_dl
      ts_customer_approval_dl
      ts_order_cancellation_dl
    }
    origin {
      booking_id
      is_reservation_extended
      ts_reservation_end
      order {
        ts_host_approval_dl
        ts_customer_approval_dl
        ts_order_cancellation_dl
      }
    }
  }
}
    `;
/**
 * __useRequestPayTimeMutation__
 *
 * To run a mutation, you first call `useRequestPayTimeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRequestPayTimeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRequestPayTimeMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRequestPayTimeMutation(options = {}) {
    return VueApolloComposable.useMutation(RequestPayTimeDocument, options);
}
export const RejectBookingDocument = gql `
    mutation rejectBooking($id: Int!, $reason: String!, $optedOutAsProspect: Boolean) {
  booking_decline_by_vendor(booking_id: $id, cancellation_reason: $reason, opted_out_as_prospect: $optedOutAsProspect) {
    booking_id
    booking_status
    order {
      ts_host_approval_dl
      ts_customer_approval_dl
      ts_order_cancellation_dl
      order_state
    }
    origin {
      booking_id
      booking_status
      order {
        ts_host_approval_dl
        ts_customer_approval_dl
        ts_order_cancellation_dl
        order_state
      }
    }
  }
}
    `;
/**
 * __useRejectBookingMutation__
 *
 * To run a mutation, you first call `useRejectBookingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRejectBookingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRejectBookingMutation({
 *   variables: {
 *     id: // value for 'id'
 *     reason: // value for 'reason'
 *     optedOutAsProspect: // value for 'optedOutAsProspect'
 *   },
 * });
 */
export function useRejectBookingMutation(options = {}) {
    return VueApolloComposable.useMutation(RejectBookingDocument, options);
}
export const CancelBookingDocument = gql `
    mutation cancelBooking($id: Int!, $reason: String!, $onlyUpdates: Boolean) {
  booking_cancelling(booking_id: $id, cancellation_reason: $reason, only_updates: $onlyUpdates) {
    booking_id
    booking_status
    cancellation_reason
    order {
      ts_host_approval_dl
      ts_customer_approval_dl
      ts_order_cancellation_dl
      refunded_total
      total_paid
      order_state
    }
    origin {
      booking_id
      booking_status
      cancellation_reason
      order {
        ts_host_approval_dl
        ts_customer_approval_dl
        ts_order_cancellation_dl
        refunded_total
        total_paid
        order_state
      }
    }
  }
}
    `;
/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCancelBookingMutation({
 *   variables: {
 *     id: // value for 'id'
 *     reason: // value for 'reason'
 *     onlyUpdates: // value for 'onlyUpdates'
 *   },
 * });
 */
export function useCancelBookingMutation(options = {}) {
    return VueApolloComposable.useMutation(CancelBookingDocument, options);
}
export const DeleteDraftBookingDocument = gql `
    mutation deleteDraftBooking($id: Int!) {
  draft_booking_delete(booking_id: $id) {
    booking_id
    origin {
      booking_id
    }
  }
}
    `;
/**
 * __useDeleteDraftBookingMutation__
 *
 * To run a mutation, you first call `useDeleteDraftBookingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftBookingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteDraftBookingMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDraftBookingMutation(options = {}) {
    return VueApolloComposable.useMutation(DeleteDraftBookingDocument, options);
}
export const AddBookingPayerDocument = gql `
    mutation addBookingPayer($input: PayerInput!) {
  create_booking_payer(input: $input) {
    account_id
  }
}
    `;
/**
 * __useAddBookingPayerMutation__
 *
 * To run a mutation, you first call `useAddBookingPayerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddBookingPayerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddBookingPayerMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAddBookingPayerMutation(options = {}) {
    return VueApolloComposable.useMutation(AddBookingPayerDocument, options);
}
export const AuthCalendarDocument = gql `
    mutation authCalendar($input: CalendarAuthCodeInput!) {
  get_calendar_auth_code_link(input: $input) {
    auth_code_link
  }
}
    `;
/**
 * __useAuthCalendarMutation__
 *
 * To run a mutation, you first call `useAuthCalendarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAuthCalendarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAuthCalendarMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useAuthCalendarMutation(options = {}) {
    return VueApolloComposable.useMutation(AuthCalendarDocument, options);
}
export const SyncSpaceCalendarDocument = gql `
    mutation syncSpaceCalendar($calendar_id: String!, $space_id: Int!) {
  sync_space_calendar(calendar_id: $calendar_id, space_id: $space_id) {
    account_id
    space_id
    calendar_deleted
    is_synchronized
    provider_name
    profile_id
    calendar_id
    calendar_name
    ts_last_sync
  }
}
    `;
/**
 * __useSyncSpaceCalendarMutation__
 *
 * To run a mutation, you first call `useSyncSpaceCalendarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSyncSpaceCalendarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSyncSpaceCalendarMutation({
 *   variables: {
 *     calendar_id: // value for 'calendar_id'
 *     space_id: // value for 'space_id'
 *   },
 * });
 */
export function useSyncSpaceCalendarMutation(options = {}) {
    return VueApolloComposable.useMutation(SyncSpaceCalendarDocument, options);
}
export const CreateSpaceCalendarDocument = gql `
    mutation createSpaceCalendar($space_id: Int!, $state_token: String!) {
  create_space_calendar(space_id: $space_id, state_token: $state_token) {
    account_id
    space_id
    calendar_deleted
    is_synchronized
    provider_name
    profile_id
    calendar_id
    calendar_name
    ts_last_sync
  }
}
    `;
/**
 * __useCreateSpaceCalendarMutation__
 *
 * To run a mutation, you first call `useCreateSpaceCalendarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpaceCalendarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSpaceCalendarMutation({
 *   variables: {
 *     space_id: // value for 'space_id'
 *     state_token: // value for 'state_token'
 *   },
 * });
 */
export function useCreateSpaceCalendarMutation(options = {}) {
    return VueApolloComposable.useMutation(CreateSpaceCalendarDocument, options);
}
export const UnlinkSpaceCalendarDocument = gql `
    mutation unlinkSpaceCalendar($space_id: Int!, $has_rollback_selected: Boolean) {
  unlink_calendar(space_id: $space_id, has_rollback_selected: $has_rollback_selected) {
    account_id
    space_id
    calendar_deleted
    is_synchronized
    provider_name
    profile_id
    calendar_id
    calendar_name
    ts_last_sync
  }
}
    `;
/**
 * __useUnlinkSpaceCalendarMutation__
 *
 * To run a mutation, you first call `useUnlinkSpaceCalendarMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkSpaceCalendarMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUnlinkSpaceCalendarMutation({
 *   variables: {
 *     space_id: // value for 'space_id'
 *     has_rollback_selected: // value for 'has_rollback_selected'
 *   },
 * });
 */
export function useUnlinkSpaceCalendarMutation(options = {}) {
    return VueApolloComposable.useMutation(UnlinkSpaceCalendarDocument, options);
}
export const GetCalendarPermissionsDocument = gql `
    mutation getCalendarPermissions($calendar_id: String!, $space_id: Int!) {
  get_extended_calendar_permissions(calendar_id: $calendar_id, space_id: $space_id) {
    auth_code_link
  }
}
    `;
/**
 * __useGetCalendarPermissionsMutation__
 *
 * To run a mutation, you first call `useGetCalendarPermissionsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarPermissionsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useGetCalendarPermissionsMutation({
 *   variables: {
 *     calendar_id: // value for 'calendar_id'
 *     space_id: // value for 'space_id'
 *   },
 * });
 */
export function useGetCalendarPermissionsMutation(options = {}) {
    return VueApolloComposable.useMutation(GetCalendarPermissionsDocument, options);
}
export const AllUserBookingsDocument = gql `
    query allUserBookings {
  get_bookings {
    booking_id
    slot_start
    start_time
    end_time
    tech_slot_start
    slot_end
    tech_slot_end
    booking_type
    booking_status
    day_number
    reservation_time
    ts_reservation_end
    is_afterpay_requested
    ts_created
    order {
      order_status
      order_state
    }
    replacement {
      booking_id
      booking_status
    }
    space {
      space_name
      service_fee_rate
      discount_percentage
      space_reference
      space_id
      space_images {
        image_url
      }
    }
  }
}
    `;
/**
 * __useAllUserBookingsQuery__
 *
 * To run a query within a Vue component, call `useAllUserBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserBookingsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAllUserBookingsQuery();
 */
export function useAllUserBookingsQuery(options = {}) {
    return VueApolloComposable.useQuery(AllUserBookingsDocument, {}, options);
}
export function useAllUserBookingsLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(AllUserBookingsDocument, {}, options);
}
export const GetBookingDocument = gql `
    query getBooking($id: Int!) {
  specific_booking(booking_id: $id) {
    ...SpecificBooking
    replacement {
      booking_id
    }
    origin {
      order {
        ts_host_approval_dl
        ts_customer_approval_dl
        ts_order_cancellation_dl
        ts_payment_expiration_dl
        vendor {
          email
          phone
          first_name
          last_name
        }
      }
      booking_meta {
        coupon {
          discount_percentage
        }
      }
    }
    order {
      ts_host_approval_dl
      ts_customer_approval_dl
      ts_order_cancellation_dl
      ts_payment_expiration_dl
      vendor {
        email
        phone
        first_name
        last_name
      }
    }
    booking_meta {
      coupon {
        discount_percentage
      }
    }
  }
}
    ${SpecificBookingFragmentDoc}`;
/**
 * __useGetBookingQuery__
 *
 * To run a query within a Vue component, call `useGetBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBookingQuery({
 *   id: // value for 'id'
 * });
 */
export function useGetBookingQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetBookingDocument, variables, options);
}
export function useGetBookingLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetBookingDocument, variables, options);
}
export const GetBookingByHashDocument = gql `
    query getBookingByHash($hash: String!) {
  specific_booking_by_hash(booking_hash: $hash) {
    ...SpecificBooking
    order {
      payer {
        account_id
        email
      }
    }
    origin {
      order {
        payer {
          account_id
          email
        }
      }
    }
  }
}
    ${SpecificBookingFragmentDoc}`;
/**
 * __useGetBookingByHashQuery__
 *
 * To run a query within a Vue component, call `useGetBookingByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingByHashQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetBookingByHashQuery({
 *   hash: // value for 'hash'
 * });
 */
export function useGetBookingByHashQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetBookingByHashDocument, variables, options);
}
export function useGetBookingByHashLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetBookingByHashDocument, variables, options);
}
export const IsRefundDocument = gql `
    query isRefund($booking_id: Int!) {
  check_refund(booking_id: $booking_id) {
    is_available
    refund_total
    refund_currency
  }
}
    `;
/**
 * __useIsRefundQuery__
 *
 * To run a query within a Vue component, call `useIsRefundQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsRefundQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useIsRefundQuery({
 *   booking_id: // value for 'booking_id'
 * });
 */
export function useIsRefundQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(IsRefundDocument, variables, options);
}
export function useIsRefundLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(IsRefundDocument, variables, options);
}
export const PaymentBookingSubscribeDocument = gql `
    subscription paymentBookingSubscribe($bookingId: Int!, $accountId: Int!) {
  wait_for_booking_status(booking_id: $bookingId, account_id: $accountId) {
    booking_id
    booking_status
    order {
      order_state
    }
    origin {
      booking_id
      booking_status
      order {
        order_state
      }
    }
  }
}
    `;
/**
 * __usePaymentBookingSubscribeSubscription__
 *
 * To run a query within a Vue component, call `usePaymentBookingSubscribeSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePaymentBookingSubscribeSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = usePaymentBookingSubscribeSubscription({
 *   bookingId: // value for 'bookingId'
 *   accountId: // value for 'accountId'
 * });
 */
export function usePaymentBookingSubscribeSubscription(variables, options = {}) {
    return VueApolloComposable.useSubscription(PaymentBookingSubscribeDocument, variables, options);
}
export const GetVenuesDocument = gql `
    query getVenues($token: String!) {
  viewerApiKey(apiKey: $token) {
    venuesGet {
      venueId
      venueName
      workingTime {
        closeTime
        openTime
        weekDay
      }
    }
  }
}
    `;
/**
 * __useGetVenuesQuery__
 *
 * To run a query within a Vue component, call `useGetVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenuesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetVenuesQuery({
 *   token: // value for 'token'
 * });
 */
export function useGetVenuesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetVenuesDocument, variables, options);
}
export function useGetVenuesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetVenuesDocument, variables, options);
}
export const VenueDocument = gql `
    query venue($venueId: Int!) {
  specific_venue(venue_id: $venueId) {
    venue_id
    working_time {
      close_time
      open_time
      week_day
    }
  }
}
    `;
/**
 * __useVenueQuery__
 *
 * To run a query within a Vue component, call `useVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVenueQuery({
 *   venueId: // value for 'venueId'
 * });
 */
export function useVenueQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(VenueDocument, variables, options);
}
export function useVenueLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(VenueDocument, variables, options);
}
export const GetSpacesByVenueIdDocument = gql `
    query getSpacesByVenueId($token: String!, $venueId: Int!) {
  viewerApiKey(apiKey: $token) {
    getSpacesByVenueId(venueId: $venueId) {
      spaceId
      spaceReference
      internalName
      spaceName
      spaceImages {
        imageId
        imageUrl
      }
    }
  }
}
    `;
/**
 * __useGetSpacesByVenueIdQuery__
 *
 * To run a query within a Vue component, call `useGetSpacesByVenueIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesByVenueIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSpacesByVenueIdQuery({
 *   token: // value for 'token'
 *   venueId: // value for 'venueId'
 * });
 */
export function useGetSpacesByVenueIdQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetSpacesByVenueIdDocument, variables, options);
}
export function useGetSpacesByVenueIdLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetSpacesByVenueIdDocument, variables, options);
}
export const GetVenueAndSpacesByVenueIdDocument = gql `
    query getVenueAndSpacesByVenueId($venueId: Int!, $show_all_spaces: Boolean) {
  specific_venue(venue_id: $venueId) {
    venue_name
    venue_geo {
      address
    }
    venue_images {
      order
      image_url
    }
    venue_spaces(show_all_spaces: $show_all_spaces) {
      space_id
      space_name
      internal_name
      space_images {
        order
        image_url
      }
    }
  }
}
    `;
/**
 * __useGetVenueAndSpacesByVenueIdQuery__
 *
 * To run a query within a Vue component, call `useGetVenueAndSpacesByVenueIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueAndSpacesByVenueIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetVenueAndSpacesByVenueIdQuery({
 *   venueId: // value for 'venueId'
 *   show_all_spaces: // value for 'show_all_spaces'
 * });
 */
export function useGetVenueAndSpacesByVenueIdQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetVenueAndSpacesByVenueIdDocument, variables, options);
}
export function useGetVenueAndSpacesByVenueIdLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetVenueAndSpacesByVenueIdDocument, variables, options);
}
export const BookingFinanceDocument = gql `
    query bookingFinance($bookingId: Int!) {
  get_booking_invoices_history(booking_id: $bookingId) {
    invoice_id
    invoice_type
    payout_status
    order {
      total_paid
      service_fee
      service_fee_total
      order_total
      refunded_total
      order_subtotal
      vendor {
        first_name
        last_name
      }
    }
  }
}
    `;
/**
 * __useBookingFinanceQuery__
 *
 * To run a query within a Vue component, call `useBookingFinanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingFinanceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBookingFinanceQuery({
 *   bookingId: // value for 'bookingId'
 * });
 */
export function useBookingFinanceQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(BookingFinanceDocument, variables, options);
}
export function useBookingFinanceLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(BookingFinanceDocument, variables, options);
}
export const GetSpacesCalendarDataDocument = gql `
    query getSpacesCalendarData($venueId: Int, $spaceIds: [Int!], $week: Int, $months: [Int!], $year: Int!, $bookingEditMode: BookingEditModeInput) {
  get_spaces_calendar_data(space_ids: $spaceIds, week: $week, months: $months, year: $year, venue_id: $venueId, booking_edit_mode: $bookingEditMode) {
    day
    is_in_current_week
    is_in_another_month
    is_today
    is_day_partially_closed
    is_day_fully_closed
    space_bookings {
      space_id
      space_name
      space_main_image
      booking_limit
      booking_start
      booking_end
      booking_status
      closed_labels
      order_state
      booking_id
      slot_start
      slot_end
    }
    space_availability {
      space_id
      slot_from
      slot_to
      slot_name
      slot_description
      closed_labels
      is_opened
    }
    is_venue_working_day
  }
}
    `;
/**
 * __useGetSpacesCalendarDataQuery__
 *
 * To run a query within a Vue component, call `useGetSpacesCalendarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesCalendarDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSpacesCalendarDataQuery({
 *   venueId: // value for 'venueId'
 *   spaceIds: // value for 'spaceIds'
 *   week: // value for 'week'
 *   months: // value for 'months'
 *   year: // value for 'year'
 *   bookingEditMode: // value for 'bookingEditMode'
 * });
 */
export function useGetSpacesCalendarDataQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetSpacesCalendarDataDocument, variables, options);
}
export function useGetSpacesCalendarDataLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetSpacesCalendarDataDocument, variables, options);
}
export const GetSpacesCalendarSyncDocument = gql `
    query getSpacesCalendarSync($venueId: Int!, $show_all_spaces: Boolean) {
  specific_venue(venue_id: $venueId) {
    venue_id
    venue_spaces(show_all_spaces: $show_all_spaces) {
      space_id
      space_name
      internal_name
      space_images {
        image_url
      }
      space_calendars {
        account_id
        calendar_id
        calendar_name
        calendar_deleted
        is_synchronized
        profile_id
        profile_name
        provider_name
        space_id
        ts_last_sync
      }
    }
  }
}
    `;
/**
 * __useGetSpacesCalendarSyncQuery__
 *
 * To run a query within a Vue component, call `useGetSpacesCalendarSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesCalendarSyncQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSpacesCalendarSyncQuery({
 *   venueId: // value for 'venueId'
 *   show_all_spaces: // value for 'show_all_spaces'
 * });
 */
export function useGetSpacesCalendarSyncQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetSpacesCalendarSyncDocument, variables, options);
}
export function useGetSpacesCalendarSyncLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetSpacesCalendarSyncDocument, variables, options);
}
export const GetExternalCalendarsDocument = gql `
    query getExternalCalendars($auth_code: String, $provider_name: CalendarProviderType!, $space_id: Int!) {
  get_external_calendars_by_provider_name(auth_code: $auth_code, provider_name: $provider_name, space_id: $space_id) {
    account_id
    space_id
    calendar_deleted
    is_synchronized
    provider_name
    profile_id
    profile_name
    calendar_id
    calendar_name
    ts_last_sync
  }
}
    `;
/**
 * __useGetExternalCalendarsQuery__
 *
 * To run a query within a Vue component, call `useGetExternalCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalCalendarsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetExternalCalendarsQuery({
 *   auth_code: // value for 'auth_code'
 *   provider_name: // value for 'provider_name'
 *   space_id: // value for 'space_id'
 * });
 */
export function useGetExternalCalendarsQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetExternalCalendarsDocument, variables, options);
}
export function useGetExternalCalendarsLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetExternalCalendarsDocument, variables, options);
}
export const BookingChatDocument = gql `
    query bookingChat($id: Int!) {
  get_chat(booking_id: $id) {
    channel_id
    chat_id
    chat_type
    entity_id
  }
}
    `;
/**
 * __useBookingChatQuery__
 *
 * To run a query within a Vue component, call `useBookingChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingChatQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBookingChatQuery({
 *   id: // value for 'id'
 * });
 */
export function useBookingChatQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(BookingChatDocument, variables, options);
}
export function useBookingChatLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(BookingChatDocument, variables, options);
}
export const SpaceChatDocument = gql `
    query spaceChat($id: Int!, $chatEventName: ChatMessageType) {
  get_chat(space_id: $id, event_name: $chatEventName) {
    channel_id
    chat_id
    chat_type
    entity_id
  }
}
    `;
/**
 * __useSpaceChatQuery__
 *
 * To run a query within a Vue component, call `useSpaceChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceChatQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSpaceChatQuery({
 *   id: // value for 'id'
 *   chatEventName: // value for 'chatEventName'
 * });
 */
export function useSpaceChatQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(SpaceChatDocument, variables, options);
}
export function useSpaceChatLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(SpaceChatDocument, variables, options);
}
export const VenueChatDocument = gql `
    query venueChat($id: Int!) {
  get_chat(venue_id: $id) {
    channel_id
    chat_id
    chat_type
    entity_id
  }
}
    `;
/**
 * __useVenueChatQuery__
 *
 * To run a query within a Vue component, call `useVenueChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueChatQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVenueChatQuery({
 *   id: // value for 'id'
 * });
 */
export function useVenueChatQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(VenueChatDocument, variables, options);
}
export function useVenueChatLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(VenueChatDocument, variables, options);
}
export const SupportChatDocument = gql `
    query supportChat {
  get_chat {
    channel_id
    chat_id
    chat_type
    entity_id
  }
}
    `;
/**
 * __useSupportChatQuery__
 *
 * To run a query within a Vue component, call `useSupportChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportChatQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSupportChatQuery();
 */
export function useSupportChatQuery(options = {}) {
    return VueApolloComposable.useQuery(SupportChatDocument, {}, options);
}
export function useSupportChatLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(SupportChatDocument, {}, options);
}
export const ChatSpaceDocument = gql `
    query chatSpace($id: Int!) {
  specific_space(space_id: $id) {
    space_id
    internal_name
    space_url
    space_images {
      image_url
    }
  }
}
    `;
/**
 * __useChatSpaceQuery__
 *
 * To run a query within a Vue component, call `useChatSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatSpaceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useChatSpaceQuery({
 *   id: // value for 'id'
 * });
 */
export function useChatSpaceQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(ChatSpaceDocument, variables, options);
}
export function useChatSpaceLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(ChatSpaceDocument, variables, options);
}
export const ChatVenueDocument = gql `
    query chatVenue($id: Int!) {
  specific_venue(venue_id: $id) {
    venue_id
    venue_name
    venue_url
    venue_images {
      image_url
    }
  }
}
    `;
/**
 * __useChatVenueQuery__
 *
 * To run a query within a Vue component, call `useChatVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatVenueQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useChatVenueQuery({
 *   id: // value for 'id'
 * });
 */
export function useChatVenueQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(ChatVenueDocument, variables, options);
}
export function useChatVenueLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(ChatVenueDocument, variables, options);
}
export const ApplyCouponDocument = gql `
    mutation applyCoupon($code: String!, $spaceId: Int!) {
  apply_coupon(code: $code, space_id: $spaceId) {
    coupon_id
    coupon_code
    starts_from
    expires_at
    discount_percentage
    usage_limit
    times_applied
    times_used
    is_active
    ts_created
  }
}
    `;
/**
 * __useApplyCouponMutation__
 *
 * To run a mutation, you first call `useApplyCouponMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useApplyCouponMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useApplyCouponMutation({
 *   variables: {
 *     code: // value for 'code'
 *     spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useApplyCouponMutation(options = {}) {
    return VueApolloComposable.useMutation(ApplyCouponDocument, options);
}
export const RemoveCouponDocument = gql `
    mutation removeCoupon($couponId: Int!) {
  remove_coupon(coupon_id: $couponId) {
    coupon_id
  }
}
    `;
/**
 * __useRemoveCouponMutation__
 *
 * To run a mutation, you first call `useRemoveCouponMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCouponMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCouponMutation({
 *   variables: {
 *     couponId: // value for 'couponId'
 *   },
 * });
 */
export function useRemoveCouponMutation(options = {}) {
    return VueApolloComposable.useMutation(RemoveCouponDocument, options);
}
export const AddCouponDocument = gql `
    mutation addCoupon($coupon: CouponCreationInput!) {
  add_coupon(input: $coupon) {
    coupon_id
  }
}
    `;
/**
 * __useAddCouponMutation__
 *
 * To run a mutation, you first call `useAddCouponMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddCouponMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddCouponMutation({
 *   variables: {
 *     coupon: // value for 'coupon'
 *   },
 * });
 */
export function useAddCouponMutation(options = {}) {
    return VueApolloComposable.useMutation(AddCouponDocument, options);
}
export const EditCouponDocument = gql `
    mutation editCoupon($coupon: CouponEditingInput!) {
  edit_coupon(input: $coupon) {
    coupon_code
  }
}
    `;
/**
 * __useEditCouponMutation__
 *
 * To run a mutation, you first call `useEditCouponMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useEditCouponMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useEditCouponMutation({
 *   variables: {
 *     coupon: // value for 'coupon'
 *   },
 * });
 */
export function useEditCouponMutation(options = {}) {
    return VueApolloComposable.useMutation(EditCouponDocument, options);
}
export const DeactivateCouponDocument = gql `
    mutation deactivateCoupon($couponId: Int!) {
  deactivate_coupon(coupon_id: $couponId) {
    is_active
  }
}
    `;
/**
 * __useDeactivateCouponMutation__
 *
 * To run a mutation, you first call `useDeactivateCouponMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCouponMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeactivateCouponMutation({
 *   variables: {
 *     couponId: // value for 'couponId'
 *   },
 * });
 */
export function useDeactivateCouponMutation(options = {}) {
    return VueApolloComposable.useMutation(DeactivateCouponDocument, options);
}
export const GetAllVenueCouponsDocument = gql `
    query getAllVenueCoupons($venueId: Int!) {
  get_all_venue_coupons(venue_id: $venueId) {
    space {
      space_name
      space_id
      internal_name
    }
    coupon_id
    coupon_code
    coupon_description
    starts_from
    expires_at
    discount_percentage
    usage_limit
    times_applied
    times_used
    is_active
    is_multi_use
    ts_created
  }
}
    `;
/**
 * __useGetAllVenueCouponsQuery__
 *
 * To run a query within a Vue component, call `useGetAllVenueCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllVenueCouponsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllVenueCouponsQuery({
 *   venueId: // value for 'venueId'
 * });
 */
export function useGetAllVenueCouponsQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetAllVenueCouponsDocument, variables, options);
}
export function useGetAllVenueCouponsLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetAllVenueCouponsDocument, variables, options);
}
export const AddFavoriteSpaceDocument = gql `
    mutation addFavoriteSpace($spaceId: Int!) {
  favor_space(space_id: $spaceId) {
    space {
      space_id
    }
  }
}
    `;
/**
 * __useAddFavoriteSpaceMutation__
 *
 * To run a mutation, you first call `useAddFavoriteSpaceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteSpaceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddFavoriteSpaceMutation({
 *   variables: {
 *     spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useAddFavoriteSpaceMutation(options = {}) {
    return VueApolloComposable.useMutation(AddFavoriteSpaceDocument, options);
}
export const RemoveFavoriteSpaceDocument = gql `
    mutation removeFavoriteSpace($spaceId: Int!) {
  remove_space_from_favorites(space_id: $spaceId) {
    result
  }
}
    `;
/**
 * __useRemoveFavoriteSpaceMutation__
 *
 * To run a mutation, you first call `useRemoveFavoriteSpaceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFavoriteSpaceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveFavoriteSpaceMutation({
 *   variables: {
 *     spaceId: // value for 'spaceId'
 *   },
 * });
 */
export function useRemoveFavoriteSpaceMutation(options = {}) {
    return VueApolloComposable.useMutation(RemoveFavoriteSpaceDocument, options);
}
export const GetFavoriteSpacesDocument = gql `
    query getFavoriteSpaces {
  customer_favorite_spaces {
    space_id
    space_name
    internal_name
    is_afterpay_allowed
    space_images {
      image_url
    }
    space_booking_type
    space_url
    space_features {
      feature_id
    }
    space_categories {
      category_id
    }
    venue {
      venue_name
      venue_geo {
        city {
          seo_entity_name
          seo_entity_slug
        }
      }
    }
    half_day_price
    space_size
    min_guests
    max_guests
  }
}
    `;
/**
 * __useGetFavoriteSpacesQuery__
 *
 * To run a query within a Vue component, call `useGetFavoriteSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteSpacesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetFavoriteSpacesQuery();
 */
export function useGetFavoriteSpacesQuery(options = {}) {
    return VueApolloComposable.useQuery(GetFavoriteSpacesDocument, {}, options);
}
export function useGetFavoriteSpacesLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetFavoriteSpacesDocument, {}, options);
}
export const ValidateOrganizationDocument = gql `
    mutation validateOrganization($bank_ascription: String!, $iban: String!) {
  specific_organisation_bank_info(bank_ascription: $bank_ascription, iban: $iban) {
    organisation_bank_id
    organisation_id
    is_active
    bank_ascription
    iban
  }
}
    `;
/**
 * __useValidateOrganizationMutation__
 *
 * To run a mutation, you first call `useValidateOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useValidateOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useValidateOrganizationMutation({
 *   variables: {
 *     bank_ascription: // value for 'bank_ascription'
 *     iban: // value for 'iban'
 *   },
 * });
 */
export function useValidateOrganizationMutation(options = {}) {
    return VueApolloComposable.useMutation(ValidateOrganizationDocument, options);
}
export const ResendInvoiceDocument = gql `
    mutation resendInvoice($invoice_id: String!) {
  resend_invoice(invoice_id: $invoice_id) {
    result
  }
}
    `;
/**
 * __useResendInvoiceMutation__
 *
 * To run a mutation, you first call `useResendInvoiceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResendInvoiceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResendInvoiceMutation({
 *   variables: {
 *     invoice_id: // value for 'invoice_id'
 *   },
 * });
 */
export function useResendInvoiceMutation(options = {}) {
    return VueApolloComposable.useMutation(ResendInvoiceDocument, options);
}
export const OrganizationBankDocument = gql `
    query organizationBank {
  fetch_iban_details {
    organisation_bank_id
    organisation_id
    bank_ascription
    is_active
    iban
  }
}
    `;
/**
 * __useOrganizationBankQuery__
 *
 * To run a query within a Vue component, call `useOrganizationBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationBankQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationBankQuery();
 */
export function useOrganizationBankQuery(options = {}) {
    return VueApolloComposable.useQuery(OrganizationBankDocument, {}, options);
}
export function useOrganizationBankLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(OrganizationBankDocument, {}, options);
}
export const OrganizationDocument = gql `
    query organization($token: String!) {
  viewerApiKey(apiKey: $token) {
    getApiOrganisation {
      organisationName
      organisationId
    }
  }
}
    `;
/**
 * __useOrganizationQuery__
 *
 * To run a query within a Vue component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationQuery({
 *   token: // value for 'token'
 * });
 */
export function useOrganizationQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(OrganizationDocument, variables, options);
}
export function useOrganizationLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(OrganizationDocument, variables, options);
}
export const FinanceHistoryDocument = gql `
    query financeHistory {
  all_finance_history {
    payout_amount
    invoice_id
    payout_status
    booking_id
    ts_created
    invoice_type
  }
}
    `;
/**
 * __useFinanceHistoryQuery__
 *
 * To run a query within a Vue component, call `useFinanceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceHistoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFinanceHistoryQuery();
 */
export function useFinanceHistoryQuery(options = {}) {
    return VueApolloComposable.useQuery(FinanceHistoryDocument, {}, options);
}
export function useFinanceHistoryLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(FinanceHistoryDocument, {}, options);
}
export const GetEntitiesDocument = gql `
    query getEntities($entity_type: LocationType!, $pagination: Pagination, $location: LocationInput) {
  get_all_locations(entity_type: $entity_type, pagination: $pagination, location: $location) {
    result {
      seo_entity_id
      seo_entity_name
      seo_entity_type
      seo_entity_slug
      counter
      venues {
        result {
          venue_images {
            order
            image_url
          }
        }
      }
    }
    page
    size
    total_pages
  }
}
    `;
/**
 * __useGetEntitiesQuery__
 *
 * To run a query within a Vue component, call `useGetEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitiesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetEntitiesQuery({
 *   entity_type: // value for 'entity_type'
 *   pagination: // value for 'pagination'
 *   location: // value for 'location'
 * });
 */
export function useGetEntitiesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetEntitiesDocument, variables, options);
}
export function useGetEntitiesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetEntitiesDocument, variables, options);
}
export const GetRegionDocument = gql `
    query getRegion($slug: String!, $pagination: Pagination) {
  get_specific_location(slug: $slug, pagination: $pagination) {
    seo_entity_id
    seo_entity_name
    seo_entity_slug
    longitude
    latitude
  }
}
    `;
/**
 * __useGetRegionQuery__
 *
 * To run a query within a Vue component, call `useGetRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRegionQuery({
 *   slug: // value for 'slug'
 *   pagination: // value for 'pagination'
 * });
 */
export function useGetRegionQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetRegionDocument, variables, options);
}
export function useGetRegionLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetRegionDocument, variables, options);
}
export const GetRegionCitiesDocument = gql `
    query getRegionCities($slug: String!, $pagination: Pagination) {
  get_specific_location(slug: $slug, pagination: $pagination) {
    cities {
      result {
        seo_entity_id
        seo_entity_name
        seo_entity_type
        seo_entity_slug
        counter
        venues {
          result {
            venue_id
            venue_name
            venue_geo {
              latitude
              longitude
            }
            venue_images {
              order
              image_url
            }
          }
        }
      }
      page
      size
      total_pages
    }
  }
}
    `;
/**
 * __useGetRegionCitiesQuery__
 *
 * To run a query within a Vue component, call `useGetRegionCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionCitiesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetRegionCitiesQuery({
 *   slug: // value for 'slug'
 *   pagination: // value for 'pagination'
 * });
 */
export function useGetRegionCitiesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetRegionCitiesDocument, variables, options);
}
export function useGetRegionCitiesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetRegionCitiesDocument, variables, options);
}
export const GetCityDocument = gql `
    query getCity($slug: String!, $pagination: Pagination) {
  get_specific_location(slug: $slug, pagination: $pagination) {
    seo_entity_id
    seo_entity_name
    seo_entity_slug
    seo_region_name
    longitude
    latitude
    video_url
    seo {
      h1
      h2
      description
      language
      meta_title
      meta_description
    }
    faqs {
      question
      answer
      language
    }
    seo_extra {
      title
      description
      language
    }
  }
}
    `;
/**
 * __useGetCityQuery__
 *
 * To run a query within a Vue component, call `useGetCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCityQuery({
 *   slug: // value for 'slug'
 *   pagination: // value for 'pagination'
 * });
 */
export function useGetCityQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetCityDocument, variables, options);
}
export function useGetCityLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetCityDocument, variables, options);
}
export const GetCityVenuesDocument = gql `
    query getCityVenues($slug: String!, $pagination: Pagination, $tag: String) {
  get_specific_location(slug: $slug, pagination: $pagination) {
    venues(tag: $tag) {
      result {
        venue_id
        venue_name
        venue_url
        organisation_rating {
          score
        }
        venue_geo {
          latitude
          longitude
        }
        venue_images {
          order
          image_url
        }
        venue_spaces {
          space_id
          space_name
          internal_name
          is_afterpay_allowed
          space_images {
            image_url
          }
          space_url
          space_features {
            feature_id
          }
          space_categories {
            category_id
          }
          space_booking_type
          half_day_price
          space_size
          min_guests
          max_guests
          is_highlighted
          is_new
          is_favorite
        }
        collection {
          logo {
            image_url
          }
          slug
          has_public_page
        }
        min_price
      }
      page
      size
      total_pages
    }
  }
}
    `;
/**
 * __useGetCityVenuesQuery__
 *
 * To run a query within a Vue component, call `useGetCityVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityVenuesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCityVenuesQuery({
 *   slug: // value for 'slug'
 *   pagination: // value for 'pagination'
 *   tag: // value for 'tag'
 * });
 */
export function useGetCityVenuesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetCityVenuesDocument, variables, options);
}
export function useGetCityVenuesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetCityVenuesDocument, variables, options);
}
export const GetAllFaqCategoriesDocument = gql `
    query getAllFAQCategories {
  get_all_faq_categories {
    faq_category_name_nl
    faq_category_name_en
    faq_category_slug_en
    faq_category_slug_nl
  }
}
    `;
/**
 * __useGetAllFaqCategoriesQuery__
 *
 * To run a query within a Vue component, call `useGetAllFaqCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFaqCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllFaqCategoriesQuery();
 */
export function useGetAllFaqCategoriesQuery(options = {}) {
    return VueApolloComposable.useQuery(GetAllFaqCategoriesDocument, {}, options);
}
export function useGetAllFaqCategoriesLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetAllFaqCategoriesDocument, {}, options);
}
export const GetAllFaqCategoryStoriesDocument = gql `
    query getAllFAQCategoryStories($slug: String!, $pagination: Pagination!) {
  get_all_faq_category_stories(category_slug: $slug, pagination: $pagination) {
    stories {
      name
      truncated_content
      description
      slug_nl
      slug_en
    }
    total
    per_page
  }
}
    `;
/**
 * __useGetAllFaqCategoryStoriesQuery__
 *
 * To run a query within a Vue component, call `useGetAllFaqCategoryStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFaqCategoryStoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllFaqCategoryStoriesQuery({
 *   slug: // value for 'slug'
 *   pagination: // value for 'pagination'
 * });
 */
export function useGetAllFaqCategoryStoriesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetAllFaqCategoryStoriesDocument, variables, options);
}
export function useGetAllFaqCategoryStoriesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetAllFaqCategoryStoriesDocument, variables, options);
}
export const GetFaqStoryDocument = gql `
    query getFAQStory($slug: String!, $preview: Boolean!) {
  get_faq_story(story_slug: $slug, preview: $preview) {
    name
    description
    slug_nl
    slug_en
    raw_content
  }
}
    `;
/**
 * __useGetFaqStoryQuery__
 *
 * To run a query within a Vue component, call `useGetFaqStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFaqStoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetFaqStoryQuery({
 *   slug: // value for 'slug'
 *   preview: // value for 'preview'
 * });
 */
export function useGetFaqStoryQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetFaqStoryDocument, variables, options);
}
export function useGetFaqStoryLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetFaqStoryDocument, variables, options);
}
export const FindFaqStoriesDocument = gql `
    query findFAQStories($searchTerm: String!) {
  search_faq_stories(search_term: $searchTerm) {
    stories {
      name
      slug_nl
      slug_en
    }
  }
}
    `;
/**
 * __useFindFaqStoriesQuery__
 *
 * To run a query within a Vue component, call `useFindFaqStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFaqStoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFindFaqStoriesQuery({
 *   searchTerm: // value for 'searchTerm'
 * });
 */
export function useFindFaqStoriesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(FindFaqStoriesDocument, variables, options);
}
export function useFindFaqStoriesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(FindFaqStoriesDocument, variables, options);
}
export const GetSpacesBySlugDocument = gql `
    query getSpacesBySlug($slugs: [String!]!) {
  get_spaces_by_slug(space_slugs: $slugs) {
    space_id
    space_name
    internal_name
    space_images {
      image_url
    }
    vat_rate
    half_day_price
    space_url
    space_booking_type
    is_favorite
  }
}
    `;
/**
 * __useGetSpacesBySlugQuery__
 *
 * To run a query within a Vue component, call `useGetSpacesBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesBySlugQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSpacesBySlugQuery({
 *   slugs: // value for 'slugs'
 * });
 */
export function useGetSpacesBySlugQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetSpacesBySlugDocument, variables, options);
}
export function useGetSpacesBySlugLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetSpacesBySlugDocument, variables, options);
}
export const GetAllFeedbacksDocument = gql `
    query getAllFeedbacks {
  get_all_feedbacks {
    feedback_id
    avatar_url
    user_name
    user_company_name
    user_company_position
    user_role
    feedback
  }
}
    `;
/**
 * __useGetAllFeedbacksQuery__
 *
 * To run a query within a Vue component, call `useGetAllFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFeedbacksQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllFeedbacksQuery();
 */
export function useGetAllFeedbacksQuery(options = {}) {
    return VueApolloComposable.useQuery(GetAllFeedbacksDocument, {}, options);
}
export function useGetAllFeedbacksLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetAllFeedbacksDocument, {}, options);
}
export const GetTopSpacesDocument = gql `
    query getTopSpaces {
  get_top_spaces {
    space_id
    space_name
    internal_name
    is_afterpay_allowed
    space_images {
      image_url
    }
    space_booking_type
    space_url
    space_features {
      feature_id
    }
    space_categories {
      category_id
    }
    venue {
      venue_name
      venue_geo {
        city {
          seo_entity_name
          seo_entity_slug
        }
      }
    }
    half_day_price
    space_size
    min_guests
    max_guests
    is_highlighted
    organisation_rating {
      score
    }
    is_new
    is_favorite
  }
}
    `;
/**
 * __useGetTopSpacesQuery__
 *
 * To run a query within a Vue component, call `useGetTopSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopSpacesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetTopSpacesQuery();
 */
export function useGetTopSpacesQuery(options = {}) {
    return VueApolloComposable.useQuery(GetTopSpacesDocument, {}, options);
}
export function useGetTopSpacesLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetTopSpacesDocument, {}, options);
}
export const GetAllHowToArticlesDocument = gql `
    query getAllHowToArticles($pagination: Pagination!) {
  get_all_how_to_articles(pagination: $pagination) {
    articles {
      name
      description
      slug
      raw_content
    }
    total
    per_page
  }
}
    `;
/**
 * __useGetAllHowToArticlesQuery__
 *
 * To run a query within a Vue component, call `useGetAllHowToArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllHowToArticlesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllHowToArticlesQuery({
 *   pagination: // value for 'pagination'
 * });
 */
export function useGetAllHowToArticlesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetAllHowToArticlesDocument, variables, options);
}
export function useGetAllHowToArticlesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetAllHowToArticlesDocument, variables, options);
}
export const GetHowToArticleDocument = gql `
    query getHowToArticle($articleSlug: String!, $preview: Boolean!) {
  get_how_to_article(article_slug: $articleSlug, preview: $preview) {
    name
    description
    slug
    raw_content
  }
}
    `;
/**
 * __useGetHowToArticleQuery__
 *
 * To run a query within a Vue component, call `useGetHowToArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHowToArticleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetHowToArticleQuery({
 *   articleSlug: // value for 'articleSlug'
 *   preview: // value for 'preview'
 * });
 */
export function useGetHowToArticleQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetHowToArticleDocument, variables, options);
}
export function useGetHowToArticleLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetHowToArticleDocument, variables, options);
}
export const VendorNotificationsDocument = gql `
    query vendorNotifications($venueId: Int) {
  get_notifications(venue_id: $venueId) {
    ...Notification
    day_overview_bookings {
      booking_id
      attendees
      slot_end
      slot_start
      order {
        order_items {
          order_item_id
          order_item_type
          order_item_name
          order_item_description
          service_name
          service_type
          reference_id
          quantity
        }
      }
      space {
        space_configurations {
          configuration_id
          configuration_type
        }
        internal_name
        space_description
        space_images {
          image_url
        }
      }
    }
  }
}
    ${NotificationFragmentDoc}`;
/**
 * __useVendorNotificationsQuery__
 *
 * To run a query within a Vue component, call `useVendorNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorNotificationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVendorNotificationsQuery({
 *   venueId: // value for 'venueId'
 * });
 */
export function useVendorNotificationsQuery(variables = {}, options = {}) {
    return VueApolloComposable.useQuery(VendorNotificationsDocument, variables, options);
}
export function useVendorNotificationsLazyQuery(variables = {}, options = {}) {
    return VueApolloComposable.useLazyQuery(VendorNotificationsDocument, variables, options);
}
export const CustomerNotificationsDocument = gql `
    query customerNotifications {
  get_notifications {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;
/**
 * __useCustomerNotificationsQuery__
 *
 * To run a query within a Vue component, call `useCustomerNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerNotificationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCustomerNotificationsQuery();
 */
export function useCustomerNotificationsQuery(options = {}) {
    return VueApolloComposable.useQuery(CustomerNotificationsDocument, {}, options);
}
export function useCustomerNotificationsLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(CustomerNotificationsDocument, {}, options);
}
export const UnreadNotificationsDocument = gql `
    subscription unreadNotifications($accountId: Int!, $venueId: Int) {
  unread_notifications(account_id: $accountId, venue_id: $venueId) {
    count
  }
}
    `;
/**
 * __useUnreadNotificationsSubscription__
 *
 * To run a query within a Vue component, call `useUnreadNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationsSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useUnreadNotificationsSubscription({
 *   accountId: // value for 'accountId'
 *   venueId: // value for 'venueId'
 * });
 */
export function useUnreadNotificationsSubscription(variables, options = {}) {
    return VueApolloComposable.useSubscription(UnreadNotificationsDocument, variables, options);
}
export const ReadNotificationDocument = gql `
    mutation readNotification($notificationId: [Int]!) {
  read_notification(event_notification_ids: $notificationId) {
    event_notification_id
    is_read
  }
}
    `;
/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useReadNotificationMutation({
 *   variables: {
 *     notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useReadNotificationMutation(options = {}) {
    return VueApolloComposable.useMutation(ReadNotificationDocument, options);
}
export const ClearNotificationsDocument = gql `
    mutation clearNotifications($venueId: Int) {
  clear_all_notifications(venue_id: $venueId) {
    event_notification_id
    is_deleted
  }
}
    `;
/**
 * __useClearNotificationsMutation__
 *
 * To run a mutation, you first call `useClearNotificationsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useClearNotificationsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useClearNotificationsMutation({
 *   variables: {
 *     venueId: // value for 'venueId'
 *   },
 * });
 */
export function useClearNotificationsMutation(options = {}) {
    return VueApolloComposable.useMutation(ClearNotificationsDocument, options);
}
export const RemoveNotificationDocument = gql `
    mutation removeNotification($id: Int!) {
  delete_notification(event_notification_id: $id) {
    event_notification_id
    is_deleted
  }
}
    `;
/**
 * __useRemoveNotificationMutation__
 *
 * To run a mutation, you first call `useRemoveNotificationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNotificationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveNotificationMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRemoveNotificationMutation(options = {}) {
    return VueApolloComposable.useMutation(RemoveNotificationDocument, options);
}
export const GetSystemNotificationsDocument = gql `
    query getSystemNotifications {
  get_system_notifications {
    event_id
    event_name
    event_scope
    event_type
    email
    sms
    is_editable
    is_booker
    is_vendor
  }
}
    `;
/**
 * __useGetSystemNotificationsQuery__
 *
 * To run a query within a Vue component, call `useGetSystemNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemNotificationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSystemNotificationsQuery();
 */
export function useGetSystemNotificationsQuery(options = {}) {
    return VueApolloComposable.useQuery(GetSystemNotificationsDocument, {}, options);
}
export function useGetSystemNotificationsLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetSystemNotificationsDocument, {}, options);
}
export const UpdateCollectionDocument = gql `
    mutation updateCollection($input: CollectionInput!) {
  update_collection(input: $input) {
    collection_id
  }
}
    `;
/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCollectionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCollectionMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateCollectionDocument, options);
}
export const CreateCollectionDocument = gql `
    mutation createCollection($input: CollectionInput!) {
  create_collection(input: $input) {
    collection_id
  }
}
    `;
/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCollectionMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useCreateCollectionMutation(options = {}) {
    return VueApolloComposable.useMutation(CreateCollectionDocument, options);
}
export const GetCollectionDocument = gql `
    query getCollection($slug: String!) {
  get_collection(slug: $slug) {
    collection_id
    collection_name
    promo_text
    about_us
    website
    social_media {
      social_media_name
      social_media_link
    }
    logo {
      image_url
    }
    slug
    venues {
      venue_id
      venue_name
      venue_slug
      venue_url
      spaces_count
      venue_geo {
        longitude
        latitude
        city {
          seo_entity_name
          seo_entity_slug
        }
      }
      venue_images {
        image_url
      }
      venue_spaces {
        space_id
        space_name
        is_afterpay_allowed
        space_images {
          image_url
        }
        space_url
        half_day_price
        space_booking_type
      }
    }
  }
}
    `;
/**
 * __useGetCollectionQuery__
 *
 * To run a query within a Vue component, call `useGetCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCollectionQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useGetCollectionQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetCollectionDocument, variables, options);
}
export function useGetCollectionLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetCollectionDocument, variables, options);
}
export const GetCollectionSpacesDocument = gql `
    query getCollectionSpaces($collection_id: Int!, $venue_id: Int, $pagination: Pagination) {
  get_collection_spaces(collection_id: $collection_id, venue_id: $venue_id, pagination: $pagination) {
    result {
      space_id
      space_name
      internal_name
      space_images {
        image_url
      }
      space_url
      half_day_price
      is_afterpay_allowed
      space_booking_type
      venue {
        venue_id
        venue_name
      }
      min_guests
      max_guests
      space_categories {
        category_id
      }
      is_new
    }
    page
    size
    total_pages
  }
}
    `;
/**
 * __useGetCollectionSpacesQuery__
 *
 * To run a query within a Vue component, call `useGetCollectionSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionSpacesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCollectionSpacesQuery({
 *   collection_id: // value for 'collection_id'
 *   venue_id: // value for 'venue_id'
 *   pagination: // value for 'pagination'
 * });
 */
export function useGetCollectionSpacesQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetCollectionSpacesDocument, variables, options);
}
export function useGetCollectionSpacesLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetCollectionSpacesDocument, variables, options);
}
export const GetCollectionNameDocument = gql `
    query getCollectionName($slug: String!) {
  get_collection(slug: $slug) {
    collection_name
  }
}
    `;
/**
 * __useGetCollectionNameQuery__
 *
 * To run a query within a Vue component, call `useGetCollectionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionNameQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCollectionNameQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useGetCollectionNameQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetCollectionNameDocument, variables, options);
}
export function useGetCollectionNameLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetCollectionNameDocument, variables, options);
}
export const GetUserOrganisationCollectionDocument = gql `
    query getUserOrganisationCollection {
  get_user_organisation_collection {
    collection_id
    collection_name
    logo {
      image_url
      image_id
    }
    slug
    promo_text
    has_public_page
    about_us
    website
    social_media {
      social_media_id
      social_media_name
      social_media_link
    }
  }
}
    `;
/**
 * __useGetUserOrganisationCollectionQuery__
 *
 * To run a query within a Vue component, call `useGetUserOrganisationCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrganisationCollectionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetUserOrganisationCollectionQuery();
 */
export function useGetUserOrganisationCollectionQuery(options = {}) {
    return VueApolloComposable.useQuery(GetUserOrganisationCollectionDocument, {}, options);
}
export function useGetUserOrganisationCollectionLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetUserOrganisationCollectionDocument, {}, options);
}
export const GetSocialMediaDocument = gql `
    query getSocialMedia {
  get_all_social_media {
    social_media_id
    social_media_name
  }
}
    `;
/**
 * __useGetSocialMediaQuery__
 *
 * To run a query within a Vue component, call `useGetSocialMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSocialMediaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSocialMediaQuery();
 */
export function useGetSocialMediaQuery(options = {}) {
    return VueApolloComposable.useQuery(GetSocialMediaDocument, {}, options);
}
export function useGetSocialMediaLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetSocialMediaDocument, {}, options);
}
export const SpaceCategoriesDocument = gql `
    query spaceCategories {
  spaceCategories {
    categoryName
    categoryId
  }
}
    `;
/**
 * __useSpaceCategoriesQuery__
 *
 * To run a query within a Vue component, call `useSpaceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSpaceCategoriesQuery();
 */
export function useSpaceCategoriesQuery(options = {}) {
    return VueApolloComposable.useQuery(SpaceCategoriesDocument, {}, options);
}
export function useSpaceCategoriesLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(SpaceCategoriesDocument, {}, options);
}
export const SpaceFeaturesDocument = gql `
    query spaceFeatures {
  getSpaceFeatures {
    featureId
    featureName
    featureType
    iconName
  }
}
    `;
/**
 * __useSpaceFeaturesQuery__
 *
 * To run a query within a Vue component, call `useSpaceFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceFeaturesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSpaceFeaturesQuery();
 */
export function useSpaceFeaturesQuery(options = {}) {
    return VueApolloComposable.useQuery(SpaceFeaturesDocument, {}, options);
}
export function useSpaceFeaturesLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(SpaceFeaturesDocument, {}, options);
}
export const VenueFeaturesDocument = gql `
    query venueFeatures {
  getVenueFeatures {
    featureName
    featureId
    featureType
    iconName
  }
}
    `;
/**
 * __useVenueFeaturesQuery__
 *
 * To run a query within a Vue component, call `useVenueFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueFeaturesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVenueFeaturesQuery();
 */
export function useVenueFeaturesQuery(options = {}) {
    return VueApolloComposable.useQuery(VenueFeaturesDocument, {}, options);
}
export function useVenueFeaturesLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(VenueFeaturesDocument, {}, options);
}
export const SpaceServicesDocument = gql `
    query spaceServices {
  getApiServiceSpace {
    systemServiceName
    systemServiceId
  }
}
    `;
/**
 * __useSpaceServicesQuery__
 *
 * To run a query within a Vue component, call `useSpaceServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceServicesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSpaceServicesQuery();
 */
export function useSpaceServicesQuery(options = {}) {
    return VueApolloComposable.useQuery(SpaceServicesDocument, {}, options);
}
export function useSpaceServicesLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(SpaceServicesDocument, {}, options);
}
export const VenueServicesDocument = gql `
    query venueServices {
  getApiServiceVenue {
    systemServiceName
    systemServiceId
  }
}
    `;
/**
 * __useVenueServicesQuery__
 *
 * To run a query within a Vue component, call `useVenueServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenueServicesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVenueServicesQuery();
 */
export function useVenueServicesQuery(options = {}) {
    return VueApolloComposable.useQuery(VenueServicesDocument, {}, options);
}
export function useVenueServicesLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(VenueServicesDocument, {}, options);
}
export const ConfigurationsDocument = gql `
    query configurations {
  getApiConfiguration {
    configurationId
    configurationType
  }
}
    `;
/**
 * __useConfigurationsQuery__
 *
 * To run a query within a Vue component, call `useConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useConfigurationsQuery();
 */
export function useConfigurationsQuery(options = {}) {
    return VueApolloComposable.useQuery(ConfigurationsDocument, {}, options);
}
export function useConfigurationsLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(ConfigurationsDocument, {}, options);
}
export const SearchDocument = gql `
    mutation search($token: String = "", $input: VenueFindInput!) {
  mutationViewerApiKey(apiKey: $token) {
    searchVenues(venueFindInput: $input) {
      hits {
        startingPrice
        venueId
        venueName
        latitude
        venueUrl
        organisationRating
        venueType
        longitude
        workingTime {
          closeTime
          openTime
          weekDay
        }
        city
        address
        images
        spaces {
          activities
          images
          bookingType
          halfDayPrice
          spaceId
          features
          spaceName
          spaceUrl
          internalName
          spaceSize
          isAfterpayAllowed
          attendees {
            minGuests
            maxGuests
          }
          isHighlighted
          isNew
          isFavorite
        }
      }
      priceHistogram {
        bins {
          count
          from
          to
        }
        maxPrice
        minPrice
      }
      availableFilters {
        attendeesFrom
        attendeesTo
        categories
        configurations
        facilities
        features
        isDirectBooking
        isFavorite
        isSocial
        isPostPayment
        priceFrom
        priceTo
      }
      similaritySuggestions {
        cities
        regions
        spaces
        venues
      }
    }
  }
}
    `;
/**
 * __useSearchMutation__
 *
 * To run a mutation, you first call `useSearchMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSearchMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSearchMutation({
 *   variables: {
 *     token: // value for 'token'
 *     input: // value for 'input'
 *   },
 * });
 */
export function useSearchMutation(options = {}) {
    return VueApolloComposable.useMutation(SearchDocument, options);
}
export const UpdateServiceDocument = gql `
    mutation updateService($venue_id: Int!, $system_service_id: Int!, $inventory_service: InventoryServiceUpdateInput!, $label_id: Int!, $space_ids: [Int!]) {
  inventory_bulk_spaces_linking(venue_id: $venue_id, system_service_id: $system_service_id, inventory_service: $inventory_service, label_id: $label_id, space_ids: $space_ids) {
    system_service_id
    system_service_name
    venue_service_id
    price
    vat_rate
    description
    is_enabled
    related_spaces {
      space_id
    }
  }
}
    `;
/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateServiceMutation({
 *   variables: {
 *     venue_id: // value for 'venue_id'
 *     system_service_id: // value for 'system_service_id'
 *     inventory_service: // value for 'inventory_service'
 *     label_id: // value for 'label_id'
 *     space_ids: // value for 'space_ids'
 *   },
 * });
 */
export function useUpdateServiceMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateServiceDocument, options);
}
export const GetServicesByVenueIdDocument = gql `
    query getServicesByVenueId($venue_id: Int!, $first: Int, $skip: Int) {
  inventory_services(venue_id: $venue_id, first: $first, skip: $skip) {
    system_service_id
    system_service_name
    system_service_type
    venue_service_id
    description
    price
    vat_rate
    is_venue
    is_space
    is_inventory_allowed
    is_inventory_enabled
    is_on_demand
    is_enabled
    is_mandatory
    order_type
    venue_service_order_type
    max_inventory
    related_spaces {
      space_id
    }
  }
}
    `;
/**
 * __useGetServicesByVenueIdQuery__
 *
 * To run a query within a Vue component, call `useGetServicesByVenueIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesByVenueIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetServicesByVenueIdQuery({
 *   venue_id: // value for 'venue_id'
 *   first: // value for 'first'
 *   skip: // value for 'skip'
 * });
 */
export function useGetServicesByVenueIdQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetServicesByVenueIdDocument, variables, options);
}
export function useGetServicesByVenueIdLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetServicesByVenueIdDocument, variables, options);
}
export const PageBySlugDocument = gql `
    mutation pageBySlug($input: PageBySlugInput!) {
  postApiPage(pageBySlugInput: $input) {
    accessInstructions
    bookBeforeMinTh
    bookingLimit
    cancellationTh
    dayPrice
    discountDescription
    discountPercentage
    fullDayPrice
    halfDayPrice
    hasDiscount
    hostApprovalTh
    internalName
    isActive
    preparationTime
    serviceBreak
    shortDayPrice
    spaceBookingType
    spaceDescription
    spaceId
    spaceName
    spaceReference
    spaceImages {
      imageId
      imageUrl
    }
    spaceSize
    spaceUrl
    venueId
    wifiCode
    wifiName
  }
}
    `;
/**
 * __usePageBySlugMutation__
 *
 * To run a mutation, you first call `usePageBySlugMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `usePageBySlugMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = usePageBySlugMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function usePageBySlugMutation(options = {}) {
    return VueApolloComposable.useMutation(PageBySlugDocument, options);
}
export const GetPreliminaryBookingCalculationsDocument = gql `
    mutation getPreliminaryBookingCalculations($input: BookingPreliminaryCalculationInput!) {
  get_booking_preliminary_calculation(input: $input) {
    space_price_with_service_fee_vat_excl
    configuration_price_with_service_fee_vat_excl
    order_items {
      reference_id
      order_item_price_with_service_fee_vat_excl
    }
    total_vat_excl
    vat_21
    vat_9
    total_vat
    total_vat_incl
    discount
    is_reservations_limit_reached
    reservations_limit
  }
}
    `;
/**
 * __useGetPreliminaryBookingCalculationsMutation__
 *
 * To run a mutation, you first call `useGetPreliminaryBookingCalculationsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useGetPreliminaryBookingCalculationsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useGetPreliminaryBookingCalculationsMutation({
 *   variables: {
 *     input: // value for 'input'
 *   },
 * });
 */
export function useGetPreliminaryBookingCalculationsMutation(options = {}) {
    return VueApolloComposable.useMutation(GetPreliminaryBookingCalculationsDocument, options);
}
export const CreateBookingDocument = gql `
    mutation createBooking($input: SpaceBookingInputInput!, $token: String!) {
  mutationViewerApiKey(apiKey: $token) {
    postApiBookingSpace(spaceBookingInputInput: $input) {
      bookingId
    }
  }
}
    `;
/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateBookingMutation({
 *   variables: {
 *     input: // value for 'input'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useCreateBookingMutation(options = {}) {
    return VueApolloComposable.useMutation(CreateBookingDocument, options);
}
export const AddFeaturesDocument = gql `
    mutation addFeatures($feature_ids: [Int!]!, $space_id: Int) {
  bulk_create_space_features(feature_ids: $feature_ids, space_id: $space_id) {
    feature_id
  }
}
    `;
/**
 * __useAddFeaturesMutation__
 *
 * To run a mutation, you first call `useAddFeaturesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddFeaturesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddFeaturesMutation({
 *   variables: {
 *     feature_ids: // value for 'feature_ids'
 *     space_id: // value for 'space_id'
 *   },
 * });
 */
export function useAddFeaturesMutation(options = {}) {
    return VueApolloComposable.useMutation(AddFeaturesDocument, options);
}
export const UpdateImagesListDocument = gql `
    mutation updateImagesList($input: ImageDeleteInput!, $spaceId: Int!, $token: String!) {
  mutationViewerApiKey(apiKey: $token) {
    patchApiImageSpaceSpaceIdBulk(imageDeleteInput: $input, spaceId: $spaceId) {
      imageId
    }
  }
}
    `;
/**
 * __useUpdateImagesListMutation__
 *
 * To run a mutation, you first call `useUpdateImagesListMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImagesListMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateImagesListMutation({
 *   variables: {
 *     input: // value for 'input'
 *     spaceId: // value for 'spaceId'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useUpdateImagesListMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateImagesListDocument, options);
}
export const AddImagesDocument = gql `
    mutation addImages($images_ids: [Int!]!, $space_id: Int!) {
  bulk_create_space_images(images_ids: $images_ids, space_id: $space_id) {
    image_id
  }
}
    `;
/**
 * __useAddImagesMutation__
 *
 * To run a mutation, you first call `useAddImagesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddImagesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddImagesMutation({
 *   variables: {
 *     images_ids: // value for 'images_ids'
 *     space_id: // value for 'space_id'
 *   },
 * });
 */
export function useAddImagesMutation(options = {}) {
    return VueApolloComposable.useMutation(AddImagesDocument, options);
}
export const RemoveSpaceDocument = gql `
    mutation removeSpace($spaceId: Int!, $token: String!) {
  mutationViewerApiKey(apiKey: $token) {
    deleteApiSpaceSpaceId(spaceId: $spaceId)
  }
}
    `;
/**
 * __useRemoveSpaceMutation__
 *
 * To run a mutation, you first call `useRemoveSpaceMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSpaceMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveSpaceMutation({
 *   variables: {
 *     spaceId: // value for 'spaceId'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useRemoveSpaceMutation(options = {}) {
    return VueApolloComposable.useMutation(RemoveSpaceDocument, options);
}
export const UpdateSpaceDetailsDocument = gql `
    mutation updateSpaceDetails($spaceId: Int!, $input: SpaceUpdateInput!, $token: String!) {
  mutationViewerApiKey(apiKey: $token) {
    patchApiSpaceSpaceId(spaceId: $spaceId, spaceUpdateInput: $input) {
      spaceId
    }
  }
}
    `;
/**
 * __useUpdateSpaceDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateSpaceDetailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpaceDetailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSpaceDetailsMutation({
 *   variables: {
 *     spaceId: // value for 'spaceId'
 *     input: // value for 'input'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useUpdateSpaceDetailsMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateSpaceDetailsDocument, options);
}
export const UpdateConfigurationsDocument = gql `
    mutation updateConfigurations($spaceId: Int!, $input: SpaceConfigurationUpdateInput!, $token: String!) {
  mutationViewerApiKey(apiKey: $token) {
    patchApiSpaceSpaceIdConfigurationBulk(spaceId: $spaceId, spaceConfigurationUpdateInput: $input) {
      configurationId
    }
  }
}
    `;
/**
 * __useUpdateConfigurationsMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateConfigurationsMutation({
 *   variables: {
 *     spaceId: // value for 'spaceId'
 *     input: // value for 'input'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useUpdateConfigurationsMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateConfigurationsDocument, options);
}
export const AddConfigurationsDocument = gql `
    mutation addConfigurations($spaceId: Int!, $input: SpaceConfigurationCreateInput!, $token: String!) {
  mutationViewerApiKey(apiKey: $token) {
    postApiSpaceSpaceIdConfigurationBulk(spaceId: $spaceId, spaceConfigurationCreateInput: $input) {
      configurationId
    }
  }
}
    `;
/**
 * __useAddConfigurationsMutation__
 *
 * To run a mutation, you first call `useAddConfigurationsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddConfigurationsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddConfigurationsMutation({
 *   variables: {
 *     spaceId: // value for 'spaceId'
 *     input: // value for 'input'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useAddConfigurationsMutation(options = {}) {
    return VueApolloComposable.useMutation(AddConfigurationsDocument, options);
}
export const UpdateFacilitiesDocument = gql `
    mutation updateFacilities($input: BulkSpaceServicesUpdateInput!, $token: String!) {
  mutationViewerApiKey(apiKey: $token) {
    patchApiSpaceFacilityBulk(bulkSpaceServicesUpdateInput: $input) {
      spaceId
    }
  }
}
    `;
/**
 * __useUpdateFacilitiesMutation__
 *
 * To run a mutation, you first call `useUpdateFacilitiesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilitiesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateFacilitiesMutation({
 *   variables: {
 *     input: // value for 'input'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useUpdateFacilitiesMutation(options = {}) {
    return VueApolloComposable.useMutation(UpdateFacilitiesDocument, options);
}
export const AddFacilitiesDocument = gql `
    mutation addFacilities($input: BulkSpaceServicesCreateInput!, $token: String!) {
  mutationViewerApiKey(apiKey: $token) {
    postApiSpaceFacilityBulk(bulkSpaceServicesCreateInput: $input) {
      spaceId
    }
  }
}
    `;
/**
 * __useAddFacilitiesMutation__
 *
 * To run a mutation, you first call `useAddFacilitiesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddFacilitiesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddFacilitiesMutation({
 *   variables: {
 *     input: // value for 'input'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useAddFacilitiesMutation(options = {}) {
    return VueApolloComposable.useMutation(AddFacilitiesDocument, options);
}
export const SpaceServicesAvailabilityDocument = gql `
    query spaceServicesAvailability($space_id: Int!, $booking_start: String!, $booking_end: String!, $first: Int, $skip: Int, $booking_edit_mode: BookingEditModeInput) {
  space_service_balance(space_id: $space_id, booking_start: $booking_start, booking_end: $booking_end, first: $first, skip: $skip, booking_edit_mode: $booking_edit_mode) {
    venue_service {
      venue_service_id
      system_service_id
      system_service_name
      system_service_type
      price
      price_net
      description
      is_on_demand
      is_mandatory
      max_inventory
      is_inventory_enabled
      expect_quantity
      is_venue
      vat_rate
      order_type
      venue_service_order_type
    }
    remaining_inventory
  }
}
    `;
/**
 * __useSpaceServicesAvailabilityQuery__
 *
 * To run a query within a Vue component, call `useSpaceServicesAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpaceServicesAvailabilityQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSpaceServicesAvailabilityQuery({
 *   space_id: // value for 'space_id'
 *   booking_start: // value for 'booking_start'
 *   booking_end: // value for 'booking_end'
 *   first: // value for 'first'
 *   skip: // value for 'skip'
 *   booking_edit_mode: // value for 'booking_edit_mode'
 * });
 */
export function useSpaceServicesAvailabilityQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(SpaceServicesAvailabilityDocument, variables, options);
}
export function useSpaceServicesAvailabilityLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(SpaceServicesAvailabilityDocument, variables, options);
}
export const GetSpaceDocument = gql `
    query getSpace($slug: String!) {
  specific_instance_by_slug(slug: $slug) {
    ... on Space {
      vat_rate
      service_fee_rate
      access_instructions
      book_before_min_th
      book_before_max_th
      booking_limit
      cancellation_th
      day_price
      discount_percentage
      discount_description
      full_day_price
      half_day_price
      has_discount
      host_approval_th
      internal_name
      is_active
      is_afterpay_allowed
      is_payment_options_selectable
      is_favorite
      organisation_rating {
        score
      }
      preparation_time
      service_break
      short_day_price
      space_booking_type
      space_description
      space_id
      space_name
      space_reference
      space_images {
        image_url
      }
      space_size
      space_url
      venue {
        allow_customer_catering
        venue_id
        venue_name
        venue_url
        venue_type
        host_avatar_link
        working_time {
          week_day
          open_time
          close_time
        }
        venue_geo {
          longitude
          latitude
          city {
            seo_entity_name
            seo_entity_slug
          }
          region {
            seo_entity_name
            seo_entity_slug
          }
          address
          postal_code
        }
        venue_images {
          image_url
        }
        collection {
          collection_name
          slug
          has_public_page
        }
        venue_rules
        venue_services {
          venue_service_id
          system_service_id
          system_service_name
          system_service_type
          price
          description
          is_on_demand
          max_inventory
          is_inventory_enabled
          expect_quantity
          order_type
        }
      }
      space_features {
        feature_type
        feature_name
        feature_id
        description
      }
      space_services {
        system_service_id
        system_service_name
        system_service_type
        description
        price
        max_inventory
        is_inventory_enabled
        expect_quantity
        is_on_demand
        vat_rate
        order_type
      }
      space_configurations {
        configuration_id
        configuration_price
        is_default
        configuration_type
        max_guests
      }
      space_categories {
        category_id
        category_name
        category_description
      }
      is_current_user_owner
      is_prospect
      coupon {
        coupon_id
        coupon_code
        discount_percentage
      }
    }
  }
}
    `;
/**
 * __useGetSpaceQuery__
 *
 * To run a query within a Vue component, call `useGetSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSpaceQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useGetSpaceQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetSpaceDocument, variables, options);
}
export function useGetSpaceLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetSpaceDocument, variables, options);
}
export const GetSpaceTagsDocument = gql `
    query getSpaceTags($slug: String!) {
  specific_instance_by_slug(slug: $slug) {
    ... on Space {
      venue {
        venue_tags {
          importance
          nl
          en
        }
      }
    }
  }
}
    `;
/**
 * __useGetSpaceTagsQuery__
 *
 * To run a query within a Vue component, call `useGetSpaceTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceTagsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSpaceTagsQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useGetSpaceTagsQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetSpaceTagsDocument, variables, options);
}
export function useGetSpaceTagsLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetSpaceTagsDocument, variables, options);
}
export const GetSpaceSeoDocument = gql `
    query getSpaceSeo($slug: String!) {
  specific_instance_by_slug(slug: $slug) {
    ... on Space {
      seo {
        description
        h1
        h2
        meta_description
        meta_title
      }
      seo_extra {
        space_id
        title
        description
        language
      }
      faqs {
        question
        answer
        language
      }
    }
  }
}
    `;
/**
 * __useGetSpaceSeoQuery__
 *
 * To run a query within a Vue component, call `useGetSpaceSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceSeoQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSpaceSeoQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useGetSpaceSeoQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetSpaceSeoDocument, variables, options);
}
export function useGetSpaceSeoLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetSpaceSeoDocument, variables, options);
}
export const GetSpaceDetailsDocument = gql `
    query getSpaceDetails($space_id: Int!) {
  specific_space(space_id: $space_id) {
    space_id
    is_active
    space_url
    space_description
    space_name
    internal_name
    half_day_price
    is_afterpay_allowed
    is_payment_options_selectable
    space_booking_type
    venue {
      venue_id
      venue_images {
        image_url
      }
      venue_name
    }
    space_calendars {
      is_synchronized
    }
  }
}
    `;
/**
 * __useGetSpaceDetailsQuery__
 *
 * To run a query within a Vue component, call `useGetSpaceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceDetailsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetSpaceDetailsQuery({
 *   space_id: // value for 'space_id'
 * });
 */
export function useGetSpaceDetailsQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetSpaceDetailsDocument, variables, options);
}
export function useGetSpaceDetailsLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetSpaceDetailsDocument, variables, options);
}
export const GetAllTagsDocument = gql `
    query getAllTags {
  get_all_tags {
    tag_id
    importance
    nl
    en
  }
}
    `;
/**
 * __useGetAllTagsQuery__
 *
 * To run a query within a Vue component, call `useGetAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTagsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetAllTagsQuery();
 */
export function useGetAllTagsQuery(options = {}) {
    return VueApolloComposable.useQuery(GetAllTagsDocument, {}, options);
}
export function useGetAllTagsLazyQuery(options = {}) {
    return VueApolloComposable.useLazyQuery(GetAllTagsDocument, {}, options);
}
export const AddNewVenueTagsDocument = gql `
    mutation addNewVenueTags($tagIds: [Int!]!, $venueId: Int!) {
  add_venue_tags(venue_id: $venueId, tag_ids: $tagIds) {
    tag_id
  }
}
    `;
/**
 * __useAddNewVenueTagsMutation__
 *
 * To run a mutation, you first call `useAddNewVenueTagsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddNewVenueTagsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddNewVenueTagsMutation({
 *   variables: {
 *     tagIds: // value for 'tagIds'
 *     venueId: // value for 'venueId'
 *   },
 * });
 */
export function useAddNewVenueTagsMutation(options = {}) {
    return VueApolloComposable.useMutation(AddNewVenueTagsDocument, options);
}
export const CreateNewTagDocument = gql `
    mutation createNewTag($tagNameNl: String!, $tagNameEn: String!, $tagImportance: Int!) {
  add_new_tag(tag_name_nl: $tagNameNl, tag_name_en: $tagNameEn, tag_importance: $tagImportance) {
    tag_id
  }
}
    `;
/**
 * __useCreateNewTagMutation__
 *
 * To run a mutation, you first call `useCreateNewTagMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewTagMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateNewTagMutation({
 *   variables: {
 *     tagNameNl: // value for 'tagNameNl'
 *     tagNameEn: // value for 'tagNameEn'
 *     tagImportance: // value for 'tagImportance'
 *   },
 * });
 */
export function useCreateNewTagMutation(options = {}) {
    return VueApolloComposable.useMutation(CreateNewTagDocument, options);
}
export const GetVenueDocument = gql `
    query getVenue($slug: String!) {
  specific_instance_by_slug(slug: $slug) {
    ... on Venue {
      venue_id
      venue_name
      venue_description
      venue_rules
      venue_geo {
        longitude
        latitude
        city {
          seo_entity_name
          seo_entity_slug
        }
        region {
          seo_entity_name
          seo_entity_slug
        }
        address
        postal_code
      }
      collection {
        collection_name
        has_public_page
        slug
      }
      allow_customer_catering
      contact_phone
      contact_email
      host_avatar_link
      is_active
      venue_slug
      venue_url
      venue_type
      venue_images {
        image_url
      }
      working_time {
        week_day
        open_time
        close_time
      }
      venue_services {
        venue_service_id
        system_service_id
        system_service_name
        system_service_type
        price
        vat_rate
        description
        is_inventory_enabled
        is_on_demand
        is_enabled
        max_inventory
        is_inventory_allowed
        is_vendor_manageable
        is_venue
        is_always_on_demand
        order_type
        venue_service_order_type
        allow_on_demand
        is_space
        expect_quantity
      }
      venue_spaces {
        space_id
        space_name
        internal_name
        space_description
        space_reference
        access_instructions
        space_url
        space_size
        half_day_price
        space_booking_type
        is_favorite
        is_afterpay_allowed
        space_images {
          image_url
        }
        space_categories {
          category_id
        }
        space_features {
          feature_id
        }
        min_guests
        max_guests
        is_highlighted
        is_new
      }
      venue_features {
        feature_id
        feature_type
        feature_name
        description
      }
      venue_meta_title
      venue_meta_description
      organisation_rating {
        score
      }
    }
  }
}
    `;
/**
 * __useGetVenueQuery__
 *
 * To run a query within a Vue component, call `useGetVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetVenueQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useGetVenueQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetVenueDocument, variables, options);
}
export function useGetVenueLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetVenueDocument, variables, options);
}
export const GetVenueTagsDocument = gql `
    query getVenueTags($slug: String!) {
  specific_instance_by_slug(slug: $slug) {
    ... on Venue {
      venue_id
      venue_tags {
        tag_id
        importance
        nl
        en
      }
    }
  }
}
    `;
/**
 * __useGetVenueTagsQuery__
 *
 * To run a query within a Vue component, call `useGetVenueTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVenueTagsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetVenueTagsQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useGetVenueTagsQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetVenueTagsDocument, variables, options);
}
export function useGetVenueTagsLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetVenueTagsDocument, variables, options);
}
export const GetPlaceGeocodeDocument = gql `
    query getPlaceGeocode($address: String!) {
  get_geocode(address: $address) {
    place_id
  }
}
    `;
/**
 * __useGetPlaceGeocodeQuery__
 *
 * To run a query within a Vue component, call `useGetPlaceGeocodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceGeocodeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPlaceGeocodeQuery({
 *   address: // value for 'address'
 * });
 */
export function useGetPlaceGeocodeQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetPlaceGeocodeDocument, variables, options);
}
export function useGetPlaceGeocodeLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetPlaceGeocodeDocument, variables, options);
}
export const GetPlacesAutocompleteDocument = gql `
    query getPlacesAutocomplete($autocompleteRequest: String!, $google_session_token: String, $language: String) {
  places_autocomplete(autocomplete_request: $autocompleteRequest, google_session_token: $google_session_token, language: $language) {
    predictions {
      description
      matched_substrings {
        length
        offset
      }
      place_id
      structured_formatting {
        main_text
        main_text_matched_substrings {
          length
          offset
        }
        secondary_text
      }
      terms {
        offset
        value
      }
      types
      url
    }
    status
  }
}
    `;
/**
 * __useGetPlacesAutocompleteQuery__
 *
 * To run a query within a Vue component, call `useGetPlacesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacesAutocompleteQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPlacesAutocompleteQuery({
 *   autocompleteRequest: // value for 'autocompleteRequest'
 *   google_session_token: // value for 'google_session_token'
 *   language: // value for 'language'
 * });
 */
export function useGetPlacesAutocompleteQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetPlacesAutocompleteDocument, variables, options);
}
export function useGetPlacesAutocompleteLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetPlacesAutocompleteDocument, variables, options);
}
export const GetPlaceInfoDocument = gql `
    query getPlaceInfo($place_id: String!, $google_session_token: String) {
  get_place_info(place_id: $place_id, google_session_token: $google_session_token) {
    html_attributions
    result {
      address_components {
        long_name
        short_name
        types
      }
      formatted_address
      geometry {
        location {
          lat
          lng
        }
        viewport {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      place_id
    }
    status
  }
}
    `;
/**
 * __useGetPlaceInfoQuery__
 *
 * To run a query within a Vue component, call `useGetPlaceInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceInfoQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPlaceInfoQuery({
 *   place_id: // value for 'place_id'
 *   google_session_token: // value for 'google_session_token'
 * });
 */
export function useGetPlaceInfoQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(GetPlaceInfoDocument, variables, options);
}
export function useGetPlaceInfoLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(GetPlaceInfoDocument, variables, options);
}
export const PhoneValidityDocument = gql `
    query phoneValidity($input: PhoneInput!) {
  phone_validity(input: $input) {
    is_valid
  }
}
    `;
/**
 * __usePhoneValidityQuery__
 *
 * To run a query within a Vue component, call `usePhoneValidityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhoneValidityQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePhoneValidityQuery({
 *   input: // value for 'input'
 * });
 */
export function usePhoneValidityQuery(variables, options = {}) {
    return VueApolloComposable.useQuery(PhoneValidityDocument, variables, options);
}
export function usePhoneValidityLazyQuery(variables, options = {}) {
    return VueApolloComposable.useLazyQuery(PhoneValidityDocument, variables, options);
}
