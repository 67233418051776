import { legacyClient } from '@/main';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { UpdateRefreshAndAccessTokensDocument } from '@/generated-types/graphql.types';
export const logout = () => globalThis.$store?.dispatch('authentication/logout');
export const isAuthorized = () => {
    return !!getToken();
};
export const getToken = () => {
    return globalThis.$store?.state.authentication.accessToken;
};
let refreshTokenAttempt = 1;
export const refreshAuthToken = async () => {
    const refreshToken = globalThis.$store?.getters['authentication/refreshToken'];
    if (refreshToken) {
        try {
            const { data } = await legacyClient.mutate({
                mutation: UpdateRefreshAndAccessTokensDocument,
                variables: {
                    input: { token: refreshToken }
                },
                context: {
                    debounceKey: 'refreshToken',
                    debounceTimeout: 300
                }
            });
            const { accessToken: newAccessToken, refreshToken: newRefreshToken } = data.updateRefreshAndAccessTokens;
            if (newAccessToken && newRefreshToken && refreshTokenAttempt) {
                globalThis.$store?.dispatch('authentication/authorize', {
                    accessToken: newAccessToken,
                    refreshToken: newRefreshToken
                });
                refreshTokenAttempt = 0;
                return data.updateRefreshAndAccessTokens;
            }
            else {
                globalThis.$store?.dispatch('authentication/logout');
                globalThis.$router.replace({
                    name: ROUTE_LIST_NAMES.MAIN,
                    query: {
                        token: null,
                        email_token: null,
                        ['verify-email']: null,
                        ['verify-phone-with-link']: null,
                        redirect_url: null
                    }
                });
                refreshTokenAttempt = 1;
            }
            return Promise.reject(new Error('Something went wrong during token refreshment'));
        }
        catch (e) {
            globalThis.$store?.dispatch('authentication/logout');
            return Promise.reject(null);
        }
    }
    else {
        return Promise.reject(null);
    }
};
