import * as Sentry from '@sentry/vue';
import Vue from 'vue';
const sentryDsn = process.env.VUE_APP_SENTRY_DSN;
export const setupSentry = (router) => {
    Sentry.init({
        Vue,
        dsn: sentryDsn,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            }),
            new Sentry.Replay()
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
            'localhost',
            /^https:\/\/wemeet\.nl/,
            /^https:\/\/dev\.wemeet\.zone/
        ],
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    });
};
