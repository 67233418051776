import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
import SearchHeader from '@/Search/components/SearchHeader.vue';
/** Components */
const EmptyLayout = () => import(
/* webpackChunkName: "helpdesk.route" */ '@/layouts/EmptyLayout.vue');
const Helpdesk = () => import(
/* webpackChunkName: "helpdesk.route" */ '@/Helpdesk/Helpdesk.vue');
const HelpdeskCategories = () => import(
/* webpackChunkName: "helpdesk.route" */ '@/Helpdesk/HelpdeskCategories.vue');
const HelpdeskArticle = () => import(
/* webpackChunkName: "helpdesk.route" */ '@/Helpdesk/HelpdeskArticle.vue');
export const helpdeskRoutes = [
    {
        path: `/${HelpdeskRoutes.Main}`,
        localePath: HelpdeskRoutes.Main,
        component: EmptyLayout,
        meta: {
            header: SearchHeader
        },
        children: [
            {
                path: '/',
                component: Helpdesk
            },
            {
                path: HelpdeskRoutes.Categories,
                component: HelpdeskCategories
            },
            {
                path: HelpdeskRoutes.Article,
                component: HelpdeskArticle
            }
        ]
    }
];
