import { Observable } from 'apollo-link';
import { refreshAuthToken } from '@/Authentication/utils/token';
import { ServerErrors } from '@/util/graphql';
const fromPromise = (promise) => new Observable(subscriber => {
    promise.then(value => {
        if (subscriber.closed) {
            return;
        }
        subscriber.next(value);
        subscriber.complete();
    }, err => subscriber.error(err));
    return subscriber;
});
export const GraphqlAuthGuard = ({ operation, forward, graphQLErrors, response }) => {
    const status = ServerErrors.extractGQLStatus(graphQLErrors?.[0] || response?.errors?.[0]);
    if (status === 401 && process.browser) {
        return fromPromise(refreshAuthToken().then(tokens => {
            if (tokens) {
                operation.variables.token = tokens.accessToken;
            }
        })).flatMap(() => forward(operation));
    }
    forward(operation);
};
