import { BookingRoutes } from '@/Booking/booking.const';
import { FinanceRoutes } from '@/Finance/finance.const';
import RouteGuard from '@/Authentication/authentication.guard';
import BookingHeader from '@/Booking/components/BookingHeader.vue';
import PaymentHeader from '@/Booking/Payment/PaymentHeader.vue';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
const EmptyLayout = () => import(
/* webpackChunkName: "empty_layout.route" */ '@/layouts/EmptyLayout.vue');
/** Components */
const UserBookings = () => import(
/* webpackChunkName: "user-bookings.route" */ '@/Booking/UserBookings.vue');
const BookingDetails = () => import(
/* webpackChunkName: "booking-details.route" */ '@/Booking/BookingDetails');
const BookingProcess = () => import(
/* webpackChunkName: "booking-process.route" */ '@/Booking/BookingProcess');
const BookingProcessSummary = () => import(
/* webpackChunkName: "booking-process.route" */ '@/Booking/BookingProcess/BookingProcessSummary.vue');
const VendorBookings = () => import(
/* webpackChunkName: "vendor-dashboard.route" */ '@/Booking/VendorBookings/VendorDashboard.vue');
const AvailabilityManagement = () => import(
/* webpackChunkName: "vendor-dashboard.route" */ '@/Booking/Availability/AvailabilityManagement.vue');
const BookingFinance = () => import(
/* webpackChunkName: "booking-finance.route" */ '@/Booking/BookingFinance.vue');
const BookingPayment = () => import(
/* webpackChunkName: "booking-payment.route" */ '@/Booking/Payment/Payment.vue');
const BookingPaymentDetails = () => import(
/* webpackChunkName: "booking-payment.route" */ '@/Booking/Payment/PaymentDetails.vue');
const BookingPaymentProcess = () => import(
/* webpackChunkName: "booking-payment.route" */ '@/Booking/Payment/PaymentProcess.vue');
const BookingPaymentSuccess = () => import(
/* webpackChunkName: "booking-payment.route" */ '@/Booking/Payment/PaymentResult.vue');
const CouponManagement = () => import(
/* webpackChunkName: "vendor-dashboard.route" */ '@/Coupon/CouponManagement.vue');
export const bookingRoutes = [
    {
        path: `/${BookingRoutes.BOOKING}/:bookingId(\\d+)`,
        localePath: `${BookingRoutes.BOOKING}/:bookingId(\\d+)`,
        component: EmptyLayout,
        beforeEnter: RouteGuard.authorized,
        meta: {
            breadcrumbs: BookingHeader
        },
        children: [
            {
                path: '/',
                component: BookingProcess,
                meta: {
                    mobileFullWidth: true,
                    maxWidthContainer: false,
                    center: true
                }
            },
            {
                path: 'summary',
                component: BookingProcessSummary,
                meta: {
                    mobileFullWidth: true,
                    maxWidthContainer: false,
                    center: false
                }
            }
        ]
    },
    {
        path: `/${BookingRoutes.BOOKINGS}`,
        localePath: BookingRoutes.BOOKINGS,
        component: EmptyLayout,
        beforeEnter: RouteGuard.authorized,
        children: [
            {
                path: 'all',
                component: UserBookings,
                beforeEnter: RouteGuard.authorized,
                meta: {
                    maxWidthContainer: true,
                    center: true
                }
            },
            /** TODO: refactor to a new structure */
            {
                path: `${BookingRoutes.VENDOR}/:venueId?`,
                component: VendorBookings,
                name: ROUTE_LIST_NAMES.BOOKINGS.VENDOR,
                // beforeEnter: RouteGuard.roleAccess(UserRoleState.Vendor),
                meta: {
                    savePosition: true
                }
            },
            /** TODO: refactor to a new structure */
            {
                path: `${BookingRoutes.VENDOR}/:venueId/${BookingRoutes.AVAILABILITY}`,
                component: AvailabilityManagement
            },
            {
                path: `${BookingRoutes.VENDOR}/:venueId/${BookingRoutes.COUPONS}`,
                component: CouponManagement
            },
            {
                path: ':bookingId(\\d+)',
                component: BookingDetails,
                meta: {
                    mobileFullWidth: true,
                    maxWidthContainer: true,
                    center: true
                }
            },
            {
                path: `:bookingId(\\d+)/${FinanceRoutes.FINANCE}`,
                component: BookingFinance,
                meta: {
                    mobileFullWidth: true,
                    maxWidthContainer: false,
                    center: false
                }
            }
        ]
    },
    {
        path: `/${BookingRoutes.BOOKING}${BookingRoutes.PAYMENT}/:hash([0-9a-zA-z]{6,8})`,
        localePath: `${BookingRoutes.BOOKING}${BookingRoutes.PAYMENT}/:hash([0-9a-zA-z]{6,8})`,
        component: EmptyLayout,
        children: [
            {
                path: '/',
                component: BookingPayment,
                meta: {
                    mobileFullWidth: true,
                    maxWidthContainer: true,
                    center: true,
                    breadcrumbs: PaymentHeader
                },
                children: [
                    {
                        path: '/',
                        component: BookingPaymentDetails,
                        meta: {
                            mobileFullWidth: true,
                            maxWidthContainer: true,
                            center: true
                        }
                    },
                    {
                        path: BookingRoutes.PAY,
                        component: BookingPaymentProcess,
                        meta: {
                            mobileFullWidth: true,
                            maxWidthContainer: true,
                            center: true
                        }
                    },
                    {
                        path: BookingRoutes.SUCCESS,
                        component: BookingPaymentSuccess,
                        meta: {
                            mobileFullWidth: true,
                            maxWidthContainer: true,
                            center: true
                        }
                    }
                ]
            }
        ]
    }
];
