import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
export const EVENTBUS_EVENTS = {
    API_ERROR: 'apiError',
    REDIRECT: 'redirect'
};
export const DAY_NAMES = {
    MONDAY: 'MON',
    TUESDAY: 'TUE',
    WEDNESDAY: 'WED',
    THURSDAY: 'THU',
    FRIDAY: 'FRI',
    SATURDAY: 'SAT',
    SUNDAY: 'SUN'
};
export const ACTIVITIES = {
    WE_MEET: 'we_meet',
    WE_PRESENT: 'we_present',
    WE_TRAIN: 'we_train',
    WE_CELEBRATE: 'we_celebrate'
};
export const SPACE_CONFIGURATIONS = {
    BLOCK: 'block',
    U_SHAPE: 'u-shape',
    THEATRE: 'theater',
    CLASSROOM: 'classroom',
    CIRCLE: 'circle',
    OPEN_SPACE: 'open_space',
    COCKTAIL: 'cocktail-style',
    INFORMAL: 'informal',
    BANQUET: 'banquet'
};
export const VAT_RATES = {
    TWENTY_ONE: 21,
    NINE: 9,
    NONE: 0
};
export const ORGANISATION_TYPES = {
    PUBLIC_ORGANISATION: 'PUBLIC',
    PRIVATE_INDIVIDUAL: 'PRIVATE'
};
export const FEATURES_ICONS = {
    wheelchair_accessible: 'wheelchair',
    public_transport_nearby: 'bus',
    rooms_for_overnight_stay: 'moon',
    inspiring_location: 'star',
    catering: 'utensils',
    bar_cafe: 'coffee',
    host_on_site: 'user',
    restaurant: 'utensils'
};
export const LOCATIONS = [
    { name: 'find', icon: 'church', resize: '?tr=w-17,h-60' },
    { name: 'great', icon: 'museum', resize: '?tr=w-60,h-60' },
    { name: 'idea', icon: 'community', resize: '?tr=w-96,h-60' },
    { name: 'cost', icon: 'monument', resize: '?tr=w-76,h-60' }
];
export const SPACE_ROUTES = [
    {
        ready: 'details',
        routeName: ROUTE_LIST_NAMES.ONBOARDING.SPACE.DETAILS,
        label: 'label_details_link'
    },
    {
        ready: 'configurations',
        routeName: ROUTE_LIST_NAMES.ONBOARDING.SPACE.CONFIGURATIONS,
        label: 'label_configurations_link'
    },
    {
        ready: 'bookingRules',
        routeName: ROUTE_LIST_NAMES.ONBOARDING.SPACE.BOOKING_RULES,
        label: 'label_booking_rules_link'
    },
    {
        ready: 'facilities',
        routeName: ROUTE_LIST_NAMES.ONBOARDING.SPACE.FACILITIES,
        label: 'label_facilities_link'
    },
    {
        ready: 'pricing',
        routeName: ROUTE_LIST_NAMES.ONBOARDING.SPACE.PRICING,
        label: 'label_pricing_link'
    },
    {
        ready: 'features',
        routeName: ROUTE_LIST_NAMES.ONBOARDING.SPACE.FEATURES,
        label: 'label_features_link'
    }
];
export const AVAILABLE_IMAGE_FORMATS = {
    JPEG: {
        mediaType: 'image/jpeg',
        extensions: ['.jpeg', '.jpg']
    },
    PNG: {
        mediaType: 'image/png',
        extensions: ['.png']
    },
    SVG: {
        mediaType: 'image/svg+xml',
        extensions: ['.svg']
    },
    WEBP: {
        mediaType: 'image/webp',
        extensions: ['.webp']
    }
};
export const AVAILABLE_IMAGE_MEDIA_TYPES = Object.entries(AVAILABLE_IMAGE_FORMATS).map(([_, formatInfo]) => formatInfo.mediaType);
export const AVAILABLE_IMAGE_EXTENSIONS = Object.entries(AVAILABLE_IMAGE_FORMATS).map(([_, formatInfo]) => formatInfo.extensions.join(', '));
export const IMAGE_VALIDATION_ERRORS = {
    TOO_BIG: 'tooBig',
    UNAVAILABLE_FORMAT: 'unavailableFormat',
    WRONG_ASPECT_RATIO: 'wrongAspectRatio',
    WRONG_SIZE: 'wrongSize'
};
export const IMAGE_VALIDATION_RULES = {
    MAX_SIZE: 20000000,
    MIN_WIDTH: 641,
    MIN_HEIGHT: 427,
    MIN_ASPECT_RATIO: 1.3,
    MAX_ASPECT_RATIO: 1.8
};
