import actions from '@/shared/store-modules/history/actions';
import getters from '@/shared/store-modules/history/getters';
import mutations from '@/shared/store-modules/history/mutations';
const state = () => ({
    histories: [
        {
            created: '14 Jan 2018, 10:28',
            in: '4a',
            bookingId: 'ID 12PO45P',
            payout: '€XX',
            status: 'TRANSFERRED',
            updated: new Date().getTime()
        },
        {
            created: '14 Jan 2018, 10:28',
            in: '3a',
            bookingId: 'ID 12PO45P',
            payout: '€XX',
            status: 'REVIEW',
            updated: new Date().getTime()
        },
        {
            created: '14 Jan 2018, 10:28',
            in: '1a',
            bookingId: 'ID 12PO45P',
            payout: '€XX',
            status: 'PENDING',
            updated: new Date().getTime()
        },
        {
            created: '14 Jan 2018, 10:28',
            in: '2a',
            bookingId: 'ID 12PO45P',
            payout: '€XX',
            status: 'TRANSFERRED',
            updated: new Date().getTime()
        }
    ]
});
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
