import actions from '@/shared/modules/calendar/store/actions';
import getters from '@/shared/modules/calendar/store/getters';
import mutations from '@/shared/modules/calendar/store/mutations';
import { RESERVATION_STATUS } from '@/Calendar/const';
const state = () => ({
    RESERVATION_STATUS,
    WEEKDAYS: [1, 2, 3, 4, 5, 6, 7],
    errorSpaceBookings: '',
    currentDay: '',
    currentWeekNumber: ''
});
export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state
};
