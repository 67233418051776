import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const videoUrl = props.blok.video_url;
        const getVideoId = () => {
            if (videoUrl.startsWith('https://www.youtube.com/watch?v=')) {
                return videoUrl.split('=')[1];
            }
            else if (videoUrl.startsWith('https://youtu.be/')) {
                return videoUrl.split('/')[3];
            }
            else {
                return '';
            }
        };
        return { getVideoId };
    }
});
