export const defaultFilter = {
    searchKey: '',
    address: [],
    isDirectBooking: false,
    features: {
        wheelchair_accessible: false,
        public_transport_nearby: false,
        rooms_for_overnight_stay: false,
        inspiring_location: false,
        waterside: false,
        garden_terrace_or_courtyard: false,
        catering: false,
        bar_cafe: false,
        restaurant: false,
        host_on_site: false,
        privacy: false,
        air_conditioning: false,
        cabled_internet: false,
        toilet_for_disabled: false
    },
    facilities: {
        self_service_coffee: false,
        smart_board: false,
        white_board: false,
        flipover: false,
        flat_tv: false,
        video_conferencing: false,
        audio_conferencing: false,
        beamer: false,
        light_installation: false,
        scanner: false,
        printer: false,
        copier: false,
        sound_system_incl_mic: false,
        sound_system_excl_mic: false,
        stage_parts: false,
        parking: false,
        lockers: false,
        kitchen: false
    },
    activities: {
        we_meet: false,
        we_present: false,
        we_train: false,
        we_celebrate: false
    },
    more: {
        attendees: 15,
        configurations: {
            block_boardroom: false,
            u_shape: false,
            theatre: false,
            classroom: false,
            circle: false,
            open_space: false,
            cocktail: false,
            informal: false,
            banquet: false
        }
    },
    price: {
        prices: [],
        counts: [],
        value: [0, 0]
    },
    time: {
        day: null,
        mode: {
            all_day: true,
            morning: false,
            afternoon: false,
            evening: false,
            custom_time: false
        },
        from: null,
        to: null
    }
};
export const defaultImages = [
    'https://media.wemeet.nl/gallery/assets/common/hero-1_usAaYpuio.png',
    'https://media.wemeet.nl/gallery/assets/common/hero-2_J4-_qbDnS.png',
    'https://media.wemeet.nl/gallery/assets/common/hero-3_kjdVpmdc7.png',
    'https://media.wemeet.nl/gallery/assets/common/space-1_2nBbJTkON.png',
    'https://media.wemeet.nl/gallery/assets/common/space-2_91aDthl5r.png'
];
export const venueFeatureKeys = [
    'wheelchair_accessible',
    'public_transport_nearby',
    'rooms_for_overnight_stay',
    'inspiring_location',
    'waterside',
    'garden_terrace_or_courtyard',
    'catering',
    'bar_cafe',
    'restaurant',
    'host_on_site'
];
export const spaceFeatureKeys = [
    'privacy',
    'waterside',
    'air_conditioning',
    'cabled_internet',
    'toilet_for_the_disabled'
];
