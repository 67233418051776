import { OrganisationRoutes } from '@/Organisation/organisation.const';
import OrganisationHeader from '@/Organisation/components/OrganisationHeader.vue';
/** Components */
const OrganisationPage = () => import(
/* webpackChunkName: "organisation.route" */ '@/Organisation/OrganisationPage.vue');
export const organisationRoutes = [
    {
        path: `/${OrganisationRoutes.ORGANISATION}/:hash/:slug`,
        localePath: `${OrganisationRoutes.ORGANISATION}/:hash/:slug`,
        meta: {
            breadcrumbs: OrganisationHeader,
            savePosition: true
        },
        beforeEnter: (to, _, next) => {
            const locale = to.params.locale;
            return next({
                query: { lang: locale }
            });
        },
        component: OrganisationPage
    }
];
