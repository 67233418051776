import { Workbox } from 'workbox-window';

function registerWorker() {
  let wb;

  // Prevent service worker registration if Cypress is running
  if (globalThis.Cypress) {
    return null;
  }

  if ('serviceWorker' in navigator) {
    wb = new Workbox('/service-worker.js');
    wb.addEventListener('controlling', () => {
      window.location.reload();
    });
    wb.register();
  } else {
    wb = null;
  }
  return wb;
}

const wb = registerWorker();

export default wb;
