import { defineComponent } from '@vue/composition-api';
export const Option = defineComponent({
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: [Boolean, String, Array, Object, Number],
            default: () => null
        }
    },
    render(h) {
        return (<div onClick={() => this.$emit('select', this.$props.value)} class="ui-toggle-option">
        {this.$props.label}
      </div>);
    }
});
export const Toggle = defineComponent({
    name: 'Toggle',
    props: {
        value: {
            type: [Boolean, String, Array, Object, Number],
            default: () => undefined
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    render(h) {
        const children = this.$slots.default
            .filter(({ tag }) => tag)
            .map(item => ({
            ...item,
            componentOptions: {
                ...item.componentOptions,
                listeners: Object.assign({}, item.componentOptions.listeners, {
                    select: (val) => !this.$props.disabled ? this.$emit('input', val) : null
                })
            }
        }));
        const values = children.map(item => item?.componentOptions?.propsData?.value);
        const activeIndex = values?.findIndex(item => item === this.$props.value);
        const classNames = `ui-toggle${this.$props.disabled ? ' disabled' : ''}`;
        return (<div class={classNames}>
        <div class="ui-toggle--active" style={{
                width: `calc((100% - 4px) / ${children.length})`,
                transform: `translateX(${(~activeIndex && activeIndex) * 100}%)`
            }}/>
        {children}
      </div>);
    }
});
export default Toggle;
