import BaseModel from '@/shared/models/BaseModel';
import SpaceSearchModel from '@/shared/models/space/SpaceSearchModel';
class VenueSearchModel extends BaseModel {
    id;
    images;
    lat;
    lng;
    name;
    startingPrice;
    venueUrl;
    workingTime;
    constructor() {
        super();
        this.id = '';
        this.name = '';
        this.images = null;
        this.lat = '';
        this.lng = '';
        this.startingPrice = 0;
        this.workingTime = [];
        this.venueUrl = null;
    }
    apiAttributes() {
        return {
            id: 'venue_id',
            name: 'venue_name',
            images: 'images',
            lat: 'latitude',
            lng: 'longitude',
            startingPrice: 'starting_price',
            workingTime: 'working_time',
            venueUrl: 'venue_url'
        };
    }
    setSpaces({ venue, venueId, activitiesList, venueUrl }) {
        this.spaces = venue.spaces.map((el) => {
            const space = new SpaceSearchModel({
                venueId,
                venueUrl
            });
            space.loadFromAPI({ model: el, slugFlag: false });
            space.mapActivities({ space, activitiesList, findByProp: 'category_id' });
            return space;
        });
    }
    get location() {
        return {
            lat: this.lat,
            lng: this.lng
        };
    }
    get price() {
        return this.startingPrice;
    }
}
export default VenueSearchModel;
