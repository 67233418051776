import i18n from '@/i18n/i18n-setup';
export var GQLLegacyError;
(function (GQLLegacyError) {
    GQLLegacyError.isError = (error) => {
        return error?.extensions && 'responseBody' in error.extensions;
    };
})(GQLLegacyError || (GQLLegacyError = {}));
export var GQLError;
(function (GQLError) {
    GQLError.isError = (error) => {
        return error && 'status_code' in error;
    };
})(GQLError || (GQLError = {}));
export var ServerErrors;
(function (ServerErrors) {
    let ErrorType;
    (function (ErrorType) {
        ErrorType["FIELD_VALIDATION"] = "FIELD_VALIDATION";
        ErrorType["VALIDATION"] = "VALIDATION";
    })(ErrorType = ServerErrors.ErrorType || (ServerErrors.ErrorType = {}));
    class Error {
        error;
        message;
        meta;
        key;
        status_code;
        constructor(error) {
            this.error = error;
            this.message = error.key
                ? i18n.t(`common.errors.${error.key}`)
                : process.env.NODE_ENV === 'development'
                    ? error.text
                    : i18n.t('common.errors.something_wrong');
            this.meta = error.meta;
            this.key = error.key;
            this.status_code = error.status_code;
        }
        toString() {
            return this.message;
        }
    }
    ServerErrors.Error = Error;
    ServerErrors.isValidationError = (data) => data?.error?.error_type === ErrorType.VALIDATION;
    ServerErrors.isCommonError = (data) => data?.error?.error_type &&
        ![ErrorType.VALIDATION, ErrorType.FIELD_VALIDATION].includes(data.error.error_type);
    ServerErrors.extractGQLStatus = (data) => {
        if (!data) {
            return data;
        }
        if (GQLLegacyError.isError(data)) {
            return data.extensions?.statusCode;
        }
        if (GQLError.isError(data)) {
            return data.status_code;
        }
    };
    const mapError = (item) => {
        const { error_type, entity } = item;
        if (error_type === ErrorType.FIELD_VALIDATION) {
            return {
                [entity]: new Error(item)
            };
        }
        return new ServerErrors.Error(item);
    };
    ServerErrors.extractGQLError = (data) => {
        if (!data) {
            return data;
        }
        if (Array.isArray(data)) {
            const [item] = data;
            return mapError(GQLLegacyError.isError(item) ? item.extensions.responseBody : item);
        }
        return mapError(GQLLegacyError.isError(data) ? data.extensions.responseBody : data);
    };
})(ServerErrors || (ServerErrors = {}));
