import { validateEmail, validatePhone } from '@/util/utils';
class ValidationHelper {
    static phone(form, value) {
        return value ? validatePhone(value) : true;
    }
    static email(form, value) {
        return value ? validateEmail(value) : true;
    }
    static required(form, value) {
        return value;
    }
    static requiredIfEqual(form, value, validationRule) {
        return form[validationRule.field] === validationRule.value ? value : true;
    }
    static maxLength(form, value, validationRule) {
        return value.length <= validationRule.value;
    }
    static minLength(form, value, validationRule) {
        return value.length >= validationRule.value;
    }
    static maxInt(form, value, validationRule) {
        return value <= validationRule.value;
    }
    static oneOfValues(form, value, validationRule) {
        return validationRule.values.includes(value);
    }
}
export default ValidationHelper;
