export var PRE_SAVE_ACTION_TYPES;
(function (PRE_SAVE_ACTION_TYPES) {
    PRE_SAVE_ACTION_TYPES["BOOKING"] = "booking";
    PRE_SAVE_ACTION_TYPES["CHAT"] = "chat";
    PRE_SAVE_ACTION_TYPES["COUPON_CODE"] = "couponCode";
    PRE_SAVE_ACTION_TYPES["SPACE_ID"] = "spaceId";
    PRE_SAVE_ACTION_TYPES["SPACE_ACTIVATION"] = "spaceActivation";
})(PRE_SAVE_ACTION_TYPES || (PRE_SAVE_ACTION_TYPES = {}));
export const preSaveAction = (obj) => {
    localStorage.setItem('preSavedType', obj.type);
    if (obj.space) {
        localStorage.setItem('preSavedSpace', JSON.stringify(obj.space));
    }
    if (obj.summary) {
        localStorage.setItem('preSavedBookingSummary', JSON.stringify(obj.summary));
    }
    if (obj.chatType) {
        localStorage.setItem('preSavedChatType', obj.chatType);
    }
    if (obj.venue) {
        localStorage.setItem('preSavedVenue', JSON.stringify(obj.venue));
    }
    if (obj.couponCode) {
        localStorage.setItem('preSavedCouponCode', obj.couponCode);
    }
    if (obj.spaceId) {
        localStorage.setItem('preSavedSpaceId', JSON.stringify(obj.spaceId));
    }
};
export const resetPreSaveAction = () => {
    localStorage.removeItem('preSavedType');
    localStorage.removeItem('preSavedSpace');
    localStorage.removeItem('preSavedBookingSummary');
    localStorage.removeItem('preSavedChatType');
    localStorage.removeItem('preSavedVenue');
    localStorage.removeItem('preSavedCouponCode');
    localStorage.removeItem('preSavedSpaceId');
};
