import Vue from 'vue';
import { currencyFilter, momentFilter, truncateText } from '@/util/templateFilters';
const globalFilters = [
    { id: 'currency', filter: currencyFilter },
    { id: 'moment', filter: momentFilter },
    { id: 'truncate', filter: truncateText }
];
/**
 * Register Vue global template filters from the globalFilters array
 */
export const registerGlobalFilters = () => {
    globalFilters.forEach((filter) => {
        Vue.filter(filter.id, filter.filter);
    });
};
