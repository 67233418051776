import RouteGuard from '@/Authentication/authentication.guard';
import { AdminRoutes } from '@/Admin/admin.const';
const EmptyLayout = () => import(
/* webpackChunkName: "admin.route" */ '@/layouts/EmptyLayout.vue');
const Admin = () => import(/* webpackChunkName: "admin.route" */ '@/Admin/Admin.vue');
export const adminRoutes = [
    {
        path: `/${AdminRoutes.ADMIN}`,
        localePath: AdminRoutes.ADMIN,
        component: EmptyLayout,
        beforeEnter: RouteGuard.roleAccess('admin'),
        children: [
            {
                path: '/',
                component: Admin,
                children: [
                    {
                        name: 'admin_dashboard',
                        path: ':tab',
                        component: Admin
                    }
                ]
            }
        ]
    }
];
