import { defineComponent, computed } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { VenueRoutes } from '@/Venue/venue.const';
export default defineComponent({
    props: {
        item: {
            type: Object,
            default: () => { }
        }
    },
    setup(props) {
        const venueImageThumbnail = computed(() => {
            return props.item?.venue_images.length
                ? `${props.item.venue_images[0]?.image_url}/tr:w-300,h-230`
                : '/img/common/placeholder-image.png';
        });
        return {
            venueImageThumbnail,
            VenueRoutes,
            useGetLocalizedPath
        };
    }
});
