import { defineComponent, computed, watch } from '@vue/composition-api';
import PagePagination from '@/components/PagePagination.vue';
import VenuesList from '@/GeoPages/components/VenuesList.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { SearchRoutes } from '@/Search/search.const';
export const mockedVenues = [
    {
        venue_id: 153,
        venue_name: '@droog',
        venue_url: '/n6HBeL6d/droog',
        venue_images: ['https://media.wemeet.nl/gallery/organisation/157/img_322767cd-1f51-4e6c-9a36-798bf2d7eb7e_pjethUtWEC0_.jpg?tr=ar-3-2%2Cw-1200'],
        venue_spaces: [
            {
                space_id: 166,
                __typename: 'Space'
            },
            {
                space_id: 167,
                __typename: 'Space'
            },
            {
                space_id: 168,
                __typename: 'Space'
            }
        ],
        min_price: 275,
        __typename: 'Venue'
    },
    {
        venue_id: 77,
        venue_name: 'Contact Amsterdam',
        venue_url: '/mHxR7g4U/contact-amsterdam',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/79/img_91361b1c-e452-4569-a15d-37cda4958cff_vXIJ85fOcldS.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/79/img_bbc22486-75cd-4d93-a6da-e503dfc1bb11_slaeZpqs5j1.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/79/img_b2a9b6e4-dfe8-4855-bd10-04ef97a767a8_59F7ss1Kb.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/79/img_1fabbb5f-208a-4e24-a87a-abccf171bee2_UMu7P4o3b3U.jpg?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 77,
                __typename: 'Space'
            },
            {
                space_id: 78,
                __typename: 'Space'
            },
            {
                space_id: 79,
                __typename: 'Space'
            }
        ],
        min_price: 287.5,
        __typename: 'Venue'
    },
    {
        venue_id: 152,
        venue_name: 'De Groene Salon aan de Stadhouderskade',
        venue_url: '/ZmTkjes5/de-groene-salon-aan-de-stadhouderskade',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/156/img_3fb2cffc-1bd8-4bac-915a-82176613d8f8_M9OrC13n1v1M.JPG?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/156/img_56f00b9d-9c66-48e7-9930-a59d26ca03bd_IqYD5fwwi.JPG?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/156/img_fe6298fd-f27a-4ca0-963b-b4cc3ed63359_T4S5AClZB.JPG?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/156/img_45216022-4759-4135-a8bb-2bad22e587bd_kUgPDm5akUl0.JPG?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/156/img_b8fb54e9-0878-4e17-b135-67c26e819c9e_ldigapia1Ysg.JPG?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/156/img_eea5db4c-356e-4c5e-871b-a3e88999d3d8_trNgC0kAHKea.JPG?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/156/img_4c880123-7bec-46f4-9493-b2e80b12be12__Vlbnyhxfs1x.JPG?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 165,
                __typename: 'Space'
            }
        ],
        min_price: 97.75,
        __typename: 'Venue'
    },
    {
        venue_id: 73,
        venue_name: 'De Wonderneming',
        venue_url: '/ZfXP4W4c/de-wonderneming',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/74/img_3561f388-dead-457a-91d6-067637845f9a_HIpXe-N0t.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/74/img_51c96da6-d233-4367-a873-8f00c73f3234_zQclWtfQ7.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/74/img_9c542b83-1e15-48cb-a6d0-4ec8f1bd049e_QEpPymAqdn0.jpg?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 71,
                __typename: 'Space'
            }
        ],
        min_price: 97.75,
        __typename: 'Venue'
    },
    {
        venue_id: 80,
        venue_name: 'Diaconie Amsterdam',
        venue_url: '/YqwBu5NE/diaconie-amsterdam',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/83/img_373335b9-67a2-4e99-8094-c17d94c0658a_l0UaGzRXF.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/83/img_cc2f2810-00ac-4e9a-84f6-69675ed4b4d4_nz44MuHOiPX.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/83/img_c32e0625-75e6-4230-ba15-5eaee26d85d7_gacAoXLH5U9.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/83/img_886a1d7b-2013-4846-a28c-2c5e6a6969fb_sT4xsG7FrT_.jpg?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 83,
                __typename: 'Space'
            },
            {
                space_id: 84,
                __typename: 'Space'
            },
            {
                space_id: 85,
                __typename: 'Space'
            },
            {
                space_id: 86,
                __typename: 'Space'
            }
        ],
        min_price: 460,
        __typename: 'Venue'
    },
    {
        venue_id: 197,
        venue_name: 'Filmtheater De Uitkijk ',
        venue_url: '/TRY8pdat/filmtheater-de-uitkijk-',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/199/img_c07eeff1-a398-4328-ac44-f2ef200fdb13_bnd9rSmfq.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/199/img_b7c47f2a-7e4e-4988-8060-3ffb82f9cb90_VHJwzNM06.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/199/img_fb9021e2-b6a5-400c-b48c-dbfbacde856a_k8zYjmiF6.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/199/img_300e984a-30d7-411c-ab6d-29ce120fc086_9COUQRWYxw-.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/199/img_d4c23d89-ac4d-4127-8a33-7551c2865920_T7wAE-1tt.jpg?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 251,
                __typename: 'Space'
            }
        ],
        min_price: 690,
        __typename: 'Venue'
    },
    {
        venue_id: 200,
        venue_name: 'Fotolokaal De Hallen',
        venue_url: '/jav07VZ1/fotolokaal-de-hallen',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/203/img_53bb0964-77f8-428d-bf4e-75e018df1c16_9qYk0STZZ.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/203/img_d765db4a-3e11-4274-afb9-e00e4e54d629_2d3SjYTjr.png?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/203/img_f2ce9879-b19c-41b3-be3b-2b9020016dd8_46_I_fuAf.png?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/203/img_dd2bf5ca-ec3e-4d48-9597-39ee9a63bccf_pZiwE5kcX.png?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/203/img_4b024ddb-3b3c-4d8e-af84-20a954aa9ccd_UTm1DaOvv.png?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/203/img_cece2fcf-7f55-4ec4-86d3-601558f89801_QLVxbuNaP.png?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/203/img_0c9f2813-5201-408c-a364-57c515b4d570_-CytcSZDR.png?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/203/img_ba588b55-a41d-417c-8728-e9b3634e10e2_ke454jF1fuj.png?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/203/img_9fc26f6c-2ab9-4a0f-b8aa-e2e166e8b0d5_eLD85imcH.png?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/203/img_6739df2c-7810-451c-9eaf-8f7f51f0535b_Gm9ljiDURH1.png?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 255,
                __typename: 'Space'
            }
        ],
        min_price: 524.998,
        __typename: 'Venue'
    },
    {
        venue_id: 79,
        venue_name: 'Het West-Indisch Huis',
        venue_url: '/5FrFUUKs/het-west-indisch-huis',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/81/img_4b73ef55-d1d5-4bb6-b393-f29c68c59cf1_-3l5G4vOA6N.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/81/img_58a64cad-146e-431e-af1d-b49469366b8c_gRFoolkY0.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/81/img_630366df-aeaa-4558-af06-1a6db2ff98c7_ygMooHb2FsX.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/81/img_4c149eac-80f3-45dd-babf-99eee0a03fc1_pJnFGj8FX.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/81/img_4dad13a1-2556-4986-a3ee-b6b41258a9d8_9QSqDejzh.JPG?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/81/img_68bac7c3-14cd-4e9f-9ea6-3bac90b3e001_T1rN0wDQY.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/81/img_03329ede-25e2-4fb9-aed4-beba5d7ebf6d_aPhIBLyHoWV.JPG?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/81/img_8608b8f5-1689-4465-b041-31de7b241229_CfnRM7GaW3L.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/81/img_995aaac4-81ad-40c4-9f0d-4482556150fb_H7pg0Q7eA.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/81/img_670b1da5-ade7-420d-948e-b913fd167462_UczyLQx6Me6.jpg?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 80,
                __typename: 'Space'
            },
            {
                space_id: 81,
                __typename: 'Space'
            },
            {
                space_id: 82,
                __typename: 'Space'
            }
        ],
        min_price: 862.5,
        __typename: 'Venue'
    },
    {
        venue_id: 156,
        venue_name: 'Keizersgrachtkerk',
        venue_url: '/LuJlAToC/keizersgrachtkerk',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/160/img_0b8257a1-a852-4abd-a503-fa7dfa62aae6_qP_uZ4IKkrCS.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/160/img_563a80fd-f566-432a-bf43-bbd6fd805c0a_0fylD8T1gv4k.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/160/img_2c02abe8-ba51-46d3-9dd4-ca2c2c5d0981_vygncrDQW.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/160/img_13cfa972-3f80-486e-9fa2-5a59a6d0c08b_Ov9W3kybPq3e.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/160/img_bebd0534-1230-443c-b32d-0957e7b5fd4d_UhU4JGRAK.png?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/160/img_33d564cf-07ba-4c1c-bfaf-78faa01c677f_zTBwLh6_it6A.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/160/img_e0281e45-450f-4a1c-9741-dd6652f35bf2_Ak2O7t9lP.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/160/img_de0aeb55-9329-4cc7-b769-600c71d0a000_uEqr3uPKG.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/160/img_31365939-6ca8-41ea-b4e0-1408e0f46f31_oiYyKIlHGxXp.JPG?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/160/img_bf4773c5-bd92-49b4-8b42-d2e6bce69338_0ucObZkRL.jpg?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 175,
                __typename: 'Space'
            },
            {
                space_id: 176,
                __typename: 'Space'
            }
        ],
        min_price: 402.5,
        __typename: 'Venue'
    },
    {
        venue_id: 158,
        venue_name: 'Kerkelijk centrum De Bron',
        venue_url: '/A5cm8S6q/kerkelijk-centrum-de-bron',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/161/img_bab52290-d7db-4f45-a1a3-b68a824baa73_yR5H1SsYiLi9S.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/161/img_5b094433-cec5-4749-ab9f-b616ae4be6c0_AHORpOcNKV.jpg?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 177,
                __typename: 'Space'
            }
        ],
        min_price: 690,
        __typename: 'Venue'
    },
    {
        venue_id: 81,
        venue_name: 'Koninklijk Instituut voor de Tropen',
        venue_url: '/Sd4ok3IH/koninklijk-instituut-voor-de-tropen',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/82/img_3ae937ba-8c03-4d6e-8258-86e4deb1161c_1vnl-oMIW.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/82/img_61fbc28d-27f9-4870-bae8-e80e1e521173_4oTp4LgjkQyL.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/82/img_e652732a-fc43-4ab1-8792-2fa69464a396_Di7rARnA7.jpg?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 87,
                __typename: 'Space'
            },
            {
                space_id: 89,
                __typename: 'Space'
            },
            {
                space_id: 90,
                __typename: 'Space'
            },
            {
                space_id: 91,
                __typename: 'Space'
            },
            {
                space_id: 92,
                __typename: 'Space'
            },
            {
                space_id: 93,
                __typename: 'Space'
            },
            {
                space_id: 95,
                __typename: 'Space'
            },
            {
                space_id: 96,
                __typename: 'Space'
            }
        ],
        min_price: 201.25,
        __typename: 'Venue'
    },
    {
        venue_id: 116,
        venue_name: 'Moustaqbel Amsterdam',
        venue_url: '/f8jzB9HN/moustaqbel-amsterdam',
        venue_images: [
            'https://media.wemeet.nl/gallery/organisation/118/img_d7e3dfb3-71fc-47e2-8769-d63b3ff86009_shvsEY_hq.jpeg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/118/img_5732f652-59a2-4d12-a5e7-5eefa96b144c_d5WWqo433.jpeg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/118/img_a1d8e7c8-326b-4991-80ef-98e456d57ccc_DLH4nf-4ocxc.jpeg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/118/img_f5afb5f5-f3c9-441f-83ee-454e413b8a5a_W5fWjuIAdK_R.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/118/img_9ba11b5f-6e4f-4030-8fd3-a981c7e7439b_OUucnrrUr.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/118/img_e96de1df-1640-4fdd-9c64-0621918dc339_UAo28zbQzeW5.jpeg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/118/img_8facd328-9e1a-4521-b729-388f754418de_UBs6vZANU.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/118/img_ba35579a-a09f-4bab-9a7a-2c7cb1e033cb_k_bfJlj_D.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/118/img_f68ac9bd-2f9d-4cae-b155-52ec3ea5b42b_O-5ML487y.jpg?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/118/img_f1cb17d3-df1c-4820-8fdf-1559f3e9ddf4_RATxdPIN_.png?tr=ar-3-2%2Cw-1200',
            'https://media.wemeet.nl/gallery/organisation/118/img_8e5d00d9-a2fc-4f8c-a28b-d3dd5de5d817_ATkMncpcc.jpg?tr=ar-3-2%2Cw-1200',
        ],
        venue_spaces: [
            {
                space_id: 259,
                __typename: 'Space'
            }
        ],
        min_price: 345,
        __typename: 'Venue'
    }
];
export default defineComponent({
    components: {
        VenuesList,
        PagePagination
    },
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup(_, context) {
        const { root } = context;
        const page = computed({
            get() {
                return +root.$route.query.page;
            },
            set(page) {
                if (page !== +root.$route.query.page) {
                    globalThis.$router.replace({ query: { page } });
                }
            }
        });
        // Setting first page of venues without query params
        if (!page.value)
            page.value = 1;
        // Re-fetch new list of venues for the current page
        watch(page, (curr, prev) => {
            if (prev && curr !== prev) {
                if (!curr)
                    page.value = 1;
            }
        }, { immediate: true });
        const list = computed(() => mockedVenues);
        const pagesCount = computed(() => 2);
        const goSearch = () => {
            globalThis.$router.push({
                path: useGetLocalizedPath(SearchRoutes.SEARCH),
                query: ''
            });
        };
        return {
            list,
            page,
            pagesCount,
            goSearch
        };
    }
});
