import { defineComponent } from '@vue/composition-api';
const blocks = [
    {
        img: 'handshake',
        name: 'connect'
    },
    {
        img: 'laptop',
        name: 'in_charge_of_account'
    },
    {
        img: 'calendar-image',
        name: 'no_double_booking'
    },
    {
        img: 'alarm-clock',
        name: 'book_directly'
    }
];
export default defineComponent({
    setup() {
        return {
            blocks
        };
    }
});
