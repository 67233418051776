import { defineComponent, ref, watch } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
const steps = [
    {
        to: '',
        title: 'geo_pages.breadcrumbs.home',
        disabled: false,
        isStatic: true
    },
    {
        to: 'organisation',
        title: 'company_pages.breadcrumbs.organisation',
        disabled: true,
        isStatic: true
    },
    {
        to: '#',
        title: 'company_pages.breadcrumbs.all_venues',
        disabled: true,
        isStatic: true
    }
];
export default defineComponent({
    components: {
        Breadcrumbs
    },
    setup(_, context) {
        const { root } = context;
        let links = ref(steps);
        watch(() => root.$route, () => {
            const { hash, slug } = root.$route.params;
            const { location } = root.$route.query;
            if (slug) {
                links.value[1].to = `organisation/${hash}/${slug}`;
                links.value[1].title = slug
                    .replaceAll('-amp-', ' & ')
                    .replaceAll('-dot-', '.')
                    .replaceAll('-', ' ');
                links.value[1].disabled = false;
                links.value[1].isStatic = false;
            }
            else {
                links.value[1].disabled = true;
                links.value[1].isStatic = true;
            }
            if (location && location !== 'all') {
                links.value[2].to = `organisation/${hash}/${slug}?location=${location}`;
                links.value[2].title = location;
                links.value[2].disabled = false;
                links.value[2].isStatic = false;
            }
            else {
                links.value[2].disabled = true;
                links.value[2].title = globalThis.$i18n.t('company_pages.breadcrumbs.all_venues');
                links.value[2].isStatic = false;
            }
        }, { immediate: true });
        return {
            links
        };
    }
});
