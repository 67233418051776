import { defineComponent, computed } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    props: {
        value: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { root } = context;
        const image = computed(() => {
            if (!props.value?.Image?.filename)
                return '';
            const imageService = 'https://img2.storyblok.com/';
            const path = props.value.Image.filename.replace(imageService, '');
            return (imageService +
                (root.$mq && root.$mq.desktop ? '1600x350/smart/' : '330x172/smart/') +
                path);
        });
        return { image, useGetLocalizedPath };
    }
});
