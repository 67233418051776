//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
import Popover from '@/components/Popover';

export default defineComponent({
  components: {
    Popover
  },
  props: {
    title: {
      type: [String, Number],
      default: 'title'
    },
    border: {
      type: Boolean,
      default: true
    },
    description: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    },
    iconSrc: {
      type: String,
      default: ''
    },
    rightLabel: {
      type: String,
      default: ''
    },
    hasPriceNotSet: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: undefined
    },
    newNotificationsCounter: {
      type: Number,
      default: 0
    },
    newChatMessagesCounter: {
      type: Number,
      default: 0
    }
  }
});
