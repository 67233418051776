import LocalDB from '@/shared/services/db';
const SET_ORGANISATION = (state, organisation) => {
    state.organisation = organisation;
};
const SET_VENUE = (state, venue) => {
    const venueIndex = state.venues.findIndex((venueItem) => venueItem.id === venue.id);
    if (venueIndex >= 0) {
        state.venues[venueIndex] = venue;
    }
    else {
        state.venues.push(venue);
    }
};
const SET_VENUES_LIST = (state, venues) => {
    state.venues = venues;
};
const REMOVE_VENUE = (state, id) => {
    state.venues.splice(state.venues.findIndex((venue) => venue.id === id), 1);
};
const SET_VENUE_DETAILS = (state, details) => {
    LocalDB.instance().setItem(LocalDB.FIELDS.VENUE_DETAILS, JSON.stringify(details));
    state.venueDetails = details;
};
const SET_VENUE_FEATURES = (state, features) => {
    state.venueFeatures = features;
};
const SET_SELECTED_TAGS = (state, tags) => {
    LocalDB.instance().setItem(LocalDB.FIELDS.VENUE_TAGS, JSON.stringify(tags));
    state.venueTags = tags;
};
const SET_VENUE_TAGS = (state, tags) => {
    state.venueTags = tags;
};
const SET_VENUE_FACILITIES = (state, facilities) => {
    state.venueFacilities = facilities;
};
const SET_VENUE_CATERING = (state, catering) => {
    state.venueCatering = catering;
};
const SET_VENUE_SERVICES = (state, payload) => {
    LocalDB.instance().setItem(LocalDB.FIELDS.VENUE_SERVICES[payload.model], JSON.stringify(payload.data));
    state.venueServices[payload.model] = payload.data;
};
const SET_VENUE_WIZARD = (state, step) => {
    state.venueWizard = step;
};
const CLEAN_VENUE_DATA = (state) => {
    state.venueWizard = 'MAIN';
    state.venueDetails = null;
    state.venueServices = {};
    state.venueTags = [];
    const localStore = LocalDB.instance();
    localStore.removeItem(LocalDB.FIELDS.VENUE_DETAILS);
    localStore.removeItem(LocalDB.FIELDS.VENUE_SERVICES.facilities);
    localStore.removeItem(LocalDB.FIELDS.VENUE_SERVICES.catering);
    localStore.removeItem(LocalDB.FIELDS.VENUE_TAGS);
};
const CLEAN_VENUE_STATE_DATA = (state) => {
    state.venueDetails = null;
    state.venueServices = {};
    state.venueTags = [];
};
const SET_VENUE_ID = (state, id) => {
    LocalDB.instance().setItem(LocalDB.FIELDS.VENUE_ID, JSON.stringify(id));
    state.venueId = id;
};
const SET_SPACE_DETAILS = (state, details) => {
    LocalDB.instance().setItem(LocalDB.FIELDS.SPACE_DETAILS, JSON.stringify(details));
    state.spaceDetails = details;
};
const SET_SPACE_CONFIGURATIONS = (state, configs) => {
    LocalDB.instance().setItem(LocalDB.FIELDS.SPACE_CONFIGURATIONS, JSON.stringify(configs));
    state.spaceConfigurations = configs;
};
const SET_SPACE_BOOKING_RULES = (state, rules) => {
    LocalDB.instance().setItem(LocalDB.FIELDS.SPACE_BOOKING_RULES, JSON.stringify(rules));
    state.spaceBookingRules = rules;
};
const SET_SPACE_FACILITIES = (state, facilities) => {
    LocalDB.instance().setItem(LocalDB.FIELDS.SPACE_FACILITIES, JSON.stringify(facilities));
    state.spaceFacilities = facilities;
    state.spaceServices = facilities;
};
const SET_SPACE_PRICING = (state, pricing) => {
    LocalDB.instance().setItem(LocalDB.FIELDS.SPACE_PRICING, JSON.stringify(pricing));
    state.spacePricing = pricing;
};
const SET_SPACE_STATIC_FEATURES = (state, data) => {
    state.spaceStaticFeatures = data;
};
const SET_SPACE_STATIC_CONFIGURATIONS = (state, data) => {
    state.spaceStaticConfigurations = data;
};
const SET_SPACE_STATIC_CATEGORIES = (state, data) => {
    state.spaceStaticCategories = data;
};
const SET_SPACE_SERVICES = (state, data) => {
    state.spaceServices = data;
};
const SET_SPACE_WIZARD = (state, step) => {
    state.spaceWizard = step;
};
const CLEAN_SPACE_DATA = (state) => {
    state.spaceDetails = null;
    state.spaceConfigurations = null;
    state.spaceBookingRules = null;
    state.spaceFacilities = null;
    state.spaceServices = null;
    state.spacePricing = null;
    const localStore = LocalDB.instance();
    localStore.removeItem(LocalDB.FIELDS.SPACE_DETAILS);
    localStore.removeItem(LocalDB.FIELDS.SPACE_CONFIGURATIONS);
    localStore.removeItem(LocalDB.FIELDS.SPACE_BOOKING_RULES);
    localStore.removeItem(LocalDB.FIELDS.SPACE_FACILITIES);
    localStore.removeItem(LocalDB.FIELDS.SPACE_PRICING);
};
export default {
    SET_ORGANISATION,
    SET_VENUE,
    SET_VENUES_LIST,
    REMOVE_VENUE,
    SET_VENUE_DETAILS,
    SET_VENUE_FEATURES,
    SET_VENUE_FACILITIES,
    SET_VENUE_CATERING,
    SET_VENUE_SERVICES,
    SET_VENUE_WIZARD,
    SET_VENUE_TAGS,
    SET_SELECTED_TAGS,
    CLEAN_VENUE_DATA,
    SET_VENUE_ID,
    SET_SPACE_DETAILS,
    SET_SPACE_CONFIGURATIONS,
    SET_SPACE_BOOKING_RULES,
    SET_SPACE_FACILITIES,
    SET_SPACE_PRICING,
    SET_SPACE_STATIC_FEATURES,
    SET_SPACE_STATIC_CONFIGURATIONS,
    SET_SPACE_STATIC_CATEGORIES,
    SET_SPACE_SERVICES,
    SET_SPACE_WIZARD,
    CLEAN_SPACE_DATA,
    CLEAN_VENUE_STATE_DATA
};
