import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const buttonStyle = computed(() => {
            const st = {};
            const { button_color, button_text_color, button_width } = props.blok;
            if (button_text_color)
                st.color = button_text_color.color;
            if (button_color)
                st.background = button_color.color;
            if (button_width)
                st.width = `${button_width}px`;
            return st;
        });
        const icon = computed(() => {
            const { icon } = props.blok;
            if (!icon)
                return;
            return icon.icon.split('-').slice(1).join('-');
        });
        const buttonAction = () => {
            const { button_link, button_type } = props.blok;
            if (button_type === 'email') {
                window.location.href = `mailto:${button_link}`;
            }
            else if (button_type === 'link') {
                window.location.href = button_link;
            }
        };
        return {
            buttonStyle,
            icon,
            buttonAction
        };
    }
});
