var LOADER_ACTIONS;
(function (LOADER_ACTIONS) {
    LOADER_ACTIONS["SET_LOADER_STATE"] = "SET_LOADER_STATE";
})(LOADER_ACTIONS || (LOADER_ACTIONS = {}));
const loaderStore = {
    namespaced: true,
    state: () => ({
        loadingState: 0
    }),
    mutations: {
        [LOADER_ACTIONS.SET_LOADER_STATE]: (state, loadingState) => {
            state.loadingState = state.loadingState + loadingState;
        }
    },
    actions: {
        setLoaderState: async ({ commit }, payload) => {
            commit(LOADER_ACTIONS.SET_LOADER_STATE, payload);
        }
    },
    getters: {
        loadingState: state => !!state.loadingState
    }
};
export default loaderStore;
