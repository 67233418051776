import { defineComponent } from '@vue/composition-api';
import Accordion from '@/components/Accordion.vue';
export default defineComponent({
    components: {
        Accordion
    },
    props: {
        blok: {
            type: Object,
            default: null
        }
    }
});
