import { computed } from '@vue/composition-api';
import { AuthPopups, AUTH_SESSION_STORAGE_KEYS } from '@/Authentication/authentication.const';
const preDefineUserRoleState = (role) => {
    if (process.browser && role) {
        window.sessionStorage.setItem(AUTH_SESSION_STORAGE_KEYS.ACTION_FLOW_ROLE_TYPE, role);
    }
};
export const useUserDetailsCompleted = (root) => {
    const user = computed(() => globalThis.$store.getters['$_app/user']);
    return {
        performAccountFlowAction: computed(() => {
            if (!user.value) {
                return (accountRole) => {
                    preDefineUserRoleState(accountRole);
                    root.$router.replace({
                        query: {
                            ...root.$route.query,
                            authPopup: AuthPopups.LOGIN
                        }
                    });
                };
            }
            if (!user.value.phone) {
                return (accountRole) => {
                    preDefineUserRoleState(accountRole);
                    root.$router.replace({
                        query: {
                            ...root.$route.query,
                            authPopup: AuthPopups.COMPLETE_ACCOUNT
                        }
                    });
                };
            }
            return null;
        })
    };
};
