import actions from '@/shared/store-modules/vendor/actions';
import getters from '@/shared/store-modules/vendor/getters';
import mutations from '@/shared/store-modules/vendor/mutations';
const state = () => ({
    organisation: null,
    venues: [],
    venueId: null,
    spaceDetails: {
        venueId: null
    },
    spaceConfigurations: null,
    spaceBookingRules: null,
    spaceFacilities: null,
    spacePricing: null,
    spaceStaticFeatures: [],
    spaceStaticConfigurations: [],
    spaceStaticCategories: [],
    spaceServices: {},
    venueDetails: null,
    venueFeatures: [],
    venueFacilities: {
        presentFacilities: [],
        onDemandFacilities: []
    },
    venueTags: [],
    venueCatering: [],
    venueServices: {},
    venueWizard: 'MAIN',
    spaceWizard: 'MAIN'
});
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
