import i18n from '@/i18n/i18n-setup';
export const currencyFilter = (value, currency, options, placeholder) => {
    if (value === undefined) {
        return placeholder;
    }
    const formatter = new Intl.NumberFormat(i18n.locale, {
        currency: currency ?? 'EUR',
        style: 'currency',
        ...(options ?? {})
    });
    return value ? formatter.format(value) : i18n.t('common.free').toString();
};
