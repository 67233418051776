import LocalDB from '@/shared/services/db';
const SET_USER = (state, user) => {
    state.user = user;
};
const UPDATE_USER = (state, user) => {
    Object.keys(user).forEach((key) => {
        state.user[key] = user[key];
    });
};
const SET_CHAT = (state, chat) => {
    state.chat = chat;
};
const SET_TOKENS = (state, data) => {
    if (data.access_token && data.refresh_token) {
        LocalDB.instance().setItem(LocalDB.FIELDS.ACCESS_TOKEN, data.access_token);
        LocalDB.instance().setItem(LocalDB.FIELDS.REFRESH_TOKEN, data.refresh_token);
    }
};
const CLEAR_TOKENS = () => {
    LocalDB.instance().setItem(LocalDB.FIELDS.ACCESS_TOKEN, '');
    LocalDB.instance().setItem(LocalDB.FIELDS.REFRESH_TOKEN, '');
};
const TOGGLE_USER_LOGGED = (state, flag) => {
    state.userLoggedIn = flag;
    LocalDB.instance().setCookie(LocalDB.FIELDS.LOGGED_IN, flag);
};
export default {
    SET_USER,
    SET_CHAT,
    UPDATE_USER,
    SET_TOKENS,
    CLEAR_TOKENS,
    TOGGLE_USER_LOGGED
};
