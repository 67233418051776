import { isAuthorized } from '@/Authentication/utils/token';
import { AuthPopups } from '@/Authentication/authentication.const';
import multiguard from 'vue-router-multiguard';
class RouteGuard {
    get authorized() {
        return (to, from, next) => {
            /** TODO add authentication to the server and remove is server */
            if (isAuthorized() || !process.browser) {
                next();
            }
            else {
                const { query, path, params } = from;
                if (path === to.path) {
                    next({
                        path: '/',
                        query: { authPopup: AuthPopups.LOGIN }
                    });
                }
                else {
                    next({
                        path,
                        params,
                        query: {
                            ...query,
                            authPopup: AuthPopups.LOGIN
                        }
                    });
                }
            }
        };
    }
    roleAccess(roles) {
        return multiguard([
            this.authorized,
            async (_to, _from, next) => {
                /** TODO: proper handle server auth */
                if (!process.browser) {
                    return next();
                }
                const user = await globalThis.$store.dispatch('$_app/refetchUser');
                if (roles === 'admin') {
                    const isUserAdmin = user?.is_admin;
                    return isUserAdmin ? next() : next('/not-found');
                }
                const role = user?.account_state;
                if (Array.isArray(roles) ? roles.includes(role) : roles === role) {
                    next();
                }
                else {
                    next('/not-found');
                }
            }
        ]);
    }
}
export default new RouteGuard();
