import { VenueRoutes } from '@/Venue/venue.const';
import VenueHeader from '@/Venue/components/VenueHeader.vue';
import SearchHeader from '@/Search/components/SearchHeader.vue';
/** Components */
const EmptyLayout = () => import(
/* webpackChunkName: "venue.route" */ '@/layouts/EmptyLayout.vue');
const Venue = () => import(/* webpackChunkName: "venue.route" */ '@/Venue/Venue.vue');
export const venueRoutes = [
    {
        path: `/${VenueRoutes.VENUE}/:hash/:name`,
        localePath: `${VenueRoutes.VENUE}/:hash/:name`,
        meta: {
            breadcrumbs: VenueHeader,
            header: SearchHeader
        },
        component: EmptyLayout,
        children: [
            {
                path: '/',
                component: Venue
            }
        ]
    }
];
