import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { SearchRoutes } from '@/Search/search.const';
export default defineComponent({
    setup(_, context) {
        const { root } = context;
        const isSearchDisabled = computed(() => root.$route.meta?.withoutSearch);
        const userInput = ref(root.$route.query.search || '');
        /**
         * On Enter key press, redirect to the search page with new user input
         */
        const onEnter = () => {
            const { ...appliedFilters } = root.$route.path === useGetLocalizedPath(SearchRoutes.SEARCH)
                ? root.$route.query
                : {};
            appliedFilters.search = userInput.value || undefined;
            appliedFilters.lat = undefined;
            appliedFilters.lng = undefined;
            const query = {
                ...appliedFilters
            };
            globalThis.$router.push({
                path: useGetLocalizedPath(SearchRoutes.SEARCH),
                query
            });
        };
        watch(() => root.$route.query.search, (newValue) => {
            userInput.value = newValue || '';
        });
        return {
            isSearchDisabled,
            userInput,
            onEnter
        };
    }
});
