import axios from 'axios';
import { errorHandler } from '@/util/errors';
const client = axios.create({
    baseURL: process.env.VUE_APP_PUBLIC_API_URL
});
client.interceptors.response.use(res => res, error => {
    errorHandler(error?.response?.data, error?.request);
    return Promise.reject(error);
});
export default client;
