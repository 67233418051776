import { defineComponent, computed } from '@vue/composition-api';
import { useGetSpacesBySlugQuery, SpaceBookingType } from '@/generated-types/graphql.types';
import { SpaceRoutes } from '@/Space/space.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { calculatePriceWithTaxAndVAT } from '@/util/utils';
export default defineComponent({
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const slug = computed(() => props.blok.space_card);
        const { result } = useGetSpacesBySlugQuery({ slugs: [slug.value] });
        const space = computed(() => result?.value?.get_spaces_by_slug[0]);
        return {
            space,
            SpaceBookingType,
            SpaceRoutes,
            calculatePriceWithTaxAndVAT,
            useGetLocalizedPath
        };
    }
});
