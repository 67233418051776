import { SearchRoutes } from '@/Search/search.const';
import SearchHeader from '@/Search/components/SearchHeader.vue';
import SearchBreadcrumbs from '@/Search/components/SearchBreadcrumbs.vue';
/** Routes */
const Search = () => import(/* webpackChunkName: "search.route" */ '@/Search/Search.vue');
export const searchRoutes = [
    {
        name: 'search',
        path: `/${SearchRoutes.SEARCH}`,
        localePath: `${SearchRoutes.SEARCH}`,
        component: Search,
        meta: {
            header: SearchHeader,
            breadcrumbs: SearchBreadcrumbs,
            noFrame: true,
            noFooter: true
        }
    }
];
