import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    props: {
        value: {
            type: Number,
            default: 1
        },
        pages: {
            type: Number,
            default: 1
        },
        pagesToShow: {
            type: Number,
            default: 3
        }
    },
    setup(props, context) {
        /**
         * Creating an array of pages based on prop @pagesToShow
         */
        const filteredPages = computed(() => {
            let diff = props.pagesToShow / 2;
            let pages = [...Array(props.pages + 1).keys()].slice(2, -1);
            if (pages.length > props.pagesToShow) {
                let start = props.value - pages[0];
                let end = props.value - pages[pages.length - 1];
                let prePages = [];
                if (start < diff) {
                    prePages = pages.slice(0, props.pagesToShow);
                }
                else if (end >= -diff) {
                    prePages = pages.slice(-props.pagesToShow);
                }
                else {
                    prePages = pages.filter(page => {
                        let diffPage = props.value - page;
                        return diffPage < 0 ? Math.abs(diffPage) <= diff : diffPage < diff;
                    });
                }
                if (pages[pages.length - 1] - prePages[prePages.length - 1] === 1) {
                    prePages.push(pages[pages.length - 1]);
                }
                if (prePages[0] === 3) {
                    prePages.unshift(2);
                }
                return prePages;
            }
            return pages;
        });
        // Previous page button click event
        const prevPage = () => {
            if (props.value > 1)
                context.emit('input', props.value - 1);
        };
        // Next page button click event
        const nextPage = () => {
            if (props.value < props.pages)
                context.emit('input', props.value + 1);
        };
        // Page button click event
        const selectPage = (page) => {
            context.emit('input', page);
        };
        return {
            prevPage,
            nextPage,
            selectPage,
            filteredPages
        };
    }
});
