import { ref, watch } from '@vue/composition-api';
export default function useImageResize(targetImage, options) {
    const imageHeight = ref(options?.defaultHeight || 0);
    const imageWidth = ref(options?.defaultWidth || 0);
    watch(() => targetImage.value, el => {
        if (!options?.resizeHeight)
            return;
        if (el) {
            // Add load event listener to the image
            el.onload = () => {
                // Set the height once the image has loaded
                imageHeight.value = el.height;
            };
            // If the image is already loaded (e.g., cached), set the height immediately
            if (el.complete && el.naturalHeight !== 0) {
                imageHeight.value = el.height;
            }
        }
    }, { immediate: true });
    /**
     * @description Adjust image width and height on screen size change.
     */
    const onScreenResize = () => {
        if (window.innerWidth < options.beginResizingAt) {
            imageWidth.value = window.innerWidth - options.widthModificator;
        }
        if (options?.resizeHeight &&
            targetImage.value &&
            targetImage.value.height) {
            imageHeight.value = targetImage.value.height;
        }
    };
    return {
        imageHeight,
        imageWidth,
        onScreenResize
    };
}
