import { venueFeatureKeys, spaceFeatureKeys, defaultImages } from '@/shared/store-modules/venue/const';
import { flatten, map } from 'lodash';
const searchKey = (state) => state.filter.searchKey;
const venues = (state) => state.venues;
const spaces = (state) => flatten(map(state.venues, (venue) => venue.spaces));
const activities = (state) => state.activities;
const filteredVenues = (state) => venues(state);
const filteredSpaces = (state) => spaces(state);
const filter = (state) => {
    const filterCopy = { ...state.filter };
    const allPrices = filteredSpaces(state)
        .map((space) => space.price)
        .sort((a, b) => a - b);
    const prices = [];
    const counts = [];
    let prev = -1;
    for (let i = 0; i < allPrices.length; i += 1) {
        if (allPrices[i] !== prev) {
            prices.push(allPrices[i]);
            counts.push(1);
        }
        else {
            counts[counts.length - 1] += 1;
        }
        prev = allPrices[i];
    }
    filterCopy.price.prices = prices;
    filterCopy.price.counts = counts;
    if (filterCopy.price.value[1] === 0) {
        filterCopy.price.value[1] = prices.length - 1;
    }
    return filterCopy;
};
const availableFeatures = (state) => {
    const features = [];
    const filtered = filteredVenues(state);
    venueFeatureKeys.forEach((key) => {
        if (filtered.some((venue) => venue.features[key] === true)) {
            features.push(key);
        }
    });
    spaceFeatureKeys.forEach((key) => {
        if (filtered.some((venue) => venue.spaces.some((space) => space.features[key] === true))) {
            features.push(key);
        }
    });
    return features;
};
const mapAPI = (state) => state.map_data.mapAPI;
const getSpaceById = (state) => (id) => filteredSpaces(state).find((space) => space.id === id);
const spaceImagesById = (_state) => (_id) => defaultImages;
const venueImagesById = (_state) => (_id) => defaultImages;
export default {
    activities,
    filter,
    searchKey,
    filteredSpaces,
    venues,
    spaces,
    filteredVenues,
    availableFeatures,
    mapAPI,
    getSpaceById,
    spaceImagesById,
    venueImagesById
};
