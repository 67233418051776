import APIClient from '@/shared/services/api';
const register = (form) => APIClient.postData('account/registration/', form, false);
const login = (form) => APIClient.postData('account/auth-token/', form, false);
const accessToken = (token) => APIClient.getData(`account/access-token/${token}/`, false);
const userInfo = () => APIClient.getData('account/');
const facebook = (token) => APIClient.postData('account/oauth/facebook/', { token }, false);
const google = (token) => APIClient.postData('account/oauth/google/', { token }, false);
const linkedin = (token) => APIClient.postData('account/oauth/linkedin/', { token }, false);
const resendVerification = (method) => APIClient.postData('account/resend-verification/', { method }, true);
const verifyEmail = (token) => APIClient.getData(`account/verify-email/${token}/`, false);
const verifyPhone = (code) => APIClient.getData(`account/verify-phone/${code}/`, false);
const updateProfile = (data, disableConfirmation = false) => {
    const url = disableConfirmation
        ? 'account/profile/?registration=true'
        : 'account/';
    return APIClient.patchData(url, data, true);
};
export default {
    register,
    login,
    accessToken,
    userInfo,
    facebook,
    google,
    linkedin,
    verifyEmail,
    verifyPhone,
    updateProfile,
    resendVerification
};
