import { defineComponent, onMounted, reactive, ref, onBeforeUnmount, computed, watchEffect, watch, onUnmounted } from '@vue/composition-api';
import { Toast } from 'vuex-toast';
import EventBus from '@/shared/services/eventBus';
import loadHelper from '@/quality/load-helper';
import useLanguageManager from '@/i18n/languageManager';
import { PortalTarget } from 'portal-vue';
import { useMeta } from '@/shared/composables/useMeta';
import { useAuthorized } from '@/shared/composables/useAuthorized';
import { StreamChat } from 'stream-chat';
export const SITE_URL = process.env.VUE_APP_SITE_URL;
export const STORYBLOK_PUBLIC_KEY = process.env.VUE_APP_STORYBLOK_PUBLIC_KEY;
export const STORYBLOK_DRAFT_KEY = process.env.VUE_APP_STORYBLOK_DRAFT_KEY;
export default defineComponent({
    components: {
        PortalTarget,
        Toast
    },
    setup(_, context) {
        const { root } = context;
        const showServiceWorkerPrompt = ref(false);
        const initialCountDown = 10;
        const countdown = ref(initialCountDown); // seconds
        const isAuthorized = useAuthorized();
        const chat = ref(null);
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const token = computed(() => globalThis.$store?.state.authentication.accessToken || undefined);
        watch(() => token.value, (newToken, oldToken) => {
            if (newToken &&
                newToken !== oldToken &&
                oldToken === undefined &&
                !user.value) {
                globalThis.$store.dispatch('$_app/refetchUser');
            }
        }, { immediate: true });
        const useChat = () => {
            const chatAPIToken = process.env.VUE_APP_PUBLIC_STREAM_CHAT_TOKEN;
            const initChat = async () => {
                const newChatInstance = new StreamChat(chatAPIToken);
                await newChatInstance?.connectUser({
                    id: user.value.chat_user_id,
                    name: user.value.first_name || '',
                    image: user.value.avatar_image || ''
                }, user.value.chat_token);
                chat.value = newChatInstance;
            };
            initChat();
        };
        watch(() => [isAuthorized.value, user.value], () => {
            if (isAuthorized.value && user.value && user.value.chat_token) {
                useChat();
            }
            if (!isAuthorized.value && chat.value) {
                chat.value.disconnectUser(5000);
            }
        }, { immediate: true });
        watch(() => chat.value, () => {
            if (chat.value) {
                globalThis.$store.commit('$_app/SET_CHAT', chat.value);
            }
        }, { immediate: true });
        onUnmounted(() => {
            if (chat.value) {
                chat.value.disconnectUser(5000);
            }
        });
        const swipeHandler = async (direction) => {
            let target = document.querySelectorAll('.toast-message');
            if (direction === 'left') {
                target.forEach(item => {
                    item.classList.add('hideLeft');
                });
            }
            if (direction === 'right') {
                target.forEach(item => {
                    item.classList.add('hideRight');
                });
            }
            setTimeout(function () {
                target.forEach(item => {
                    item.remove();
                }, 1000);
            });
        };
        // Service Worker
        if (globalThis.$workbox) {
            globalThis.$workbox.addEventListener('waiting', () => {
                if (globalThis.Cypress)
                    return;
                showServiceWorkerPrompt.value = true;
            });
        }
        const updateServiceWorker = async () => {
            if (globalThis.Cypress)
                return;
            showServiceWorkerPrompt.value = false;
            await globalThis.$workbox.messageSW({ type: 'SKIP_WAITING' });
        };
        const startCountdown = async () => {
            let intervalId = setInterval(() => {
                if (countdown.value > 0) {
                    countdown.value -= 1;
                }
                else {
                    clearInterval(intervalId);
                    updateServiceWorker();
                    countdown.value = initialCountDown;
                }
            }, 1000); // Decrease countdown every second
        };
        watch(() => showServiceWorkerPrompt.value, async () => {
            if (showServiceWorkerPrompt.value) {
                await startCountdown();
            }
        }, { immediate: true });
        EventBus.$on('appMounted', () => {
            console.log('[App has been mounted]: ', new Date());
        });
        EventBus.$on('userRoleSwitch', isBooker => {
            localStorage.setItem('user_role', isBooker ? 'Booker' : 'Vendor');
        });
        // Here we invoke the language Manager. All locale switching logic depends on this instance.
        const languageManager = reactive(useLanguageManager(root));
        const loading = computed(() => {
            // Prevent loader from running
            if (globalThis.Cypress)
                return;
            return globalThis.$store.getters['loader/loadingState'];
        });
        const storyBlokTokenLoaded = computed(() => STORYBLOK_DRAFT_KEY);
        watchEffect(() => {
            if (storyBlokTokenLoaded.value && root.$storyblok) {
                root.$storyblok.init({
                    accessToken: storyBlokTokenLoaded.value,
                    customParent: `${SITE_URL}`
                });
            }
        });
        const updateMeta = () => {
            useMeta({
                title: root.$i18n.t('meta.general.title_tag'),
                description: root.$i18n.t('meta.general.description_tag'),
                url: `${SITE_URL}${window.location.pathname}`,
                imageUrl: 'https://media.wemeet.nl/gallery/assets/home/logo_8_new_Y9FP-Fb5m.png?tr=h-200',
                noIndex: !!SITE_URL?.includes('zone'),
                keywords: root.$i18n
                    .t('meta.general.meta_keywords', {
                    returnObjects: true
                })
                    .map((keyword) => keyword)
                    .join(',')
            });
        };
        watch(() => root.$route, (newRoute, oldRoute) => {
            if (newRoute.path !== oldRoute.path &&
                ['/', '/en'].includes(newRoute.path)) {
                updateMeta();
            }
        });
        onMounted(() => {
            globalThis.$store.dispatch('initStore');
            EventBus.$emit('appMounted');
            window.addEventListener('beforeunload', () => {
                EventBus.$emit('unloading');
            }, true);
            if (process.env.ENABLE_SUPPORT_MODAL === 'enabled') {
                loadHelper();
            }
            // https://docs.prerender.io/article/11-best-practices
            document.onreadystatechange = () => {
                if (document.readyState == 'complete') {
                    window.prerenderReady = true;
                }
            };
            globalThis.$gtm = root.$gtm;
            updateMeta();
        });
        onBeforeUnmount(() => {
            EventBus.$off('appMounted');
            EventBus.$off('unloading');
            EventBus.$off('userRoleSwitch');
            languageManager.deconstruct();
        });
        return {
            countdown,
            initialCountDown,
            isAuthorized,
            loading,
            showServiceWorkerPrompt,
            swipeHandler,
            updateServiceWorker
        };
    }
    // watch: {
    //   $route(to, from) {
    //
    //     // Triggers on page changes and on homepage entrances
    //     if ( (to.path !== from.path) || (to.path == '/' && to.name == 'main' && from.name == 'main')) {
    //       try {
    //         // @todo this is wrong, sending bad page title
    //         //globalThis.$gtm.trackView(document.title, location.href);
    //       } catch (e) {
    //         console.error('Page view error');
    //         console.error(e);
    //       }
    //     }
    //   }
    // },
});
