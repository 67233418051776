import { defineComponent, computed } from '@vue/composition-api';
import { useGetSpacesBySlugQuery, SpaceBookingType } from '@/generated-types/graphql.types';
import { SpaceRoutes } from '@/Space/space.const';
import { calculatePriceWithTaxAndVAT } from '@/util/utils';
export default defineComponent({
    props: {
        articles: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const slugs = props.articles.map((article) => article.space_id);
        const { result } = useGetSpacesBySlugQuery({
            slugs: slugs
        });
        const slides = computed(() => result?.value?.get_spaces_by_slug);
        return {
            slides,
            SpaceBookingType,
            SpaceRoutes,
            calculatePriceWithTaxAndVAT
        };
    }
});
