import { defineComponent, ref, watch, computed } from '@vue/composition-api';
import vClickOutside from 'v-click-outside';
export default defineComponent({
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        dropdownData: {
            type: Array,
            required: true
        },
        value: {
            type: [String, Number, Object, Boolean],
            default: null
        },
        label: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            default: ''
        }
    },
    setup(props, { emit }) {
        const open = ref(false);
        const triggerSelect = () => {
            if (props.disabled)
                return;
            open.value = !open.value;
        };
        watch(() => open.value, val => {
            if (val) {
                emit('onSelect');
            }
        });
        const selected = computed(() => props.dropdownData.find(option => option.value === props.value));
        return {
            open,
            selected,
            triggerSelect
        };
    }
});
