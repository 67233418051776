import Popover from '@/components/Popover.vue';
import TimeFilter from '@/Search/components/TimeFilter.vue';
import FilterPopover from '@/Search/components/FilterPopover.vue';
import FilterForm from '@/Search/components/FilterForm.vue';
import { defineComponent, reactive, ref, computed } from '@vue/composition-api';
import { formatTimeRange } from '@/Search/search.utils';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { SearchRoutes } from '@/Search/search.const';
import vClickOutside from 'v-click-outside';
export default defineComponent({
    components: {
        TimeFilter,
        FilterForm,
        FilterPopover,
        Popover
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    setup() {
        const userInput = ref('');
        const filters = reactive({
            slotFrom: null,
            slotTo: null,
            isSocial: null,
            attendees: null
        });
        const socialFilterIcon = ref(null);
        const socialFilterPopoverVisible = ref(false);
        const socialPopoverLeft = ref(0);
        const socialPopoverTop = ref(0);
        /**
         * @description
         * When the social filter icon is clicked, the description popover is shown.
         * The popover is positioned relative to the icon.
         */
        const onSocialIconClick = () => {
            if (socialFilterIcon.value) {
                socialPopoverTop.value = socialFilterIcon.value.offsetTop + 12;
                socialPopoverLeft.value = socialFilterIcon.value.offsetLeft + 6;
                socialFilterPopoverVisible.value = true;
            }
        };
        const closePopover = () => {
            socialFilterPopoverVisible.value = false;
        };
        const time = ref(false);
        const closeTime = () => (time.value = false);
        const timeFilterLabel = computed(() => formatTimeRange({ slotFrom: filters.slotFrom, slotTo: filters.slotTo }));
        /**
         * On Enter key press, redirect to the search page with new user input
         */
        const onEnter = () => {
            const query = {
                slotFrom: filters.slotFrom,
                slotTo: filters.slotTo,
                isSocial: filters.isSocial,
                attendees: filters.attendees || undefined,
                search: userInput.value || undefined
            };
            globalThis.$router.push({
                path: useGetLocalizedPath(SearchRoutes.SEARCH),
                query
            });
        };
        return {
            filters,
            socialFilterIcon,
            socialFilterPopoverVisible,
            socialPopoverLeft,
            socialPopoverTop,
            time,
            timeFilterLabel,
            userInput,
            closePopover,
            closeTime,
            onEnter,
            onSocialIconClick,
            useGetLocalizedPath
        };
    }
});
