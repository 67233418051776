import { defineComponent, ref, watch, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EventBus from '@/shared/services/eventBus';
import { OrganisationRoutes } from '@/Organisation/organisation.const';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
const steps = [
    {
        to: '',
        title: 'geo_pages.breadcrumbs.home',
        disabled: false,
        isStatic: true
    },
    {
        to: 'locations',
        title: 'geo_pages.breadcrumbs.locations',
        disabled: false,
        isStatic: true
    },
    {
        to: 'locations/:region',
        title: 'geo_pages.breadcrumbs.region',
        disabled: false,
        isStatic: true
    },
    {
        to: 'locations/:region/:city',
        title: 'geo_pages.breadcrumbs.city',
        disabled: false,
        isStatic: true
    },
    {
        to: '#',
        title: 'geo_pages.breadcrumbs.venue',
        disabled: true,
        isStatic: true
    }
];
export default defineComponent({
    components: {
        Breadcrumbs
    },
    setup() {
        const venue = ref(null);
        let links = ref(steps);
        watch(() => venue.value, () => {
            if (venue.value) {
                // Region
                links.value[2].title = venue.value.venue_geo.region.seo_entity_name;
                links.value[2].to = `${GeoPagesRoutes.CITIES}${venue.value.venue_geo.region.seo_entity_slug}`;
                links.value[2].isStatic = false;
                // City
                links.value[3].title = venue.value.venue_geo.city.seo_entity_name;
                links.value[3].to = `${GeoPagesRoutes.CITIES}${venue.value.venue_geo.city.seo_entity_slug}`;
                links.value[3].isStatic = false;
                // Organisation
                if (venue.value.collection?.has_public_page &&
                    links.value[4].to === '#') {
                    const orgListItem = {
                        to: `${OrganisationRoutes.ORGANISATION}${venue.value.collection.slug}`,
                        title: venue.value.collection.collection_name,
                        disabled: false,
                        isStatic: false
                    };
                    links.value.splice(4, 0, orgListItem);
                }
                // Venue
                links.value[links.value.length - 1].title = venue.value.venue_name;
                links.value[links.value.length - 1].isStatic = false;
            }
        }, { immediate: true });
        onMounted(() => {
            // Get venue object from the Venue.vue component
            EventBus.$on('venueLoaded', _venue => {
                if (_venue) {
                    venue.value = _venue;
                }
            });
        });
        return {
            links
        };
    }
});
