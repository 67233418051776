import { HowToRoutes } from '@/HowTo/howto.const';
import SearchHeader from '@/Search/components/SearchHeader.vue';
/** Components */
const EmptyLayout = () => import(
/* webpackChunkName: "empty_layout.route" */ '@/layouts/EmptyLayout.vue');
const HowTo = () => import(/* webpackChunkName: "how_to.route" */ '@/HowTo/HowTo.vue');
const Article = () => import(/* webpackChunkName: "how_to.route" */ '@/HowTo/Article.vue');
export const howToRoutes = [
    {
        path: `/${HowToRoutes.HowTo}`,
        localePath: HowToRoutes.HowTo,
        component: EmptyLayout,
        meta: {
            header: SearchHeader
        },
        children: [
            {
                path: '/',
                component: HowTo
            },
            {
                path: ':slug',
                beforeEnter: (to, _from, next) => {
                    const locale = to.params.locale;
                    return next({
                        query: { lang: locale }
                    });
                },
                component: Article,
                meta: {
                    lang: 'en'
                }
            }
        ]
    }
];
