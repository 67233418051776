import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import { includes } from 'lodash';
import { BookingStatus } from '@/generated-types/graphql.types';
import { computed, isRef, ref } from '@vue/composition-api';
const moment = extendMoment(Moment);
export const now = moment();
export const unixNow = moment().unix();
export const currentYear = moment().year();
export const yesterday = moment().subtract(1, 'day').toDate();
export const unixTs = (date, tz) => {
    return tz ? moment.unix(date).tz(tz) : moment.unix(date);
};
moment.tz.setDefault('Europe/Amsterdam');
/*
  check if two dates are on the same day
 */
export const isSameDay = (first, second) => {
    return moment(first).isSame(moment(second), 'day');
};
export const isToday = (day) => {
    return moment().isSame(day, 'day');
};
/**
 * Create a Unix timestamp from the number of minutes from the start of the day and the date string
 * @param {number} minutes Number of minutes from the day start
 * @param {string} date Date as string ('YYYY-MM-DD')
 * @param {string} tz Timezone ('Europe/London')
 * @returns {number} Unix timestamp
 */
export const generateTimestamp = (minutes, date, tz) => {
    return moment.tz(date, tz).startOf('day').add(minutes, 'minutes').unix();
};
/**
 * Creating a moment range
 * @param {number} start range start timestamp
 * @param {number} end range end timestamp
 * @returns {monthsRange} moment range object
 */
export const dateRange = (start, end) => moment.range(unixTs(start), unixTs(end));
/**
 * Creating moment object
 * @param {number | string} ts timestamp
 * @returns {moment} moment object for selected timestamp
 */
export const momentObj = (ts) => moment(ts);
/**
 * Get moment object from the start of the day
 * @param {number | string} date timestamp
 * @returns {moment} moment object started from the 0:00
 */
export const startOfTheDay = (date) => moment(date).startOf('day');
/**
 * Get day of the week for the selected timestamp or date string
 * @param {number | string} date timestamp or date string
 * @returns {moment} day of the week in ISO format (Mon - 1, Sun - 7)
 */
export const getWeekDay = (date) => moment(dateFormatter(date, DATE_FORMATS.calendarCore)).isoWeekday();
/**
 * Date formatter for date strings
 * @param {string} date String formatted date
 * @param {string} format moment format
 * @returns {Moment} Moment date object with provided format
 */
export const dateFormatter = (date, format) => {
    return moment(date, format);
};
/**
 * Check if a specific date falls within daylight saving time (DST)
 * @param date
 */
export const isDST = (date) => {
    return moment.unix(date).isDST();
};
/**
 * Function helper. Format date into datetime string
 * @param {number} date timestamp
 * @param {string?} format datetime format string
 * @returns {string} formatted datetime string
 */
export const unixFormatted = (date, format) => moment.unix(date).format(format);
/**
 * Check if selected date is in the future
 * @param {number} date Unix timestamp
 * @returns {boolean} true - if is in future | false
 */
export const isAfterCheck = (date) => {
    return moment().isBefore(moment.unix(date));
};
/**
 * Check if there is less than 24 hours before the provided date
 * @param {number} date Unix timestamp
 * @returns {boolean} true - less than 24h | false
 */
export const ifLessThenDay = (date) => {
    return moment().add(24, 'hours').isAfter(moment.unix(date));
};
/**
 * Calculating remains time for BookedItemsDetails
 * @param {Undefined<number>} date
 * @returns {string} readable time string
 */
export const calculateRemainingTime = (date) => {
    const timeLeft = date && moment.duration(moment.unix(date).diff(moment())).humanize();
    return timeLeft?.toString();
};
/**
  Convert date string to Unix timestamp
  @param {string} date String formatted date
  @returns {number} Unix formatted date
 **/
export const unixDate = (date) => {
    return moment(date).unix();
};
/**
  Get relative time from now
  @param {number} date Unix timestamp
  @returns {string} String formatted relative time
 **/
export const getRelativeTime = (date) => {
    return globalThis.$moment.unix(date).fromNow(true);
};
/**
 * Get current day as string 'YYYY-MM-DD'
 */
export const getToday = () => moment().format('YYYY-MM-DD');
export const isDateToday = (date) => moment(date, 'ddd, D MMM').isSame(moment(), 'd');
export const dateFormat = (date, format) => moment(date).format(format);
export const dateTzFormat = (date, tz, format) => moment(date).tz(tz).format(format);
/**
 * Format the given timestamp to the format YYYY-MM-DD HH:mm (e.g. 2020-01-01 12:00).
 * Apply timezone if specified
 * @param {number} date Unix timestamp
 * @param {string?} tz Timezone (optional, 'Europe/London')
 * @param {boolean?} shiftHour Temporary workaround to shift the hour by +1
 * @returns {string} Formatted date as string
 */
export const dateString = (date, tz, shiftHour) => {
    const _date = moment.unix(date);
    if (shiftHour) {
        _date.add(1, 'hour');
    }
    return tz
        ? _date.tz(tz).format('YYYY-MM-DD HH:mm')
        : _date.format('YYYY-MM-DD HH:mm');
};
export const dateUnixTZ = (date, tz) => {
    const unix = moment.unix(date);
    return tz ? unix.tz(tz) : unix;
};
/**
 * Get number of minutes from the day start given the date as string
 * @param {string} date Date as string ('YYYY-MM-DD')
 * @returns {number} Number of minutes from the day start (e.g. 720)
 */
export const stringToSlot = (date) => {
    const mmt = moment(date);
    return mmt.diff(mmt.clone().startOf('day'), 'minutes');
};
export const dateDSTCheck = (slot, tz, format) => {
    const momentDate = dateUnixTZ(slot, tz);
    return format ? momentDate.format(format) : momentDate;
};
export const tsFromSlot = (slot, tz, day) => {
    return moment
        .tz(day, tz)
        .startOf('day')
        .hour(slot / 60)
        .minutes(slot % 60)
        .unix();
};
/**
 * Given the number of minutes from the day start and the date as string, check if the provided time is in the past
 * @param {number} minutes Number of minutes from the day start (e.g. 720)
 * @param {string} day Date as string ('YYYY-MM-DD')
 * @returns {boolean} true if is in the past | false
 */
export const isPastSlot = (minutes, day) => {
    return moment().isAfter(moment(day).startOf('day').add(minutes, 'minutes'));
};
/**
  Check if first timestamp is after the second one
  @param {number} first Unix timestamp
  @param {number} second Unix timestamp (compare to)
  @returns {boolean} true if first is after second else false
 **/
export const dateIsAfter = (first, second) => {
    return moment.unix(first).isAfter(moment.unix(second));
};
export const slotIsClosed = (date, slot) => {
    const stamp = moment(date)
        .startOf('day')
        .hour(Number(slot) / 60)
        .minutes(Number(slot) % 60);
    return moment(moment()).isAfter(stamp);
};
/**
 * Availability management block
 */
export const monthsRange = () => 
// Current month + 1 month ahead
Array.from(moment.range(moment(), moment().add(1, 'month')).by('month')).map(day => moment(day).month() + 1);
export const onMonthEvent = (date, tz) => {
    const day = tz ? unixTs(date, tz) : unixTs(date);
    const months = Array.from(moment.range(day, day.clone().add(2, 'months')).by('month')).map(day => moment(day).month() + 1);
    const year = day.year();
    return { months, year };
};
export const dateTzUnix = (date, tz) => moment(date).tz(tz).unix();
/**
 * Generator of classes for month calendar (Availability)
 * @param classes CSS classes for calendar
 * @param date Date string
 * @param props Object
 * @returns updated classes object
 */
export const formatDate = (classes, date, props) => {
    const day = moment(date).tz(globalThis.$timezone);
    // const range = day.range('day');
    const dayAsString = day.format('YYYY-MM-DD');
    // Get calendar data for the specified calendar day
    const calendarData = props.spacesCalendarData?.find(day => day.day === dayAsString);
    if (!calendarData)
        return;
    const isWorkingDay = calendarData.is_venue_working_day;
    // const workingDays = props.workingTime.map(day => day.week_day);
    const spacesBookings = calendarData.space_bookings;
    // Render a booking dot in the calendar day cell
    classes.booked = spacesBookings?.find(booking => 
    // Find only active bookings
    [
        BookingStatus.Pending,
        BookingStatus.Paid,
        BookingStatus.PaymentProcessing
    ].includes(booking.booking_status) || false);
    const availability = calendarData.space_availability;
    // Render calendar day cell as fully closed
    classes.closed = !isWorkingDay || calendarData.is_day_fully_closed;
    // Render calendar day cell as partially closed
    classes.partial =
        (!classes.closed && calendarData.is_day_partially_closed && isWorkingDay) ||
            (props.spacedIds.some(spaceId => availability?.find(slot => slot.space_id === spaceId && slot.is_opened)) &&
                !isWorkingDay) ||
            false;
    return classes;
};
export const dateFromStart = (date, tz, days = 0) => moment(date).tz(tz).add(days, 'day').startOf('day').unix();
const DATE_FORMATS = {
    calendarCore: 'YYYY-MM-DD',
    weekModeTitle: 'ddd, DD MMM',
    timeFormat: 'HH:mm',
    headerTitleMonth: 'MMMM',
    headerTitleWeek: 'DD MMM'
};
const getValidDate = date => {
    if (typeof date === 'string') {
        return new Date(date.replace(/-/g, '/'));
    }
    return date;
};
export const getDate = date => moment(getValidDate(date)).format(DATE_FORMATS.calendarCore);
export const getMonthViewStartDay = (date, firstDay, mode) => {
    if (mode === 'week') {
        const dayStart = startOfTheDay(date);
        // if current day == 0 (Sunday) start of the week will be Monday previous week.
        // Because in moment js week starts from Sunday
        return dayStart.day() ? dayStart.day(1) : dayStart.day(-6);
    }
    // get cur month start day obj from data
    let start = momentObj(date).startOf(mode);
    if (start.isoWeekday() < firstDay) {
        // if start day behind of the view's first day,
        // start day should subtract a week -
        // to include all days of the month
        start = start.subtract(1, 'week');
    }
    // set final start day
    const day = start.isoWeekday();
    start = start.add(firstDay - day, 'day');
    return start;
};
export const getItemStatus = (date, data, context) => {
    const tempDate = date.clone();
    const formatedDay = moment(context.currentDay);
    const isCurMonth = tempDate.month() === formatedDay.month();
    const isPrevMonth = !isCurMonth && tempDate.isBefore(formatedDay, 'month');
    const isNextMonth = !isCurMonth && tempDate.isAfter(formatedDay, 'month');
    const isPrevLastDay = isPrevMonth
        ? tempDate.isSame(tempDate.endOf('month'))
        : false;
    const isNextFirstDay = isNextMonth
        ? tempDate.isSame(tempDate.startOf('month'))
        : false;
    const isSelected = tempDate.format(DATE_FORMATS.calendarCore) ===
        moment(context.currentDay).format(DATE_FORMATS.calendarCore);
    const isDisabled = includes(context.disabledWeekDays, date.isoWeekday());
    const isHasBookings = data.length !== 0;
    const isInCurrentWeek = includes(context.getCurrentWeek, date.format(DATE_FORMATS.calendarCore));
    return {
        isPrevMonth,
        isPrevLastDay,
        isNextMonth,
        isNextFirstDay,
        isSelected,
        isToday: date.format(DATE_FORMATS.calendarCore) ===
            moment(context.today).format(DATE_FORMATS.calendarCore),
        isCurMonth,
        isDisabled,
        isHasBookings,
        isInCurrentWeek
    };
};
export const getDateInfo = (date) => {
    const dateObj = globalThis.$moment(date);
    return {
        year: dateObj.year(),
        month: dateObj.month() + 1,
        date: dateObj.date(),
        day: dateObj.isoWeekday(),
        full: dateObj.format(DATE_FORMATS.calendarCore),
        weekModeTitle: dateObj.format(DATE_FORMATS.weekModeTitle)
    };
};
export const updateCalendarData = (currentDay, method, context) => {
    const toChange = context.mode === 'week' ? 'isoWeek' : 'month';
    const selectedDayInNewMonth = moment(currentDay)[method](1, toChange);
    const today = now;
    if (toChange === 'month') {
        if (
        // if the selected month is the current month - return current day but not the first day
        selectedDayInNewMonth.month() === today.month() &&
            selectedDayInNewMonth.year() === today.year()) {
            return today.toDate();
        }
    }
    else if (toChange === 'isoWeek') {
        if (
        // if the selected week is the current week - return current day but not the first day
        selectedDayInNewMonth.isoWeek() === today.isoWeek()) {
            return today.toDate();
        }
    }
    return selectedDayInNewMonth.startOf(toChange).toDate();
};
export const getCurDay = date => moment(date, DATE_FORMATS.calendarCore).toDate();
//
//
// WeekView block
//
//
export const dayPickerFilterDays = day => moment(day).isAfter(moment().subtract(1, 'days'));
export const isAfterBookBeforeMinimumThreshold = (day, hours) => moment(day).isAfter(moment().add(hours, 'hours'));
export const isAfterBookBeforeMinimumThresholdMonthView = (day, hours) => {
    return moment(day).endOf('day').isAfter(moment().add(hours, 'hours'));
};
export const isBeforeBookBeforeMaximumThreshold = (day, hours) => moment(day).isBefore(moment().add(hours, 'hours'));
export const isBeforeBookBeforeMaximumThresholdMonthView = (day, hours) => moment(day).endOf('day').isBefore(moment().add(hours, 'hours'));
export const useBookingTimeSlots = (bookingData, showOnlyDuration = false) => {
    if (!bookingData || (isRef(bookingData) && bookingData.value)) {
        return ref(null);
    }
    const booking = isRef(bookingData)
        ? bookingData
        : computed(() => bookingData);
    const start = computed(() => dateDSTCheck(booking.value.slot_start, globalThis.$timezone));
    const end = computed(() => dateDSTCheck(booking.value.slot_end, globalThis.$timezone));
    const bookingDuration = computed(() => humanizedDuration(moment.duration(end.value.diff(start.value))));
    if (!bookingDuration) {
        return '';
    }
    return computed(() => showOnlyDuration
        ? `${booking.value.start_time || start.value.format('HH:mm')} – ${booking.value.end_time || end.value.format('HH:mm')} ${'(' + bookingDuration.value + ')'}`
        : `${start.value.format('D MMMM')}, ${booking.value.start_time || start.value.format('HH:mm')} – ${booking.value.end_time || end.value.format('HH:mm')} ${'(' + bookingDuration.value + ')'}`);
};
/**
 * Get human-readable duration
 * @param {moment.Duration} duration Duration object
 * @returns {string} string (e.g. 1 hour 30 minutes)
 */
export const humanizedDuration = (duration) => {
    // Get Months and subtract from duration
    const months = duration.months();
    duration.subtract(moment.duration(months, 'months'));
    const durMonths = months > 0 ? moment.duration(months, 'months').humanize() : '';
    // Get Days and subtract from duration
    const days = duration.days();
    duration.subtract(moment.duration(days, 'days'));
    const durDays = days > 0 ? moment.duration(days, 'days').humanize() : '';
    // Get hours and subtract from duration
    const hours = duration.hours();
    duration.subtract(moment.duration(hours, 'hours'));
    const durHours = hours > 0 ? moment.duration(hours, 'hours').humanize() : '';
    // Get Minutes and subtract from duration
    const minutes = duration.minutes();
    duration.subtract(moment.duration(minutes, 'minutes'));
    const durMinutes = minutes > 0 ? moment.duration(minutes, 'minutes').humanize() : '';
    return [durMonths, durDays, durHours, durMinutes].filter(Boolean).join(' ');
};
export const countdownTimerHumanizedDuration = (duration) => {
    const days = duration.days() > 0 ? duration.days() : 0;
    const hours = duration.hours() < 10 ? '0' + duration.hours() : duration.hours();
    const minutes = duration.minutes() < 10 ? '0' + duration.minutes() : duration.minutes();
    const seconds = duration.seconds() < 10 ? '0' + duration.seconds() : duration.seconds();
    return days + 'd:' + hours + ':' + minutes + ':' + seconds;
};
export const convertUnixToExternalCalendarDate = (date) => {
    return moment.unix(date).utc().format('YYYYMMDD[T]HHmmss[Z]');
};
export const enumDayOfWeek = new Map([
    [1, 'monday'],
    [2, 'tuesday'],
    [3, 'wednesday'],
    [4, 'thursday'],
    [5, 'friday'],
    [6, 'saturday'],
    [7, 'sunday']
]);
