import { getStorageToken } from '@/Authentication/utils/storage';
import { errorHandler } from '@/util/errors';
class APIClient {
    static _BACKEND_URL = process.env.VUE_APP_PUBLIC_API_URL;
    constructor() {
        if (!APIClient.instance) {
            APIClient.instance = this;
        }
        return APIClient.instance;
    }
    /**
     * Generates HTTP request headers for API calls.
     * This asynchronous function creates a headers object for use in HTTP requests.
     * It includes options to set 'Content-Type' to 'application/json' and to add an 'Authorization' header
     * with a Bearer token retrieved from local storage using 'getStorageToken'.
     *
     * Parameters:
     * - withCredentials: A boolean flag that determines whether to include the 'Authorization' header.
     * - isFormData: A boolean flag that, if true, prevents setting 'Content-Type' to 'application/json',
     *   as form data requires a different content type to be properly sent.
     *
     * The function returns a promise resolving to an object containing the necessary headers for the API request.
     * This implementation ensures that requests are properly formatted and authenticated as required.
     *
     * @param {boolean} withCredentials - Flag to include 'Authorization' header with Bearer token.
     * @param {boolean} isFormData - Flag to set 'Content-Type' to 'application/json' or not.
     * @returns {Promise<Record<string, string>>} A promise resolving to an object containing the request headers.
     */
    async _generateHeaders(withCredentials = true, isFormData = false) {
        const accessToken = getStorageToken();
        const headers = {};
        if (!isFormData) {
            headers['Content-Type'] = 'application/json';
        }
        if (withCredentials) {
            headers.Authorization = `Bearer ${accessToken}`;
        }
        if (globalThis.$i18n.locale) {
            headers['X-Locale'] = globalThis.$i18n.locale.toUpperCase();
        }
        return headers;
    }
    /**
     * Performs an internal HTTP request using predefined settings.
     * This asynchronous function is designed to simplify making HTTP requests within the application.
     * It constructs the complete URL by appending the provided 'url' parameter to a backend base URL.
     * The function then delegates the actual request handling to the '_baseRequest' method.
     *
     * Parameters:
     * - url: The endpoint path to be appended to the backend base URL.
     * - type: The HTTP method type (e.g., 'GET', 'POST').
     * - data: The data to be sent with the request, typically used with 'POST' or 'PUT' requests.
     * - withCredentials: A boolean flag to indicate whether credentials (like cookies or headers) should be included.
     * - throwUp: A boolean flag to indicate whether errors should be thrown up to the caller.
     * - label: A boolean flag used for additional processing or handling, specific to the implementation.
     *
     * The function returns a promise that resolves to the response from the '_baseRequest' method.
     * This modular approach helps in maintaining and updating the API request logic conveniently.
     *
     * @param {string} url - The API endpoint path.
     * @param {string} type - The HTTP request method.
     * @param {object} data - The data to be sent with the request.
     * @param {boolean} withCredentials - Flag to include credentials in the request.
     * @param {boolean} throwUp - Flag to control error propagation.
     * @param {boolean} label - Additional flag for custom processing.
     * @returns {Promise<any>} A promise resolving to the response from the '_baseRequest' method.
     */
    async _internalRequest(url = '', type = 'GET', data = {}, withCredentials = true, throwUp = false, label = false) {
        const urlCompleted = `${APIClient._BACKEND_URL}${url}`;
        return this._baseRequest(urlCompleted, type, data, withCredentials, throwUp, label);
    }
    async _baseRequest(url = '', type = 'GET', data = {}, withCredentials = true, throwUp = false, label = false) {
        if (!process.browser)
            return;
        const isFormData = data instanceof FormData;
        const params = {
            method: type,
            mode: 'cors',
            credentials: 'same-origin',
            headers: await this._generateHeaders(withCredentials, isFormData),
            redirect: 'follow',
            referrer: 'no-referrer'
        };
        let dataRequest = data;
        const labelId = Number(process.env.VUE_APP_LABEL_ID);
        if (label) {
            if (isFormData)
                data.append('label_id', labelId);
            dataRequest = { ...data, label_id: labelId };
        }
        if (type !== 'GET') {
            params.body = isFormData ? data : JSON.stringify(dataRequest);
        }
        try {
            const response = await fetch(url, params);
            if (!response.ok) {
                let responseParsed;
                try {
                    responseParsed = await response.json();
                }
                catch (e) {
                    try {
                        responseParsed = await response.text();
                    }
                    catch (e) {
                        console.log('Cannot parse backend response');
                    }
                }
                throw {
                    data: responseParsed,
                    status: response.status,
                    response
                };
            }
            if (response.status === 204) {
                return;
            }
            return response.json();
        }
        catch (error) {
            errorHandler(error, { url, type, data });
            throw error;
        }
    }
    async getData(url = '', withCredentials = true, throwUp = false) {
        return this._internalRequest(url, 'GET', {}, withCredentials, throwUp);
    }
    async postData(url = '', data = {}, withCredentials = true, throwUp = false, label = false) {
        return this._internalRequest(url, 'POST', data, withCredentials, throwUp, label);
    }
    async patchData(url = '', data = {}, withCredentials = true, throwUp = false, label = false) {
        return this._internalRequest(url, 'PATCH', data, withCredentials, throwUp, label);
    }
    async deleteData(url = '', withCredentials = true, throwUp = false) {
        return this._internalRequest(url, 'DELETE', {}, withCredentials, throwUp);
    }
}
const instance = new APIClient();
Object.freeze(instance);
export default instance;
