import APIClient from '@/shared/services/api';
export const addSpaceConfigurations = (data, id) => APIClient.postData(`space/${id}/configuration/bulk/`, data, true, false, true);
export const addSpaceFacilities = (data) => APIClient.postData('space/facility/bulk/', data, true, false, true);
export const addSpaceFeatures = (data) => APIClient.postData('space/feature/bulk/', data, true);
export const addSpaceImages = (data, id) => APIClient.postData(`image/space/${id}/bulk/`, data, true);
export const attachVenueFeatures = (data) => APIClient.postData('venue/feature/bulk/', data, true, false, true);
export const attachVenueImages = (data, id) => APIClient.postData(`image/venue/${id}/bulk/`, data, true);
export const attachVenueServices = (data) => APIClient.postData('venue/services/bulk/', data, true);
export const createSpace = (form) => APIClient.postData('space/', form, true, false, true);
export const createVenue = (form) => APIClient.postData('venue/', form, true, false, true);
export const loadOrganisation = (throwUp) => APIClient.getData('organisation/', true, throwUp);
export const loadSpaceCategories = () => APIClient.getData('category/', true, false);
export const loadSpaceConfigurations = () => APIClient.getData('configuration/', true, false);
export const loadSpaceFeatures = () => APIClient.getData('feature/space/', true, false);
export const loadSpaceImages = (spaceId) => APIClient.getData(`image/space/${spaceId}/`, true, false);
export const loadSpaceServices = (venueId) => APIClient.getData(`space/${venueId}/facility/`, true, false);
export const loadSpaceStaticServices = () => APIClient.getData('service/space/', true, false);
// export const loadSpacesList = (venueId: string | number): Promise<any> =>
//   APIClient.getData(`venue/${venueId}/space/`, true, false);
export const loadVenue = (id) => APIClient.getData(`venue/${id}/`, true, false);
export const loadVenueFeatures = () => APIClient.getData('feature/venue/', true, false);
export const loadVenueImage = (id) => APIClient.getData(`image/venue/${id}/`, true, false);
export const loadVenueServices = () => APIClient.getData('service/venue/', true, false);
// export const loadVenueServicesById = (venueId: string | number): Promise<any> =>
//   APIClient.getData(`venue/${venueId}/services/`, true, false);
export const loadVenuesList = () => APIClient.getData('venue/', true, false);
export const removeSpace = (id) => APIClient.deleteData(`space/${id}/`, true, false);
export const removeVenue = (id) => APIClient.deleteData(`venue/${id}/`, true, false);
const getPreparedForm = (form, dic) => {
    const preparedForm = dic.reduce((acc, curr) => {
        const [key1, key2] = curr;
        return {
            ...acc,
            [key2]: form[key1]
        };
    }, Object.create(null));
    preparedForm.is_finance_coc_geo = true;
    return preparedForm;
};
export const saveOrganisation = (form) => {
    if (form.organisation_type.toUpperCase() === 'PUBLIC') {
        return APIClient.postData('organisation/public/', { ...form, is_finance_coc_geo: true }, true);
    }
    return APIClient.postData('organisation/private/', getPreparedForm(form, [
        ['finance_email', 'finance_email'],
        ['name', 'organisation_name'],
        ['place_id', 'place_id'],
        ['finance_place_id', 'finance_place_id'],
        ['vat_number', 'vat_number'],
        ['vat_rate', 'vat_rate']
    ]), true);
};
export const searchAddressByCoC = (coc) => APIClient.getData(`kvk/get_address/${coc}/`);
export const updateVenue = (form, id) => APIClient.patchData(`venue/${id}`, form, true, false, true);
