import { defineComponent, onMounted } from '@vue/composition-api';
export default defineComponent({
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        onMounted(() => {
            // creating an array of scripts/styles to inject
            const scripts = props.blok.body.map(sc => {
                let snippet = document.createElement(sc.type);
                if (sc.type === 'script')
                    // adding type (dafault - 'text/javascript')
                    snippet.setAttribute('type', 'text/javascript');
                if (sc.attributes.length) {
                    // adding all attributes (src, data-attributes, etc.)
                    sc.attributes.forEach(attr => snippet.setAttribute(attr.name, attr.value));
                }
                // adding script/style body to our element
                if (sc.body)
                    snippet.appendChild(document.createTextNode(sc.body));
                return snippet;
            });
            // Injecting first script
            document[props.blok.body[0].position].appendChild(scripts[0]);
            scripts[0].onload = () => {
                // Waiting for the first script to be injected
                // it is necessary for a sync loading
                scripts.forEach((sc, i) => {
                    // Injecting all other scripts
                    if (i)
                        document[props.blok.body[i].position].appendChild(sc);
                });
            };
        });
    }
});
