export var TestimonialView;
(function (TestimonialView) {
    TestimonialView["BOOKER"] = "booker";
    TestimonialView["VENDOR"] = "vendor";
})(TestimonialView || (TestimonialView = {}));
export const socialSharingOptions = [
    {
        name: 'facebook',
        social: true,
        iconName: 'fa-brands fa-facebook fa-lg pre-icon'
    },
    {
        name: 'twitter',
        social: true,
        iconName: 'fa-brands fa-x-twitter fa-lg pre-icon'
    },
    {
        name: 'linkedin',
        social: true,
        iconName: 'fa-brands fa-linkedin fa-lg pre-icon'
    },
    {
        name: 'copy',
        social: false,
        iconName: 'fa-solid fa-copy fa-lg pre-icon'
    },
    {
        name: 'email',
        social: true,
        iconName: 'fa-solid fa-envelope fa-lg pre-icon'
    }
];
export const homeInfoBlocks = [
    {
        name: 'why_wemeet',
        icon: 'fa-solid fa-block-question',
        img: 'https://media.wemeet.nl/gallery/assets/home/art-room_NuY9FJUD.jpeg?tr=w-200,h-200',
        alt: 'Art room'
    },
    {
        name: 'online_booking',
        icon: 'fa-sharp fa-solid fa-globe',
        img: 'https://media.wemeet.nl/gallery/assets/home/online_ysFgJ8vZa.png?tr=w-200,h-200',
        alt: 'Online booking'
    },
    {
        name: 'meeting_venues',
        icon: 'fa-regular fa-map-location',
        img: 'https://media.wemeet.nl/gallery/assets/home/venue_0mDLE6jxT.jpeg?tr=w-200,h-200',
        alt: 'Meeting venues'
    },
    {
        name: 'professional_meetings',
        icon: 'fa-solid fa-user-tie',
        img: 'https://media.wemeet.nl/gallery/assets/home/professional_meetings_M9oT53sSG.png?tr=w-200,h-200',
        alt: 'Professional meetings'
    }
];
