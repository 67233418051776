import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes/const';
import RouteGuard from '@/Authentication/authentication.guard';
import { UserRoleState } from '@/generated-types/graphql.types';
const ProfilePage = () => import(
/* webpackChunkName: "account_page.route" */ '@/Account/components/ProfilePage.vue');
const AccountPage = () => import(/* webpackChunkName: "account_page.route" */ '@/Account/Account.vue');
const NotificationManagementPage = () => import(
/* webpackChunkName: "account_page.route" */ '@/Account/NotificationManagement.vue');
const OrganisationPage = () => import(
/* webpackChunkName: "organisation.route" */ '@/pages/profile/organisation/OrganisationPage.vue');
const FinancePayout = () => import(/* webpackChunkName: "finance.route" */ '@/Finance/FinancePayout.vue');
const FinanceHistory = () => import(
/* webpackChunkName: "finance_history.route" */ '@/Finance/FinanceHistory.vue');
const Team = () => import(/* webpackChunkName: "team.route" */ '@/Account/Team.vue');
const PublicPage = () => import(
/* webpackChunkName: "organisation.route" */ '@/Organisation/vendors/PublicPage.vue');
export const profileRoutes = [
    {
        path: '/profile',
        localePath: 'profile',
        name: ROUTE_LIST_NAMES.PROFILE.MAIN,
        redirect: { name: ROUTE_LIST_NAMES.PROFILE.ACCOUNT },
        component: ProfilePage,
        children: [
            {
                path: 'account',
                name: ROUTE_LIST_NAMES.PROFILE.ACCOUNT,
                meta: {
                    mobileFullWidth: true
                },
                component: AccountPage
            },
            {
                path: 'notifications',
                name: ROUTE_LIST_NAMES.PROFILE.NOTIFICATIONS,
                meta: {
                    mobileFullWidth: true
                },
                component: NotificationManagementPage
            },
            {
                path: 'organisation',
                name: ROUTE_LIST_NAMES.PROFILE.ORGANISATION,
                beforeEnter: RouteGuard.roleAccess(UserRoleState.Vendor),
                meta: {
                    mobileFullWidth: true,
                    embed: true
                },
                component: OrganisationPage
            },
            {
                path: 'social',
                name: ROUTE_LIST_NAMES.PROFILE.SOCIAL,
                beforeEnter: RouteGuard.roleAccess(UserRoleState.Vendor),
                meta: {
                    mobileFullWidth: true,
                    embed: true
                },
                component: PublicPage
            },
            {
                path: 'payout',
                name: ROUTE_LIST_NAMES.PROFILE.PAYOUT,
                beforeEnter: RouteGuard.roleAccess(UserRoleState.Vendor),
                meta: {
                    mobileFullWidth: true,
                    embed: true
                },
                component: FinancePayout
            },
            {
                path: 'history',
                name: ROUTE_LIST_NAMES.PROFILE.HISTORY,
                meta: {
                    mobileFullWidth: true,
                    embed: true
                },
                component: FinanceHistory
            },
            {
                path: 'team',
                beforeEnter: RouteGuard.roleAccess(UserRoleState.Vendor),
                name: ROUTE_LIST_NAMES.PROFILE.TEAM,
                meta: {
                    mobileFullWidth: true
                },
                component: Team
            }
        ]
    }
];
