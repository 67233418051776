import Vue from 'vue';
export const afterEachCallback = (to, from) => {
    return Vue.nextTick(() => {
        let lastPage = false;
        // Triggers on page changes and on homepage entrances
        if ((to.path !== from.path && to.name !== 'main') ||
            (to.name === 'main' && from.name === 'main')) {
            try {
                let sentView = false;
                setTimeout(function () {
                    if (location.href !== lastPage && !sentView) {
                        globalThis.$gtm.trackView(document.title, location.href);
                        lastPage = location.href;
                        sentView = true;
                    }
                }, 750);
                //@todo: implement event tracking here to track pages using the default system title
            }
            catch (e) {
                console.error('Page view error');
                console.error(e);
            }
        }
    });
};
