import { ServerErrors } from '@/util/graphql';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
const isAPIError = (error) => error && 'data' in error;
const isAuthError = (error) => error?.error?.status_code === 401 && error?.error?.entity === 'access';
const isNotFoundError = (error) => error?.error?.status_code === 404;
export const errorHandler = (error, request) => {
    const serverError = ServerErrors.extractGQLError(isAPIError(error) ? error.data : error);
    if (isNotFoundError(serverError)) {
        globalThis.$router.push({ name: ROUTE_LIST_NAMES.ERRORS.NOT_FOUND });
    }
    const addToast = globalThis.$store.dispatch.bind(null, 'addToast');
    if (isAuthError(serverError)) {
        return;
    }
    if (isAPIError(error)) {
        /** TODO: add analytics */
        console.warn('Request url:', error.response.url);
        console.warn('Request body:', error);
        // if (process.env.NODE_ENV === 'production') {
        //   configureScope(scope => {
        //     scope.setExtra('serverError', serverError?.error);
        //     if (request) {
        //       scope.setExtra('serverRequest', JSON.stringify(request));
        //     }
        //     captureException(new Error(serverError?.toString()));
        //   });
        // }
    }
    if (process.env.NODE_ENV === 'production' &&
        serverError?.error?.entity === 'graphql')
        return;
    if (serverError && ServerErrors.isCommonError(serverError)) {
        /** TODO: remove this once we decide how to show messages to users */
        if (process.env.NODE_ENV === 'production') {
            // configureScope(scope => {
            //   scope.setExtra('serverError', serverError.error);
            //   if (request) {
            //     scope.setExtra('serverRequest', request);
            //   }
            //   captureException(new Error(serverError.toString()));
            // });
            return;
        }
        addToast({
            text: serverError.message,
            type: 'danger',
            dismissAfter: 7000
        });
    }
    if (process.browser && !navigator.onLine) {
        addToast({
            text: 'Please check internet connection',
            type: 'danger',
            dismissAfter: 3000
        });
    }
};
export const GQLErrorHandler = ({ operation, forward, networkError, graphQLErrors }) => {
    errorHandler(graphQLErrors ?? networkError?.result, operation);
    forward(operation);
};
