import { dateFormat } from '@/util/dates';
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        return {
            formattedDate: computed(() => dateFormat(props.blok.Date, 'DD MMM'))
        };
    }
});
