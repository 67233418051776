import VenueSearchModel from '@/shared/models/venue/VenueSearchModel';
import { defaultFilter } from '@/shared/store-modules/venue/const';
// SET Venues after find method
const SET_VENUES = (state, { venues }) => {
    if (!venues.length)
        return;
    state.venues = [
        ...state.venues,
        ...venues.map(venue => {
            const model = new VenueSearchModel();
            model.loadFromAPI(venue);
            model.setSpaces({
                venue,
                venueId: model.id,
                activitiesList: state.activities,
                venueUrl: model.venueUrl
            });
            return model;
        })
    ];
};
// SET Loading Venues
const SET_LOADING_VENUES = (state, bool) => {
    state.loadingVenues = bool;
};
//CLEAR VENUES for new search request
const CLEAR_VENUES = (state) => {
    state.venues = [];
};
// Categories aka activities?
const SET_ACTIVITIES = (state, activities) => {
    state.activities = activities;
};
const SET_FILTER = (state, filter) => {
    state.filter = filter;
};
const SET_FILTER_TIME = (state, time) => {
    state.filter.time = time;
};
const SET_FILTER_ACTIVITY = (state, activities) => {
    state.filter.activities = activities;
};
const SET_FILTER_PRICE = (state, price) => {
    state.filter.price.value = price;
};
const SET_FILTER_FACILITY = (state, facilities) => {
    state.filter.facilities = facilities;
};
const SET_FILTER_FEATURE = (state, features) => {
    state.filter.features = features;
};
const SET_FILTER_DIRECT_BOOKING = (state, isDirectBooking) => {
    state.filter.isDirectBooking = isDirectBooking;
};
const SET_FILTER_MORE = (state, more) => {
    state.filter.more = more;
};
const SET_FILTER_SEARCH_KEY = (state, searchKey) => {
    state.filter.searchKey = searchKey;
    state.filter.address = [];
};
const SET_FILTER_SEARCH_KEY_ADDRESS = (state, { searchKey, address }) => {
    state.filter.searchKey = searchKey;
    if (address) {
        state.filter.address = [...address];
    }
    else {
        state.filter.address = [];
    }
};
const RESET_FILTER = (state) => {
    state.filter = JSON.parse(JSON.stringify(defaultFilter));
};
const SET_MAP_API = (state, mapAPI) => {
    state.map_data.mapAPI = mapAPI;
};
export default {
    SET_FILTER,
    SET_FILTER_TIME,
    SET_FILTER_ACTIVITY,
    SET_FILTER_PRICE,
    SET_FILTER_FACILITY,
    SET_FILTER_FEATURE,
    SET_FILTER_DIRECT_BOOKING,
    SET_FILTER_MORE,
    SET_FILTER_SEARCH_KEY,
    SET_FILTER_SEARCH_KEY_ADDRESS,
    RESET_FILTER,
    SET_MAP_API,
    SET_VENUES,
    SET_ACTIVITIES,
    CLEAR_VENUES,
    SET_LOADING_VENUES
};
