export var ServicesRoutes;
(function (ServicesRoutes) {
    ServicesRoutes["SERVICES"] = "services";
})(ServicesRoutes || (ServicesRoutes = {}));
export const DATA_TABLE_SETTINGS = {
    datatableCSS: {
        table: 'table table-hover table-center',
        theadTh: 'header-item',
        thWrapper: 'th-wrapper',
        arrowsWrapper: 'arrows-wrapper',
        arrowUp: 'arrow up',
        arrowDown: 'arrow down',
        footer: 'footer'
    },
    isLoading: false,
    sort: 'asc',
    sortField: 'status'
};
