import { lnManager } from '@/i18n/lnManager';
export const useGetLocalizedPath = (path) => {
    return lnManager.storageLanguage !== 'undefined' &&
        globalThis.$i18n.locale !== 'nl'
        ? `/${globalThis.$i18n.locale}/${path}`
        : `/${path}`;
};
export const useGetStyles = (data) => {
    return data.style && data.style.length ? JSON.parse(data.style) : null;
};
export const optimizeImage = (url) => {
    const connectionSpeedType = navigator?.connection?.effectiveType;
    if (connectionSpeedType === '2g' || connectionSpeedType === '3g') {
        return `${url},q-50`;
    }
    return url;
};
export const visibilityChanged = (isVisible, entry) => {
    if (isVisible) {
        setTimeout(() => {
            const image = entry.target;
            image.src = image.dataset.src;
        }, 10);
    }
};
export const backgroundVisibilityChanged = (isVisible, entry) => {
    if (isVisible) {
        setTimeout(() => {
            const image = entry.target;
            image.classList.remove('lazy');
        }, 10);
    }
};
export const cleanObjectKeys = (obj) => {
    // Iterate over each key in the object
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        // Check if the value is undefined
        if (value === undefined) {
            // Remove the key from the object
            delete obj[key];
        }
        else if (Array.isArray(value)) {
            // Check if the value is an empty array
            if (value.length === 0) {
                // Remove the key from the object
                delete obj[key];
            }
        }
    });
    return obj;
};
