import { FinanceRoutes } from '@/Finance/finance.const';
/** Components */
const EmptyLayout = () => import(
/* webpackChunkName: "finance.route" */ '@/layouts/EmptyLayout.vue');
const Finance = () => import(/* webpackChunkName: "finance.route" */ '@/Finance/Finance.vue');
export const financeRoutes = [
    {
        path: `/${FinanceRoutes.FINANCE}`,
        localePath: FinanceRoutes.FINANCE,
        component: EmptyLayout,
        children: [
            {
                path: '/',
                component: Finance,
                meta: {
                    maxWidthContainer: true,
                    center: true,
                    noPadding: true,
                    fullHeight: true,
                    noFooter: true
                }
            }
        ]
    }
];
