import { ChatRoutes } from '@/Chat/chat.const';
import RouteGuard from '@/Authentication/authentication.guard';
/** Components */
const EmptyLayout = () => import(
/* webpackChunkName: "chat.route" */ '@/layouts/EmptyLayout.vue');
const Chat = () => import(/* webpackChunkName: "chat.route" */ '@/Chat/Chat.vue');
const ChatDialog = () => import(/* webpackChunkName: "chat.route" */ '@/Chat/ChatDialog.vue');
export const chatRoutes = [
    {
        path: `/${ChatRoutes.CHAT}`,
        localePath: ChatRoutes.CHAT,
        component: EmptyLayout,
        beforeEnter: RouteGuard.authorized,
        children: [
            {
                path: '/',
                component: Chat,
                meta: {
                    maxWidthContainer: true,
                    center: true,
                    noPadding: true,
                    fullHeight: true,
                    noFooter: true
                },
                children: [
                    {
                        path: ':chatId',
                        component: ChatDialog,
                        meta: {
                            maxWidthContainer: true,
                            center: true,
                            noPadding: true,
                            fullHeight: true,
                            noFooter: true
                        }
                    }
                ]
            }
        ]
    }
];
