import BaseForm from '@/shared/models/BaseForm';
class ServiceModel extends BaseForm {
    description;
    enabled;
    expectQuantity;
    fields;
    icon;
    id;
    inventory;
    inventoryAllowed;
    inventoryEnabled;
    label;
    maxInventory;
    onDemand;
    orderType;
    price;
    selectable;
    selected;
    selectedLimitOption;
    vat;
    venueServiceId;
    venueServiceOrderType;
    constructor(props) {
        super(props);
        this.id = null;
        this.venueServiceId = null;
        this.label = '';
        this.icon = 'wifi';
        this.selected = false;
        this.selectable = true;
        this.price = null;
        this.fields = null;
        this.vat = null;
        this.description = '';
        this.inventory = null;
        this.inventoryEnabled = false;
        this.enabled = false;
        this.onDemand = false;
        this.expectQuantity = false;
        this.maxInventory = 0;
        this.orderType = 'STATIC';
        this.venueServiceOrderType = 'STATIC';
        this.inventoryAllowed = false;
        this.selectedLimitOption = 0;
        if (props)
            Object.keys(props).forEach((k) => {
                this[k] = props[k];
            });
    }
    apiAttributes() {
        return {
            id: 'system_service_id',
            venueServiceId: 'venue_service_id',
            label: 'system_service_name',
            description: 'description',
            enabled: 'is_enabled',
            inventoryEnabled: 'is_inventory_enabled',
            onDemand: 'is_on_demand',
            maxInventory: 'max_inventory',
            vat: 'vat_rate',
            price: 'price',
            selected: 'is_enabled',
            orderType: 'order_type',
            venueServiceOrderType: 'venue_service_order_type',
            inventoryAllowed: 'is_inventory_allowed',
            expectQuantity: 'expect_quantity',
            allowOnDemand: 'allow_on_demand'
        };
    }
}
export default ServiceModel;
