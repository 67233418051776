import FormSelect from '@/shared/components/form/FormSelect.vue';
import { defineComponent, computed, ref, reactive, watch, toRefs } from '@vue/composition-api';
import i18n from '@/i18n/i18n-setup';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import ListItem from '@/uikit/ListItem.vue';
const themeStyles = {
    dayContent({ day }) {
        if (day.inMonth) {
            return {
                backgroundColor: '#FFF',
                height: window.innerWidth < 400 ? '30px' : '50px',
                width: '100%',
                border: '1px solid #E5E5E5',
                borderStyle: 'none solid solid none',
                borderRadius: '0',
                margin: '0'
            };
        }
        return {
            backgroundColor: '#FFF',
            height: window.innerWidth < 400 ? '30px' : '50px',
            width: '100%',
            border: '1px solid #CACACA',
            borderStyle: 'none solid solid none',
            borderRadius: '0',
            margin: '0'
        };
    },
    weeks() {
        return {
            border: '1px solid #E5E5E5',
            borderStyle: 'solid none none solid',
            padding: '0',
            flexGrow: '0'
        };
    },
    weekdays() {
        return {
            padding: '0',
            marginBottom: '5px',
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: '500'
        };
    }
};
const attributes = [
    {
        key: 'today',
        content: { class: 'today-day' },
        dates: new Date()
    }
];
const selectAttribute = {
    highlight: {
        class: 'selected-day'
    },
    content: {
        color: 'white',
        class: 'vc-text-white'
    },
    dates: new Date(),
    order: 100
};
const generateHours = (timestamp) => new Array(25).fill('').map((_, key, arr) => {
    if (key + 1 === arr.length) {
        return globalThis.$moment
            .unix(timestamp)
            .hour(key - 1)
            .endOf('hour');
    }
    return globalThis.$moment.unix(timestamp).hour(key).startOf('hour');
});
var SlotRanges;
(function (SlotRanges) {
    SlotRanges["MORNING"] = "morning";
    SlotRanges["AFTERNOON"] = "afternoon";
    SlotRanges["EVENING"] = "evening";
})(SlotRanges || (SlotRanges = {}));
const calculateRange = (acc, range) => globalThis.$moment.range(range.start.isBefore(acc?.start) ? range.start : acc.start, range.end.isAfter(acc?.end) ? range.end : acc.end);
const formatHourOptions = (item) => ({
    label: item.format('HH:mm'),
    value: item.unix()
});
export default defineComponent({
    components: {
        FormSelect,
        ListItem,
        DatePicker
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, { emit }) {
        const startDate = computed(() => props.value?.slotFrom || props.value.slotTo
            ? globalThis.$moment.unix(props.value.slotFrom ?? props.value.slotTo)
            : globalThis.$moment());
        const _day = ref(startDate.value.toDate());
        const currentDay = computed({
            get() {
                return _day.value;
            },
            set(day) {
                if (day === null) {
                    return;
                }
                _day.value = day;
            }
        });
        const ranges = computed(() => {
            const startDate = globalThis.$moment(currentDay.value).startOf('day');
            return [
                {
                    name: SlotRanges.MORNING,
                    slotFrom: startDate.hours(9).startOf('hour').unix(),
                    slotTo: startDate.hours(12).startOf('hour').unix()
                },
                {
                    name: SlotRanges.AFTERNOON,
                    slotFrom: startDate.hours(12).startOf('hour').unix(),
                    slotTo: startDate.hours(16).startOf('hour').unix()
                },
                {
                    name: SlotRanges.EVENING,
                    slotFrom: startDate.hours(16).startOf('hour').unix(),
                    slotTo: startDate.hours(19).startOf('hour').unix()
                }
            ];
        });
        let times = reactive({
            morning: reactive({ checked: false }),
            afternoon: reactive({ checked: true }),
            evening: reactive({ checked: false })
        });
        /**
         * Populate options data
         */
        const prefillTime = () => {
            ranges.value.forEach(slot => {
                times[slot.name].checked =
                    slot.slotFrom === props.value.slotFrom &&
                        slot.slotTo === props.value.slotTo;
            });
        };
        const customRange = reactive({
            slotFrom: null,
            slotTo: null
        });
        /**
         * Set values for the custom range dropdowns
         */
        const updateCustomRange = slots => {
            Object.assign(customRange, slots);
            Object.keys(times).forEach(key => {
                times[key].checked = false;
            });
            emit('input', slots);
        };
        const hours = computed(() => generateHours(globalThis.$moment(currentDay.value).unix()));
        let startHours = ref(hours.value.map(formatHourOptions));
        let endHours = ref(hours.value.map(formatHourOptions));
        /**
         * Set values for the custom range dropdowns
         */
        const prefillCustomTime = (range) => {
            if (range) {
                customRange.slotFrom = range.start.unix();
                customRange.slotTo = range.end.unix();
            }
            else {
                customRange.slotFrom = null;
                customRange.slotTo = null;
            }
        };
        /**
         * Watch the day change in the calendar and update the selected slots date.
         */
        watch(() => currentDay.value, (_, prev) => {
            if (prev === undefined)
                return;
            if (customRange.slotFrom) {
                customRange.slotFrom = globalThis.$moment
                    .unix(customRange.slotFrom)
                    .year(globalThis.$moment(currentDay.value).year())
                    .dayOfYear(globalThis.$moment(currentDay.value).get('dayOfYear'))
                    .unix();
            }
            if (customRange.slotTo) {
                customRange.slotTo = globalThis.$moment
                    .unix(customRange.slotTo)
                    .year(globalThis.$moment(currentDay.value).year())
                    .dayOfYear(globalThis.$moment(currentDay.value).get('dayOfYear'))
                    .unix();
            }
            updateCustomRange(customRange);
        }, { immediate: true });
        /**
         * Mark radio button checked based on initial values comes from props
         */
        watch(() => props.value, (slot, _) => {
            if (!slot) {
                return;
            }
            if (!slot.slotFrom && !slot.slotTo) {
                const startDate = globalThis.$moment(currentDay.value).startOf('day');
                // If no slots provided in props, use default Afternoon values
                slot.slotFrom = startDate.hours(12).startOf('hour').unix();
                slot.slotTo = startDate.hours(16).startOf('hour').unix();
            }
            const { slotTo, slotFrom } = slot;
            const range = slotFrom || slotTo
                ? globalThis.$moment.range(globalThis.$moment.unix(slotFrom ||
                    globalThis.$moment(currentDay.value).startOf('day').unix()), globalThis.$moment.unix(slotTo ||
                    globalThis.$moment(currentDay.value).endOf('day').unix()))
                : null;
            const rangeFromSlots = ranges.value.reduce((acc, { slotFrom, slotTo }) => {
                const slotRange = globalThis.$moment.range(globalThis.$moment.unix(slotFrom), globalThis.$moment.unix(slotTo));
                if (range?.contains(slotRange.start) ||
                    range?.contains(slotRange.end)) {
                    return acc ? calculateRange(acc, slotRange) : slotRange;
                }
                return acc;
            }, null);
            prefillCustomTime(range);
            if (!rangeFromSlots && !range) {
                return;
            }
            prefillTime();
        }, { immediate: true });
        /**
         * Send applied custom range
         */
        watch(() => toRefs(customRange), range => {
            if (range.slotTo.value) {
                startHours.value = hours.value
                    .filter(hour => hour.isBefore(globalThis.$moment.unix(range.slotTo.value)))
                    .map(formatHourOptions);
            }
            if (range.slotFrom.value) {
                endHours.value = hours.value
                    .filter(hour => hour.isAfter(globalThis.$moment.unix(range.slotFrom.value)))
                    .map(formatHourOptions);
            }
        }, { immediate: true });
        /**
         * Calculate range based on the radio button clicks
         */
        const onRangeRadioChecked = (name, checked) => {
            Object.keys(times).reduce((acc, key) => {
                if (key !== name) {
                    acc[key] = { ...acc[key], checked: !checked };
                }
                else {
                    acc[key] = { ...acc[key], checked };
                }
                return acc;
            }, times);
            const selectedRange = ranges?.value?.find(_range => _range?.name === name);
            const range = selectedRange
                ? globalThis.$moment.range(globalThis.$moment.unix(selectedRange.slotFrom), globalThis.$moment.unix(selectedRange.slotTo))
                : null;
            prefillCustomTime(range);
            emit('input', {
                slotTo: range?.end.unix(),
                slotFrom: range?.start.unix()
            });
        };
        return {
            attributes,
            currentDay,
            customRange,
            endHours,
            locale: computed(() => i18n.locale),
            ranges,
            selectAttribute,
            startHours,
            themeStyles,
            times,
            onRangeRadioChecked,
            updateCustomRange
        };
    }
});
