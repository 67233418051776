import { ServicesRoutes } from '@/Services/services.const';
import RouteGuard from '@/Authentication/authentication.guard';
import { UserRoleState } from '@/generated-types/graphql.types';
/** Components */
const EmptyLayout = () => import(
/* webpackChunkName: "services.route" */ '@/layouts/EmptyLayout.vue');
const Services = () => import(/* webpackChunkName: "services.route" */ '@/Services/Services.vue');
export const servicesRoutes = [
    {
        path: `/${ServicesRoutes.SERVICES}`,
        localePath: ServicesRoutes.SERVICES,
        component: EmptyLayout,
        beforeEnter: RouteGuard.roleAccess(UserRoleState.Vendor),
        children: [
            {
                path: ':venueId?',
                component: Services
            }
        ]
    }
];
