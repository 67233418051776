const configurations = (state) => state.configurations;
const facilities = (state) => state.facilities;
const caterings = (state) => state.caterings;
const inclPrice = (state) => [...state.facilities, ...state.caterings].reduce((sum, item) => sum + item.price * item.count, 0);
const bookingState = (state) => state;
export default {
    bookingState,
    caterings,
    configurations,
    facilities,
    inclPrice
};
