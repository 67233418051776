import EventBus from '@/shared/services/eventBus';
import { isAuthorized } from '@/Authentication/utils/token';
import { computed } from '@vue/composition-api';
const AVAILABLE_LOCALES = ['en', 'nl'];
export default function useLanguageManager(root) {
    /**
     * Checks if the specified locale is valid
     */
    function _isValidLocale(locale) {
        return locale != null && AVAILABLE_LOCALES.includes(locale);
    }
    /**
     * Returns the specified locale if it's valid and the stored locale otherwise
     */
    function _ensuredLocale(locale) {
        if (_isValidLocale(locale)) {
            return locale;
        }
        const storedLocale = localStorage.getItem('locale');
        return _isValidLocale(storedLocale) ? storedLocale : null;
    }
    /**
     * Enables the specified locale and store it into Local Storage
     */
    function _enableLocale(locale) {
        localStorage.setItem('locale', locale);
        globalThis.$i18n.locale = locale;
        // Set the moments.js locale
        // dow - First day of week is Monday
        // doy - First week of year must contain 4 January (7 + 1 - 4) as per ISO-8601, Europe
        root.$moment.updateLocale(locale, {
            week: { dow: 1, doy: 4 },
            relativeTime: {
                M: locale === 'nl' ? '1 maand' : '1 month',
                MM: locale === 'nl' ? '%d maanden' : '%d months',
                d: locale === 'nl' ? '1 dag' : '1 day',
                dd: locale === 'nl' ? '%d dagen' : '%d days',
                h: locale === 'nl' ? '1 uur' : '1 hour',
                m: locale === 'nl' ? '1 minuut' : '1 minute',
                mm: locale === 'nl' ? '%d minuten' : '%d minutes'
            }
        });
        globalThis.$moment = root.$moment;
    }
    /**
     * Enables the user valid locale or stored locale if no user locale found
     */
    async function setupLocale() {
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const userLocale = isAuthorized()
            ? user.value?.locale?.toLowerCase()
            : null;
        const locale = _ensuredLocale(userLocale) || 'nl';
        const localeFromPath = window.location.pathname.split('/')[1];
        if (locale && localeFromPath !== locale) {
            _enableLocale(locale);
            const route = globalThis.$router.history.current.params.locale
                ? globalThis.$router.history.current.fullPath.substring(3)
                : globalThis.$router.history.current.fullPath;
            if (locale === 'en') {
                if (globalThis.$router.history.current.fullPath !== route)
                    globalThis.$router.push(route || `/en${window.location.pathname}`);
            }
            else if (locale === 'nl') {
                if (globalThis.$router.history.current.fullPath !== route)
                    globalThis.$router.push(route || '/');
            }
        }
    }
    /**
     * Enables the specified valid locale if it was changed
     */
    function switchLocale(locale) {
        if (!locale)
            return;
        if (_isValidLocale(locale) && locale !== localStorage.getItem('locale')) {
            _enableLocale(locale);
        }
    }
    const $this = {
        setupLocale,
        switchLocale,
        unsubscribeFromEvents: null,
        unsubscribeFromActions: null,
        deconstruct() {
            // Unsubscribes from events
            if (this.unsubscribeFromEvents) {
                this.unsubscribeFromEvents();
            }
            // Unsubscribes from actions
            if (this.unsubscribeFromActions) {
                this.unsubscribeFromActions();
            }
        }
    };
    useEvents(root, $this);
    useActions(root, $this);
    return $this;
}
function useEvents(_, $this) {
    /**
     * Application was mounted
     */
    async function _appMounted() {
        await $this.setupLocale();
    }
    /**
     * Language selected by user in the header menu
     */
    function _languageSelected(locale) {
        $this.switchLocale(locale);
    }
    // Subscribes to events
    const eventsMapping = {
        appMounted: _appMounted,
        languageSelected: _languageSelected
    };
    Object.entries(eventsMapping).forEach(([event, handler]) => EventBus.$on(event, handler));
    $this.unsubscribeFromEvents = () => Object.keys(eventsMapping).forEach(event => EventBus.$off(event));
}
function useActions(_, $this) {
    /**
     * User just authorized
     */
    async function _userAuthorized(_) {
        await $this.setupLocale();
    }
    /**
     * User data changed in the account settings
     */
    async function _userDataUpdated(state) {
        const locale = state['$_app'].user?.locale?.toLowerCase();
        $this.switchLocale(locale);
    }
    // Subscribes to actions
    const actionsMapping = {
        'authentication/authorize': _userAuthorized,
        'authentication/socialAuth': _userAuthorized,
        '$_app/updateUser': _userDataUpdated
    };
    $this.unsubscribeFromActions = globalThis.$store.subscribeAction({
        after: async (action, state) => {
            if (action.type in actionsMapping) {
                await actionsMapping[action.type](state);
            }
        }
    });
}
