import { unixFormatted, unixTs } from '@/util/dates';
import i18n from '@/i18n/i18n-setup';
export const formatTimeRange = ({ slotTo, slotFrom }) => {
    if (!slotFrom && !slotTo) {
        return i18n.t('filter.time.no_selection.title');
    }
    const start = slotFrom && unixTs(slotFrom);
    const end = slotTo && unixTs(slotTo);
    const month = (start || end)?.format('D MMMM');
    if (
    //TODO: to check this
    start?.unix() === start?.startOf('day').unix() &&
        end?.unix() === end?.endOf('day').unix()) {
        return `${month}, ${i18n.t('filter.time.all_day.title')}`;
    }
    return `${slotFrom && !slotTo ? 'From' : ''} ${slotTo && !slotFrom ? 'To' : ''}  ${month}, 
    ${slotFrom ? unixFormatted(slotFrom, 'H:mm') : ''} 
    ${slotTo && slotFrom ? '–' : ''} 
    ${slotTo ? unixFormatted(slotTo, 'H:mm') : ''}`;
};
/**
 * Convert Google map zoom value to kilometers
 * @param {number} zoom Current Google map zoom (e.g. 10)
 * @param {number} lat Google map latitude value (e.g. 52.092857)
 * @returns {number} Distance in kilometers from the current user view to the ground (e.g. 100)
 */
export const convertMapZoomToKilometers = (zoom, lat) => {
    return Math.round((38000 / Math.pow(2, Number(zoom) - 3)) *
        Math.cos((Number(lat) * Math.PI) / 180) *
        1000);
};
