import { computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        onSearch: {
            type: Boolean,
            default: false
        },
        organisationRating: {
            type: Number,
            required: true
        },
        statusText: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        const { root } = context;
        const ratingTextValue = computed(() => ratingText.value[organisationRatingValue.value]);
        const organisationRatingValue = computed(() => props.organisationRating / 2);
        const ratingText = computed(() => ({
            0: root.$i18n.t('components.organisation_labels_rating.unreliable'),
            1: root.$i18n.t('components.organisation_labels_rating.low_rate'),
            2: root.$i18n.t('components.organisation_labels_rating.moderate_rate'),
            3: root.$i18n.t('components.organisation_labels_rating.average_rate'),
            4: root.$i18n.t('components.organisation_labels_rating.reliable'),
            5: root.$i18n.t('components.organisation_labels_rating.super_reliable')
        }));
        return {
            organisationRatingValue,
            ratingTextValue
        };
    }
});
