import { defineComponent, computed, ref, onMounted } from '@vue/composition-api';
import { useSpaceCategoriesQuery, BookingType2 as BookingType, useAddFavoriteSpaceMutation, useRemoveFavoriteSpaceMutation, UserRoleState } from '@/generated-types/graphql.types';
import groupBy from 'lodash/groupBy';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import OrganisationRating from '@/components/OrganisationRating.vue';
import SpaceCardBadges from '@/Space/components/SpaceCardBadges.vue';
import { SpaceRoutes } from '@/Space/space.const';
import { GeoPagesRoutes } from '@/GeoPages/geopages.const';
import { optimizeImage, backgroundVisibilityChanged } from '@/util/globalHelpers';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import { useUserDetailsCompleted } from '@/shared/composables/useUserDetailsCompleted';
import { PRE_SAVE_ACTION_TYPES, preSaveAction, resetPreSaveAction } from '@/util/preSaveActions';
import { getItemLocalStorage, saveCurrentPath } from '@/util/utils';
export default defineComponent({
    components: {
        OrganisationRating,
        SpaceCardBadges
    },
    props: {
        space: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false
        },
        showCityName: {
            type: Boolean,
            default: false
        },
        highlighted: {
            type: Boolean,
            default: false
        },
        lazyClass: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root } = context;
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const isSpaceFavorite = ref(props.space ? props.space.isFavorite : false);
        const { performAccountFlowAction } = useUserDetailsCompleted(root);
        const { result: categoriesResult } = useSpaceCategoriesQuery({
            clientId: 'legacy'
        });
        const categories = computed(() => groupBy(categoriesResult?.value?.spaceCategories, item => item?.categoryId));
        const linkTarget = computed(() => globalThis.Cypress ? '_self' : '_blank');
        const { mutate: addFavoriteSpace } = useAddFavoriteSpaceMutation({});
        const { mutate: removeFavoriteSpace } = useRemoveFavoriteSpaceMutation({});
        const isOnSpaceNameHover = ref(false);
        const activitiesPopoverVisible = ref(false);
        const activities = computed(() => {
            if (!props.space.activities)
                return [];
            return props.space.activities
                .filter(Boolean)
                .map((activityId) => {
                const activityTitle = activityId && categories.value[activityId]?.[0]?.categoryName
                    ? root.$i18n
                        .t(`filter.activity.${categories.value[activityId]?.[0]?.categoryName}.title`)
                        .toString()
                    : '';
                return {
                    value: activityId,
                    label: activityTitle
                };
            });
        });
        const handleClickOnFavorite = () => {
            if (performAccountFlowAction.value && !user.value) {
                preSaveAction({
                    type: PRE_SAVE_ACTION_TYPES.SPACE_ID,
                    spaceId: props.space.spaceId
                });
                saveCurrentPath(root.$route.fullPath.slice(1));
                performAccountFlowAction.value(UserRoleState.Booker);
                return;
            }
            makeAction();
        };
        const makeAction = (preSaved) => {
            if (preSaved && props.space.isFavorite)
                return;
            const spaceId = props.space.spaceId;
            (isSpaceFavorite.value
                ? removeFavoriteSpace({ spaceId })
                : addFavoriteSpace({ spaceId }))
                .then(() => {
                isSpaceFavorite.value = !isSpaceFavorite.value;
            })
                .catch(() => {
                const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
                addToast({
                    text: root.$i18n.t('favorites.error'),
                    type: 'danger',
                    dismissAfter: 5000
                });
            });
        };
        const spaceName = computed(() => `${props.space.venueName} - ${props.space?.internalName}`);
        onMounted(() => {
            const preSavedType = localStorage.getItem('preSavedType');
            let preSavedSpaceId = getItemLocalStorage('preSavedSpaceId');
            if (props.space &&
                preSavedSpaceId === props.space.spaceId &&
                preSavedType &&
                preSavedType === PRE_SAVE_ACTION_TYPES.SPACE_ID) {
                makeAction(true);
                resetPreSaveAction();
            }
        });
        /**
         * On space name hover. Show truncated name if space name is too long.
         */
        const onSpaceNameHover = (isHover) => {
            isOnSpaceNameHover.value = isHover;
        };
        return {
            activities,
            activitiesPopoverVisible,
            categories,
            isOnSpaceNameHover,
            isSpaceFavorite,
            linkTarget,
            spaceName,
            BookingType,
            GeoPagesRoutes,
            SpaceRoutes,
            backgroundVisibilityChanged,
            handleClickOnFavorite,
            makeAction,
            onSpaceNameHover,
            optimizeImage,
            useGetLocalizedPath
        };
    }
});
