import i18n from '@/i18n/i18n-setup';
import { lnManager } from '@/i18n/lnManager';
export const beforeEachCallback = (to, from, next) => {
    const locale = to.params.locale;
    const languages = ['en', 'nl'];
    if (languages.includes(locale)) {
        i18n.locale = locale;
        lnManager.currentLocale = locale;
    }
    else {
        lnManager.currentLocale = undefined;
    }
    const prevLocale = from.params.locale;
    const nextLocale = to.params.locale;
    // This produces a lot of 'Redirected when going from...' errors.
    if (prevLocale !== nextLocale &&
        i18n.locale !== nextLocale &&
        i18n.locale !== 'nl') {
        console.log(`[WARNING] Wrong locale (prev=${prevLocale}, next=${nextLocale})! Redirect to: ${i18n.locale}`);
        to.params.locale = i18n.locale;
        // return next(`/${i18n.locale}${to.fullPath}`);
    }
    return next();
};
