class LocalDB {
    static FIELDS = {
        TOKEN: 'token',
        ACCESS_TOKEN: 'access_token',
        REFRESH_TOKEN: 'refresh_token',
        LOGGED_IN: 'logged_in',
        VENUE_DETAILS: 'venue_details',
        VENUE_SERVICES: {
            facilities: 'venue_facilities',
            catering: 'venue_catering'
        },
        VENUE_TAGS: 'venue_tags',
        VENUE_ID: 'venue_id',
        SPACE_DETAILS: 'space_details',
        SPACE_CONFIGURATIONS: 'space_configurations',
        SPACE_BOOKING_RULES: 'space_booking_rules',
        SPACE_FACILITIES: 'space_facilities',
        SPACE_PRICING: 'space_pricing'
    };
    static instance() {
        return new LocalDB();
    }
    getItem(item) {
        return localStorage.getItem(item);
    }
    setItem(item, data) {
        localStorage.setItem(item, data);
    }
    removeItem(item) {
        localStorage.removeItem(item);
    }
    setCookie(cname, cvalue) {
        if (!process.browser)
            return;
        const d = new Date();
        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }
    getCookie(cname) {
        if (!process.browser)
            return '';
        const name = `${cname}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
export default LocalDB;
