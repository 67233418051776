import { uploadImage as uploadImageViaApi } from '@/shared/api-modules/application.api';
import AuthApi from '@/shared/services/auth.api';
const refetchUser = async ({ commit }) => {
    const user = await AuthApi.userInfo();
    commit('SET_USER', user);
    return user;
};
const removeUser = async ({ commit }) => {
    commit('SET_USER', undefined);
};
const loadUser = async ({ commit, dispatch, state }) => {
    if (!state.user) {
        const user = await AuthApi.userInfo();
        commit('SET_USER', user);
    }
    try {
        await dispatch('$_vendor/loadOrganisation', true, { root: true });
    }
    catch (e) {
        if (e.status === 404) {
            // Used to redirect if there's no organisation data from backend.
            // EventBus.$emit(EVENTBUS_EVENTS.REDIRECT, ROUTE_LIST_NAMES.ONBOARDING.MAIN);
        }
    }
    return state.user;
};
const updateUser = async ({ commit }, data) => {
    const user = await AuthApi.updateProfile(data);
    commit('SET_USER', user);
};
const uploadImage = (_, form) => uploadImageViaApi(form);
const verifyPhone = async (_, code) => {
    try {
        await AuthApi.verifyPhone(code);
    }
    catch (error) {
        throw new Error();
    }
};
const resendVerification = async (_, method) => {
    try {
        await AuthApi.resendVerification(method);
    }
    catch (error) {
        throw new Error();
    }
};
export default {
    loadUser,
    updateUser,
    uploadImage,
    verifyPhone,
    resendVerification,
    refetchUser,
    removeUser
};
