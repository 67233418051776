import OrganisationDetailsModel from '@/shared/models/organisation/OrganisationDetailsModel';
import { ORGANISATION_TYPES, VAT_RATES } from '@/shared/const';
class OrganisationDetailsForm extends OrganisationDetailsModel {
    validationRules() {
        return {
            name: [
                {
                    validation: 'requiredIfEqual',
                    field: 'organisationType',
                    value: ORGANISATION_TYPES.PUBLIC_ORGANISATION,
                    i18code: 'onboarding.organisation_details.errors.name_empty'
                },
                {
                    validation: 'maxLength',
                    value: 100,
                    i18code: 'onboarding.organisation_details.errors.name_too_long'
                }
            ],
            vatNumber: [
                {
                    validation: 'required',
                    i18code: 'onboarding.organisation_details.errors.vat_number_empty'
                },
                {
                    validation: 'maxLength',
                    value: 14,
                    i18code: 'onboarding.organisation_details.errors.vat_number_length_invalid'
                }
            ],
            coc: [
                {
                    validation: 'requiredIfEqual',
                    field: 'organisationType',
                    value: ORGANISATION_TYPES.PUBLIC_ORGANISATION,
                    i18code: 'onboarding.organisation_details.errors.coc_empty'
                }
            ],
            organisationAddress: [
                {
                    validation: 'requiredIfEqual',
                    field: 'organisationType',
                    value: ORGANISATION_TYPES.PUBLIC_ORGANISATION,
                    i18code: 'onboarding.organisation_details.errors.organisation_address_empty'
                }
            ],
            financeEmail: [
                {
                    validation: 'email',
                    i18code: 'onboarding.organisation_details.errors.finance_email_incorrect'
                },
                {
                    validation: 'required',
                    i18code: 'onboarding.organisation_details.errors.finance_email_incorrect'
                }
            ],
            financePlaceId: [
                {
                    validation: 'required',
                    i18code: 'onboarding.venue.details.errors.address_not_selected'
                }
            ],
            personalAddress: [
                {
                    validation: 'requiredIfEqual',
                    field: 'organisationType',
                    value: ORGANISATION_TYPES.PRIVATE_INDIVIDUAL,
                    i18code: 'onboarding.organisation_details.errors.personal_address_empty'
                }
            ],
            vatRate: [
                {
                    validation: 'oneOfValues',
                    values: [VAT_RATES.NONE, VAT_RATES.NINE, VAT_RATES.TWENTY_ONE],
                    i18code: 'onboarding.organisation_details.errors.vat_rate_empty'
                }
            ]
        };
    }
}
export default OrganisationDetailsForm;
