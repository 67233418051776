import { defineComponent, watch, onMounted } from '@vue/composition-api';
export default defineComponent({
    props: {
        initialValues: {
            type: [Array, Object, Boolean, String, Number],
            default: undefined
        }
    },
    setup(props) {
        const form = {
            value: props.initialValues
        };
        watch(() => props.initialValues, () => {
            form.value = props.initialValues;
        }, { immediate: true });
        onMounted(() => {
            form.value = props.initialValues;
        });
        return { form };
    }
});
