import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import vClickOutside from 'v-click-outside';
export default defineComponent({
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Number],
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        position: {
            type: String,
            default: 'bottom'
        },
        label: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        default: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const optionsVisible = ref(false);
        const selectedLabel = computed(() => {
            const option = props.options.filter(opt => opt.value === props.value)[0];
            const { label } = option || '';
            if (!label && props.placeholder) {
                return props.placeholder;
            }
            return label;
        });
        const isNotSelected = computed(() => {
            return selectedLabel.value === props.placeholder;
        });
        const selectOption = (option) => {
            if (option.disabled)
                return;
            optionsVisible.value = false;
            emit('input', option.value);
        };
        const closeOptions = () => {
            optionsVisible.value = false;
        };
        const toggleOptions = () => {
            if (!props.disabled) {
                optionsVisible.value = !optionsVisible.value;
            }
        };
        onMounted(() => {
            if (props.value == null &&
                props.default &&
                props.options.length &&
                !props.placeholder) {
                emit('input', props.options[0].value);
            }
        });
        return {
            isNotSelected,
            optionsVisible,
            selectedLabel,
            closeOptions,
            selectOption,
            toggleOptions
        };
    }
});
