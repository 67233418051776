import { defineComponent, ref, watch } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { match, compile } from 'path-to-regexp';
const steps = [
    {
        to: '/vendor/onboarding/organisation-details',
        title: 'onboarding.breadcrumbs.label_organisation_details',
        disabled: true,
        unavailable: true,
        isStatic: true
    },
    {
        to: '/vendor/onboarding/venue/(.*)',
        title: 'onboarding.breadcrumbs.label_venue_details',
        disabled: true,
        unavailable: true,
        isStatic: true
    },
    {
        to: '/vendor/onboarding/space/(.*)',
        title: 'onboarding.breadcrumbs.label_space_details',
        disabled: true,
        unavailable: true,
        isStatic: true
    }
];
export default defineComponent({
    components: {
        Breadcrumbs
    },
    setup(_, context) {
        const { root } = context;
        let links = ref(steps);
        watch(() => root.$route, () => {
            links.value = steps.reduceRight((acc, { to, ...item }) => {
                const preparedPath = root.$route.name && !root.$route.name.startsWith('en-')
                    ? root.$route.fullPath
                    : root.$route.fullPath.slice(3);
                const matcher = match(to);
                const matched = matcher(preparedPath);
                const prevState = acc[0]?.disabled ?? true;
                const disabled = prevState ? !matched : prevState;
                const link = {
                    ...item,
                    to: disabled ? to : compile(to)(['details']),
                    active: !!matched,
                    disabled
                };
                return new Array().concat(link, acc);
            }, new Array());
        }, { immediate: true });
        return {
            links
        };
    }
});
