import actions from '@/shared/store-modules/venue/actions';
import getters from '@/shared/store-modules/venue/getters';
import mutations from '@/shared/store-modules/venue/mutations';
import { defaultFilter } from '@/shared/store-modules/venue/const';
const state = () => ({
    filter: JSON.parse(JSON.stringify(defaultFilter)),
    map_data: {
        mapAPI: {}
    },
    venues: [],
    activities: []
});
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
