import actions from '@/shared/store-modules/app/actions';
import getters from '@/shared/store-modules/app/getters';
import mutations from '@/shared/store-modules/app/mutations';
const state = () => ({
    user: null,
    userLoggedIn: false,
    chat: null
});
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
