import { computed, defineComponent, watch } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { SITE_URL } from '@/App.vue';
import EventBus from '@/shared/services/eventBus';
export default defineComponent({
    props: {
        links: {
            type: Array,
            default: () => []
        }
    },
    setup(props, context) {
        const { root } = context;
        const structuredDataBreadcrumbs = computed(() => {
            const links = [...props.links].reverse();
            return {
                '@type': 'BreadcrumbList',
                numberOfItems: links.length,
                itemListElement: links.map((link, index, arr) => ({
                    '@type': 'ListItem',
                    position: arr.length - index,
                    item: {
                        '@id': `${SITE_URL}${useGetLocalizedPath(link.to)}`,
                        name: link.isStatic ? root.$i18n.t(link.title) : link.title
                    }
                }))
            };
        });
        watch(() => props.links, () => {
            if (props.links.length) {
                EventBus.$emit('onBreadcrumbsReady', structuredDataBreadcrumbs.value);
            }
        }, { immediate: true, deep: true });
        return { useGetLocalizedPath, SITE_URL };
    }
});
