import { getWeekDay } from '@/util/dates';
const clearDateInfo = ({ commit }) => {
    commit('CLEAR_DATE_INFO');
};
const setCurrentDay = ({ commit }, day) => {
    const week = getWeekDay(day);
    commit('SET_CURRENT_DAY', { day, week });
};
export default {
    clearDateInfo,
    setCurrentDay
};
