import { defineComponent } from '@vue/composition-api';
import { EnumProp } from '@/util/vueProps';
var Size;
(function (Size) {
    Size["big"] = "big";
    Size["medium"] = "medium";
    Size["small"] = "small";
})(Size || (Size = {}));
export default defineComponent({
    props: {
        size: EnumProp(Size, Size.medium),
        value: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        inline: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const onChange = (value) => {
            if (props.disabled) {
                return;
            }
            emit('input', value);
            emit('click');
        };
        return {
            onChange
        };
    }
});
