import APIClient from '@/shared/services/api';
export const findVenues = (params) => APIClient.postData('venue/find/', params, false);
export const getAllCategories = () => APIClient.getData('category/');
export const getImageById = (id) => APIClient.getData(`image/${id}/`, true, false);
export const loadImage = async (id, width, height) => {
    const images = await APIClient.postData('images/get_image/', {
        ids: [id],
        width,
        height
    }, false, false);
    return images.images.length ? images.images[0] : {};
};
export const loadModelImages = (model, id) => APIClient.getData(`image/${model}/${id}/`, true, false);
export const removeAvatar = (accountId) => APIClient.deleteData(`image/account/${accountId}/`, true, false);
export const removeOrgLogo = (orgId) => APIClient.deleteData(`image/organisation/${orgId}/`, true, false);
export const uploadImage = (form) => APIClient.postData('image/', form, true, false, true);
// const validatePhone = (params: any): Promise<any> =>
//   APIClient.postData('phone/validate/', params, false);
