import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
    props: {
        blok: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        return {
            tag: computed(() => props.blok.tag)
        };
    }
});
