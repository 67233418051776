import { FavoritesRoutes } from '@/Favorites/favorites.const';
import RouteGuard from '@/Authentication/authentication.guard';
/** Components */
const EmptyLayout = () => import(
/* webpackChunkName: "favorites.route" */ '@/layouts/EmptyLayout.vue');
const Favorites = () => import(/* webpackChunkName: "favorites.route" */ '@/Favorites/Favorites.vue');
export const favoritesRoutes = [
    {
        path: `/${FavoritesRoutes.FAVORITES}`,
        localePath: FavoritesRoutes.FAVORITES,
        component: EmptyLayout,
        beforeEnter: RouteGuard.authorized,
        children: [
            {
                path: '/',
                component: Favorites,
                meta: {
                    // maxWidthContainer: true,
                    center: true
                }
            }
        ]
    }
];
