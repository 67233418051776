export default function useSmartlookHooks() {
    /**
     * Identify user in Smartlook. https://web.developer.smartlook.com/docs/identify-visitor#how-to-use-the-identify-method
     * @param user
     */
    const identifyUser = (user) => {
        if (!user)
            return;
        if (!globalThis.$smartlook)
            return;
        try {
            globalThis.$smartlook.identify(user.email, {
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                phone: user.phone
            });
        }
        catch (error) {
            console.error('Smartlook error', error);
        }
    };
    /**
     * Anonymize user in Smartlook. https://web.developer.smartlook.com/docs/identify-visitor#anonymizing-users
     */
    const anonymizeUser = () => {
        if (!globalThis.$smartlook)
            return;
        try {
            globalThis.$smartlook.anonymize();
        }
        catch (error) {
            console.error('Smartlook error', error);
        }
    };
    return {
        anonymizeUser,
        identifyUser
    };
}
