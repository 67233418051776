//i18n-setup.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n); // Todo: Move to app.use in main file after vue compat:3
const loadLocaleMessages = () => {
    const locales = require.context('../locales/', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = messages[locale]
                ? Object.assign(messages[locale], locales(key))
                : locales(key);
        }
    });
    return messages;
};
const i18n = new VueI18n({
    locale: process.env.DEFAULT_LOCALE || 'nl',
    fallbackLocale: process.env.FALLBACK_LOCALE || 'nl',
    messages: loadLocaleMessages()
});
export default i18n;
