import { defineComponent, ref, watch } from '@vue/composition-api';
export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        isCollapsed: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const collapsed = ref(true);
        const toggle = (event) => {
            event.preventDefault();
            if (!props.disabled) {
                collapsed.value = !collapsed.value;
            }
        };
        watch(() => props.isCollapsed, () => {
            collapsed.value = props.isCollapsed;
        }, { immediate: true });
        return {
            collapsed,
            toggle
        };
    }
});
