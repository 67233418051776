import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { createPopper } from '@popperjs/core';
export default defineComponent({
    props: {
        placement: {
            type: String,
            default: 'top'
        },
        title: {
            type: String,
            default: null
        },
        offset: {
            type: Array,
            default: () => [0, 0]
        }
    },
    setup(props, { slots }) {
        const tooltip = ref(null);
        onMounted(() => {
            if (slots && slots.default) {
                createPopper(slots.default()[0].elm, tooltip.value, {
                    placement: props.placement,
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: props.offset
                            }
                        }
                    ]
                });
            }
        });
        return {
            tooltip
        };
    }
});
