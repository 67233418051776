import { defineComponent, ref, watch } from '@vue/composition-api';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
const steps = [
    {
        to: '',
        title: 'geo_pages.breadcrumbs.home',
        disabled: false,
        isStatic: true
    },
    {
        to: 'locations',
        title: 'geo_pages.breadcrumbs.locations',
        disabled: true,
        isStatic: true
    },
    {
        to: 'locations/:region',
        title: 'geo_pages.breadcrumbs.regions',
        disabled: true,
        isStatic: true
    },
    {
        to: 'locations/:region/:city',
        title: 'geo_pages.breadcrumbs.city',
        disabled: true,
        isStatic: true
    }
];
export default defineComponent({
    components: {
        Breadcrumbs
    },
    setup(_, context) {
        const { root } = context;
        let links = ref(steps);
        watch(() => root.$route, () => {
            const { region, city, tag } = root.$route.params;
            if (region) {
                links.value[2].title = region;
                links.value[2].isStatic = false;
                links.value[1].disabled = false;
            }
            else {
                links.value[2].title = 'geo_pages.breadcrumbs.regions';
                links.value[2].isStatic = true;
                links.value[1].disabled = true;
            }
            if (city) {
                links.value[3].title = !tag ? city : `${tag} ${city}`;
                links.value[3].isStatic = false;
                links.value[3].to = `locations/${region}/${city}`;
                links.value[2].to = `locations/${region}`;
                links.value[2].disabled = false;
                links.value[2].isStatic = false;
            }
            else {
                links.value[3].title = 'geo_pages.breadcrumbs.city';
                links.value[3].isStatic = true;
                links.value[2].disabled = true;
            }
        }, { immediate: true });
        return {
            links
        };
    }
});
