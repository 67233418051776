export const DATE_FORMATS = {
    calendarCore: 'YYYY-MM-DD',
    weekModeTitle: 'ddd, DD MMM',
    timeFormat: 'HH:mm',
    headerTitleMonth: 'MMMM',
    headerTitleWeek: 'DD MMM'
};
export const RESERVATION_STATUS = {
    CANCELLED: {
        value: 'CANCELLED',
        meta: {
            bookingPage: {
                className: 'cancelled',
                label: 'booking_calendar.label_stats_cancelled_number',
                icon: 'times-circle'
            }
        }
    },
    EXPIRED: {
        value: 'EXPIRED',
        meta: {
            bookingPage: {
                className: 'expired',
                label: 'booking_calendar.label_stats_expired_number',
                icon: 'check-circle'
            }
        }
    },
    PENDING: {
        value: 'PENDING',
        meta: {
            bookingPage: {
                className: 'pending',
                label: 'booking_calendar.label_stats_pending_number',
                icon: 'question-circle'
            }
        }
    },
    PAID: {
        value: 'PAID',
        meta: {
            bookingPage: {
                className: 'paid',
                label: 'booking_calendar.label_stats_paid_number',
                icon: 'clock'
            }
        }
    },
    OPTIONAL: {
        value: 'OPTIONAL',
        meta: {
            bookingPage: {
                className: 'optional',
                label: 'booking_calendar.label_stats_optional_number',
                icon: 'clipboard-alt'
            }
        }
    }
};
