import BaseForm from '@/shared/models/BaseForm';
import { DAY_NAMES } from '@/shared/const';
import { generateWorkingTime } from '@/util/utils';
class VenueDetailsModel extends BaseForm {
    additionalAddress;
    address;
    allowCatering;
    contactEmail;
    contactName;
    contactPhone;
    description;
    features;
    id;
    images;
    isActive;
    name;
    placeID;
    slug;
    status;
    venueRules;
    workingTime;
    constructor(props) {
        super(props);
        this.id = '';
        this.name = '';
        this.description = '';
        this.venueRules = '';
        this.images = [];
        this.features = {};
        this.placeID = '';
        this.address = '';
        this.additionalAddress = '';
        this.workingTime = {};
        this.workingTime[DAY_NAMES.MONDAY] = [];
        this.workingTime[DAY_NAMES.TUESDAY] = [];
        this.workingTime[DAY_NAMES.WEDNESDAY] = [];
        this.workingTime[DAY_NAMES.THURSDAY] = [];
        this.workingTime[DAY_NAMES.FRIDAY] = [];
        this.workingTime[DAY_NAMES.SATURDAY] = [];
        this.workingTime[DAY_NAMES.SUNDAY] = [];
        this.contactName = '';
        this.contactPhone = {};
        this.contactEmail = '';
        this.allowCatering = true;
        this.status = 'DRAFT';
        this.isActive = false;
        this.slug = '';
        if (props) {
            Object.keys(props).forEach((k) => {
                this[k] = props[k];
            });
        }
    }
    loadFromModel(model, except) {
        if (!model) {
            return;
        }
        const exceptAttributes = except === undefined ? [] : except;
        this.attributes().forEach((attribute) => {
            if (model[attribute] && !exceptAttributes.includes(attribute)) {
                this[attribute] =
                    attribute === 'workingTime'
                        ? generateWorkingTime(model[attribute], this.workingTime)
                        : model[attribute];
            }
        });
    }
    apiAttributes() {
        return {
            id: 'venue_id',
            name: 'venue_name',
            description: 'venue_description',
            venueRules: 'venue_rules',
            features: 'features',
            placeID: 'place_id',
            address: 'address',
            additionalAddress: 'additional',
            workingTime: 'working_time',
            contactName: 'contact_name',
            contactPhone: 'contact_phone',
            contactEmail: 'contact_email',
            allowCatering: 'allow_customer_catering',
            status: 'status',
            isActive: 'is_active',
            slug: 'venue_url'
        };
    }
}
export default VenueDetailsModel;
