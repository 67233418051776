export var BookingRoutes;
(function (BookingRoutes) {
    BookingRoutes["BOOKING"] = "booking";
    BookingRoutes["BOOKINGS"] = "bookings";
    BookingRoutes["PAYMENT"] = "/payment";
    BookingRoutes["PAY"] = "pay";
    BookingRoutes["SUCCESS"] = "success";
    BookingRoutes["VENDOR"] = "vendor";
    BookingRoutes["AVAILABILITY"] = "availability";
    BookingRoutes["FINANCE"] = "/finance";
    BookingRoutes["COUPONS"] = "/coupons";
    BookingRoutes["SUMMARY"] = "summary";
    BookingRoutes["ALL"] = "all";
})(BookingRoutes || (BookingRoutes = {}));
export var BookingDetailsViewMode;
(function (BookingDetailsViewMode) {
    BookingDetailsViewMode["VENDOR"] = "VENDOR";
    BookingDetailsViewMode["CUSTOMER"] = "CUSTOMER";
})(BookingDetailsViewMode || (BookingDetailsViewMode = {}));
export var BookingTypes;
(function (BookingTypes) {
    BookingTypes["UPCOMING"] = "UPCOMING";
    BookingTypes["ARCHIVED"] = "ARCHIVED";
    BookingTypes["RESERVATIONS"] = "RESERVATIONS";
    BookingTypes["REQUESTS"] = "REQUESTS";
})(BookingTypes || (BookingTypes = {}));
export var BookingStates;
(function (BookingStates) {
    BookingStates["WAITING_FOR_HOST"] = "WAITING_FOR_HOST";
    BookingStates["WAITING_FOR_CUSTOMER"] = "WAITING_FOR_CUSTOMER";
    BookingStates["UNPAID"] = "UNPAID";
    BookingStates["PAID"] = "PAID";
    BookingStates["CANCELLED_BY_CUSTOMER"] = "CANCELLED_BY_CUSTOMER";
    BookingStates["CANCELLED_BY_HOST"] = "CANCELLED_BY_HOST";
    BookingStates["DRAFT"] = "DRAFT";
    BookingStates["PASSED"] = "PASSED";
    BookingStates["EXPIRED"] = "EXPIRED";
    BookingStates["DECLINED"] = "DECLINED";
})(BookingStates || (BookingStates = {}));
export var TimelineActions;
(function (TimelineActions) {
    TimelineActions["BOOKING_REQUEST_GUEST_VIEW"] = "BOOKING_REQUEST_GUEST_VIEW";
    TimelineActions["BOOKING_REQUEST_VENDOR_VIEW"] = "BOOKING_REQUEST_VENDOR_VIEW";
    TimelineActions["CUSTOMER_CANCEL_WITH_REFUND"] = "CUSTOMER_CANCEL_WITH_REFUND";
    TimelineActions["CUSTOMER_CANCEL_WITHOUT_REFUND"] = "CUSTOMER_CANCEL_WITHOUT_REFUND";
    TimelineActions["EDIT_BOOKING"] = "EDIT_BOOKING";
    TimelineActions["POST_PAYMENT_FOR_BOOKER"] = "POST_PAYMENT_FOR_BOOKER";
    TimelineActions["POST_PAYMENT_FOR_VENDOR"] = "POST_PAYMENT_FOR_VENDOR";
    TimelineActions["VENDOR_CANCEL"] = "VENDOR_CANCEL";
    TimelineActions["VENDOR_CANCEL_WITH_REFUND"] = "VENDOR_CANCEL_WITH_REFUND";
    TimelineActions["VENDOR_POST_PAYMENT_INVOICING"] = "VENDOR_POST_PAYMENT_INVOICING";
    TimelineActions["OVERDUE_PAYMENT"] = "OVERDUE_PAYMENT";
    TimelineActions["PASSED"] = "PASSED";
    TimelineActions["PAY"] = "PAY";
    TimelineActions["RESERVED"] = "RESERVED";
    TimelineActions["REVIEW"] = "REVIEW";
    TimelineActions["USER_INFO"] = "USER_INFO";
    TimelineActions["EXPIRED"] = "EXPIRED";
    TimelineActions["FINANCE"] = "FINANCE";
})(TimelineActions || (TimelineActions = {}));
