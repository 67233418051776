import { extendMoment } from 'moment-range';
import Moment from 'moment-timezone';
import wb from '@/registerServiceWorker';
import Smartlook from 'smartlook-client';
const timezone = process.env.VUE_APP_TIMEZONE;
const SMARTLOOK_KEY = process.env.VUE_APP_SMARTLOOK_KEY;
/**
 * Setup globalThis
 */
export const setupGlobalThis = (store, router, i18n) => {
    globalThis.$store = store;
    globalThis.$router = router;
    globalThis.$i18n = i18n;
    globalThis.$moment = extendMoment(Moment);
    globalThis.$timezone = timezone;
    if (SMARTLOOK_KEY && !globalThis.Cypress) {
        globalThis.$smartlook = Smartlook;
        const isSmartlookStarted = globalThis.$smartlook.init(SMARTLOOK_KEY, {
            region: 'eu'
        });
        if (isSmartlookStarted) {
            globalThis.$smartlook.record({
                forms: true,
                ips: true,
                emails: true,
                numbers: true
            });
        }
    }
    // For Vue3 could become: app.config.globalProperties since Vue.prototype is removed
    globalThis.$workbox = wb;
};
