let GTM_ENABLED;
let GTM_DEBUG;
let GTM_LOAD;
if (window.location.hostname === 'wemeet.nl' &&
    window.location.protocol === 'https:') {
    // We are live, all features enabled, debugging disabled.
    GTM_ENABLED = true;
    GTM_DEBUG = false;
    GTM_LOAD = true;
}
else if (window.location.hostname === 'dev.wemeet.zone') {
    // Testing, data is sent and debug is on.
    GTM_ENABLED = true;
    GTM_DEBUG = true;
    GTM_LOAD = true;
}
else if (window.location.hostname === 'localhost' &&
    parseInt(window.location.port, 10) > 1000) {
    // We are developing - No GTM is loaded, though you will see debug info, but no data will be sent.
    GTM_ENABLED = true;
    GTM_DEBUG = true;
    GTM_LOAD = false;
}
else {
    // We are something else - nothing wll happen.
    GTM_ENABLED = false;
    GTM_DEBUG = false;
    GTM_LOAD = false;
}
export default {
    id: 'GTM-WKMN2T6',
    defer: false,
    compatibility: true,
    vueRouter: false,
    enabled: GTM_ENABLED,
    debug: GTM_DEBUG,
    loadScript: GTM_LOAD,
    trackOnNextTick: false // Whether call trackView in Vue.nextTick
};
