export var AuthPopups;
(function (AuthPopups) {
    AuthPopups["LOGIN"] = "login";
    AuthPopups["SIGNUP"] = "sign-up";
    AuthPopups["SIGN_UP"] = "sign-up";
    AuthPopups["EMAIL_LOGIN"] = "email-login";
    AuthPopups["PHONE_LOGIN"] = "phone-login";
    AuthPopups["EMAIL_CONFIRMATION"] = "email-confirmation";
    AuthPopups["EMAIL_CONFIRMED"] = "email-confirmed";
    AuthPopups["ANOTHER_BROWSER"] = "another-browser";
    AuthPopups["COMPLETE_ACCOUNT"] = "complete-account";
    AuthPopups["SELECT_ROLE"] = "select-role";
    AuthPopups["PHONE_CONFIRMATION"] = "phone-confirmation";
    AuthPopups["PHONE_CONFIRMED"] = "phone_confirmed";
})(AuthPopups || (AuthPopups = {}));
export var SignUpSources;
(function (SignUpSources) {
    SignUpSources["BECOME_VENDOR_PAGE"] = "become-vendor-page";
})(SignUpSources || (SignUpSources = {}));
export var SocialProviders;
(function (SocialProviders) {
    SocialProviders["FACEBOOK"] = "facebook";
    SocialProviders["GOOGLE"] = "google";
    SocialProviders["LINKEDIN"] = "linkedin";
})(SocialProviders || (SocialProviders = {}));
export var LoginMethods;
(function (LoginMethods) {
    LoginMethods["PHONE"] = "phone";
    LoginMethods["EMAIL"] = "email";
})(LoginMethods || (LoginMethods = {}));
export var AUTH_SESSION_STORAGE_KEYS;
(function (AUTH_SESSION_STORAGE_KEYS) {
    AUTH_SESSION_STORAGE_KEYS["ACTION_FLOW_ROLE_TYPE"] = "action_flow_role_type";
})(AUTH_SESSION_STORAGE_KEYS || (AUTH_SESSION_STORAGE_KEYS = {}));
export var AuthFlow;
(function (AuthFlow) {
    AuthFlow["LOGIN"] = "LOGIN";
    AuthFlow["SIGNUP"] = "SIGNUP";
})(AuthFlow || (AuthFlow = {}));
