export const ROUTE_LIST_NAMES = {
    MAIN: 'main',
    SPACES: 'spaces',
    VENDOR_BOOKINGS: 'vendor-bookings',
    VENUE_SETTINGS: {
        MAIN: 'venue-settings',
        DETAILS: 'venue-settings-details',
        FACILITIES: 'venue-settings-facilities',
        CATERING: 'venue-settings-catering',
        TAGS: 'venue-settings-tags'
    },
    SPACE_SETTINGS: {
        MAIN: 'space-settings',
        DETAILS: 'space-settings-details',
        CONFIGURATIONS: 'space-settings-configurations',
        BOOKING_RULES: 'space-settings-booking-rules',
        FACILITIES: 'space-settings-facilities',
        PRICING: 'space-settings-pricing',
        FEATURES: 'space-settings-features'
    },
    CREATE_SPACE: {
        MAIN: 'create-space',
        DETAILS: 'create-space-details',
        CONFIGURATIONS: 'create-space-configurations',
        BOOKING_RULES: 'create-space-booking-rules',
        FACILITIES: 'create-space-facilities',
        PRICING: 'create-space-pricing',
        FEATURES: 'create-space-features'
    },
    ONBOARDING: {
        MAIN: 'onboarding',
        ORGANISATION_DETAILS: 'onboarding-organisation-details',
        VENUE: {
            MAIN: 'venue-onboarding',
            DETAILS: 'venue-onboarding-details',
            FACILITIES: 'venue-onboarding-facilities',
            CATERING: 'venue-onboarding-catering',
            TAGS: 'venue-onboarding-tags'
        },
        SPACE: {
            MAIN: 'space-onboarding',
            DETAILS: 'space-details-onboarding',
            CONFIGURATIONS: 'space-configurations-onboarding',
            BOOKING_RULES: 'space-booking-rules-onboarding',
            FACILITIES: 'space-facilities-onboarding',
            PRICING: 'space-pricing-onboarding',
            FEATURES: 'space-features-onboarding'
        }
    },
    PROFILE: {
        MAIN: 'profile',
        ACCOUNT: 'profile-account',
        FINANCE: 'profile-finance',
        PAYOUT: 'profile-finance-payout',
        HISTORY: 'profile-finance-history',
        ORGANISATION: 'profile-organisation',
        TEAM: 'profile-team',
        SOCIAL: 'profile-social'
    },
    ERRORS: {
        NOT_FOUND: 'not-found',
        SERVER_ERROR: 'server-error'
    },
    SUCCESS: {
        VENUE_CREATED: 'venue-success',
        SPACE_CREATED: 'space-success'
    },
    BOOKINGS: {
        VENDOR: 'bookings-vendor'
    },
    CUSTOMERS: 'customers',
    CUSTOMERS_SEARCH: {
        MAIN: 'customers-search-main',
        DETAIL: 'customers-search-detail'
    },
    CUSTOMER: {
        MAIN: 'customer',
        VENUE: {
            MAIN: 'customer-venue'
        }
    }
};
export const PUBLIC_ROUTES = [
    'main',
    'customers-search-main',
    'customers-search-detail',
    'customer',
    'customer-venue',
    'customer-space'
];
