import actions from '@/shared/store-modules/booking/actions';
import getters from '@/shared/store-modules/booking/getters';
import mutations from '@/shared/store-modules/booking/mutations';
const state = () => ({
    attendees: 2,
    configurations: [],
    facilities: [],
    caterings: [],
    startTime: 0,
    endTime: 0
});
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
